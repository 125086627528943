import { IonCheckbox, IonLabel } from "@ionic/react";
import { useTranslation } from "react-i18next";

const TnCCheckbox = ({ checked, onChange, ...rest }: any) => {
  const { t } = useTranslation();
  return (
    <>
      <IonCheckbox
        checked={checked}
        onIonChange={onChange}
        style={{ marginRight: 10, marginBottom: -5 }}
        {...rest}
      ></IonCheckbox>
      <IonLabel>
        {t("youAgreeTo")}{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href={process.env.REACT_APP_T_C_URL}
          style={{ cursor: "pointer" }}
        >
          {t("tnc")}
        </a>
      </IonLabel>
    </>
  );
};

export default TnCCheckbox;
