import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonAvatar,
  IonItemDivider,
  IonSlides,
  IonSlide,
  useIonAlert,
  IonIcon,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import useStore from "../../store/store";
import { Button, LinkButton } from "../../controls";
import userService from "../../services/user";
import achievementService from "../../services/achievement";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import Constants from "../../utils/constants";
import { v4 as uuid } from "uuid";
import ActiveProjectSlide from "../../components/npo/ActiveProjectSlide";
import Styles from "../../css/styles";
import ActiveAchievementSlide from "../../components/npo/ActiveAchievementSlide";
import { Clipboard } from "@capacitor/clipboard";
import projectService from "../../services/project";
import npoService from "../../services/npo";
import {
  createOutline,
  notificationsOutline,
  shareSocialOutline,
} from "ionicons/icons";
import HeaderNavbar from "../../components/HeaderNavbar";
import NpoAnalytics from "../../components/npo/NpoAnalytics";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const Profile: React.FC = () => {
  const { t } = useTranslation();
  const { user, token, setIsLoading, setUser } = useStore(
    (state: any) => state
  );
  const size = useScreenSize();
  const history = useHistory();
  const [presentAlert] = useIonAlert();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  const [profilePictureUrl, setProfilePictureUrl] = useState<any>(user.fileUrl);
  const [achievements, setAchievements] = useState<any>([]);
  const [projects, setProjects] = useState<any>([]);
  const [noOfSupporters, setNoOfSupporters] = useState<number>(0);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href });
  }, []);

  useEffect(() => {
    achievementService.getAll(token).then((result) => {
      if (result.error) {
        setErrorMessage(result.error);
        setIsLoading(false);
        return;
      }

      const data = result?.data || false;
      if (!data) return;

      setAchievements(data);
    });

    projectService.getAll(token).then((result) => {
      if (result.error) {
        setErrorMessage(result.error);
        setIsLoading(false);
        return;
      }

      const data = result?.data || false;
      if (!data) return;

      setProjects(data);
    });

    npoService.getDonars({ npoId: user._id }).then((result) => {
      if (result.data) {
        const d: any[] = result?.data || [];
        setNoOfSupporters(d.length);
      }
    });
  }, []);

  const base64FromPath = async (path: string): Promise<string> => {
    const response = await fetch(path);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        if (typeof reader.result === "string") {
          resolve(reader.result);
        } else {
          reject("method did not return a string");
        }
      };
      reader.readAsDataURL(blob);
    });
  };

  const handleProfilePictureCamera = async () => {
    const image = await Camera.getPhoto({
      quality: 100,
      allowEditing: false,
      resultType: CameraResultType.Uri,
      source: CameraSource.Prompt,
      saveToGallery: true,
      promptLabelHeader: t("choose"),
      promptLabelCancel: t("close"),
      presentationStyle: "popover",
    });

    setProfilePictureUrl(image.webPath);
    setIsLoading(true);
    try {
      const format = image.format;
      const fileName = `${process.env.REACT_APP_NAME}/${uuid()}.${format}`;
      const base64Data = await base64FromPath(image.webPath!);
      await Filesystem.writeFile({
        path: fileName,
        data: base64Data,
        directory: Directory.Documents,
        encoding: Encoding.UTF8,
      });
      const contents = await Filesystem.readFile({
        path: fileName,
        directory: Directory.Documents,
        encoding: Encoding.UTF8,
      });

      const res = await fetch(contents.data);
      const blob = await res.blob();
      const name = `${uuid()}.${format}`;
      const file = new File([blob], name, {
        type: `image/${format}`,
      });
      const result = await userService.updatePicture(token, file);
      if (result.error) {
        setErrorMessage(result.error);
        setIsLoading(false);
        return;
      }

      setErrorMessage("");
      setSuccessMessage(t("profilePictureUpdatedSuccess"));
      setUser(result.data);
      setIsLoading(false);
    } catch (err: any) {
      setErrorMessage(err.message);
      setIsLoading(false);
      return;
    }
  };

  const handleGenerateLink = async (e: any) => {
    e.preventDefault();
    await Clipboard.write({
      string: `${process.env.REACT_APP_URL}/npo/${user._id}`,
    });
    presentAlert({
      header: t("copied"),
      subHeader: "",
      message: t("copiedHint"),
      buttons: [t("ok")],
    });
  };

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{t("npoProfilePage")}</IonTitle>
            {size.screen !== BreakPoint.xs && size.screen !== BreakPoint.s && (
              <HeaderNavbar />
            )}
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large" className="ios-page-heading">
                {t("npoProfilePage")}
              </IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonGrid
            style={{
              padding: 0,
              maxWidth:
                size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                  ? "100%"
                  : Constants.MAX_GRID_WIDTH,
            }}
          >
            <IonRow style={Styles.orangeHeader} className="center">
              <IonCol size="9">
                <IonAvatar
                  onClick={handleProfilePictureCamera}
                  style={{
                    width: 100,
                    height: 100,
                    marginTop: 20,
                    marginLeft:
                      size.screen === BreakPoint.xs ||
                      size.screen === BreakPoint.s
                        ? size.width / 2 - 50
                        : Constants.MAX_GRID_WIDTH / 2 - 50,
                  }}
                >
                  <IonImg src={profilePictureUrl} alt={user.name} />
                </IonAvatar>
              </IonCol>
              <IonCol size="3" className="ion-text-center">
                <IonIcon
                  icon={notificationsOutline}
                  color="primary"
                  style={{
                    height: 25,
                    width: 25,
                    cursor: "pointer",
                  }}
                  onClick={(e: any) => history.push("/notifications")}
                />
              </IonCol>
              <IonCol size="12">
                <IonLabel
                  className="fwfs-500-18"
                  style={{
                    color: Constants.DARK_COLOR,
                  }}
                >
                  {user.name}
                </IonLabel>
              </IonCol>
              <IonCol size="12" style={{ marginTop: 10 }}>
                <IonLabel color="primary" className="fwfs-500-18">
                  {noOfSupporters}
                </IonLabel>{" "}
                <IonLabel
                  className="fwfs-400-14"
                  style={{
                    color: Constants.DARK_COLOR,
                  }}
                >
                  {t("supporters")}
                </IonLabel>
              </IonCol>
              <IonRow
                style={{
                  marginBottom: 20,
                  marginTop: 10,
                  width: "90%",
                }}
              >
                <IonCol size="6">
                  <Button
                    text={t("shareLink")}
                    type="button"
                    color="primary"
                    icon={shareSocialOutline}
                    onClick={handleGenerateLink}
                  />
                </IonCol>
                <IonCol size="6">
                  <Button
                    text={t("edit")}
                    type="button"
                    color="dark"
                    icon={createOutline}
                    onClick={() => history.push("/edit-basic")}
                  />
                </IonCol>
              </IonRow>
            </IonRow>
          </IonGrid>
          <IonGrid
            style={{
              marginTop: 10,
              maxWidth:
                size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                  ? "100%"
                  : Constants.MAX_GRID_WIDTH,
            }}
          >
            <IonRow>
              <IonCol size="6">
                <IonLabel className="fwfs-500-18">{t("details")}</IonLabel>
              </IonCol>
              <IonCol size="6" className="ion-text-end">
                <LinkButton
                  to="/edit-details"
                  text={t("edit")}
                  textClassName="fwfs-500-14"
                />
              </IonCol>
              <IonItemDivider style={{ minHeight: 10 }} />
              <IonCol size="12" className="fwfs-400-14">
                <IonLabel>{user.details}</IonLabel>
              </IonCol>
              <IonCol size="6" className="mt-20">
                <IonLabel className="fwfs-500-18">{t("whatWeDo")}</IonLabel>
              </IonCol>
              <IonCol size="6" className="ion-text-end mt-20">
                <LinkButton
                  to="/edit-about"
                  text={t("edit")}
                  textClassName="fwfs-500-14"
                />
              </IonCol>
              <IonItemDivider style={{ minHeight: 10 }} />
              <IonCol size="12" className="fwfs-400-14">
                <IonLabel>{user.about}</IonLabel>
              </IonCol>

              <IonCol size="6" className="mt-20">
                <IonLabel className="fwfs-500-18">
                  {t("activeProjects")}
                </IonLabel>
              </IonCol>
              <IonCol size="6" className="ion-text-end mt-20">
                <LinkButton
                  to="/projects"
                  text={t("edit")}
                  textClassName="fwfs-500-14"
                />
              </IonCol>
              <IonItemDivider style={{ minHeight: 10 }} />
              <IonCol size="12" className="mt-10">
                <IonSlides
                  pager={true}
                  scrollbar={false}
                  options={{ initialSlide: 1, speed: 400 }}
                >
                  {projects.map((project: any, idx: number) => (
                    <IonSlide key={idx} style={{ paddingBottom: 30 }}>
                      <ActiveProjectSlide data={project} npoId={user?._id} />
                    </IonSlide>
                  ))}
                </IonSlides>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                ></div>
              </IonCol>

              <IonCol size="8" className="mt-20">
                <IonLabel className="fwfs-500-18">
                  {t("whatWeveAchieved")}
                </IonLabel>
              </IonCol>
              <IonCol size="4" className="ion-text-end mt-20">
                <LinkButton
                  to="/achievements"
                  text={t("edit")}
                  textClassName="fwfs-500-14"
                />
              </IonCol>
              <IonItemDivider style={{ minHeight: 10 }} />
              <IonCol size="12" className="mt-10">
                <IonSlides
                  pager={true}
                  scrollbar={false}
                  options={{ initialSlide: 1, speed: 400 }}
                >
                  {achievements.map((achievement: any, idx: number) => (
                    <IonSlide key={idx} style={{ paddingBottom: 30 }}>
                      <ActiveAchievementSlide data={achievement} />
                    </IonSlide>
                  ))}
                </IonSlides>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                ></div>
              </IonCol>

              <IonCol size="12" className="mt-20">
                <IonLabel className="fwfs-500-18">{t("analytics")}</IonLabel>
              </IonCol>
              <IonItemDivider style={{ minHeight: 10 }} />
              <IonCol size="12" className="mt-20">
                <NpoAnalytics npoId={user?._id} />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Profile;
