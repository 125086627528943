import { IonGrid, IonRow, IonCol, IonList } from "@ionic/react";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import Constants from "../../utils/constants";
import NpoCard from "./NpoCard";
import { useState, useEffect } from "react";
import userService from "../../services/user";
import useStore from "../../store/store";
import { Hint } from "../../controls";
import { useTranslation } from "react-i18next";

const TopNpos = ({ category, excludeNpoId }: any) => {
  const { t } = useTranslation();
  const { setIsLoading } = useStore((state: any) => state);
  const size = useScreenSize();
  const [list, setList] = useState<any>([]);
  const [likes, setLikes] = useState<any>([]);

  useEffect(() => {
    setIsLoading(true);
    userService.getTopNpos({ category }).then((result) => {
      if (result.data) {
        let t: any[] = result.data || [];
        t = t.filter((x: any) => x._id.toString() !== excludeNpoId.toString());
        setList(t);
      }
    });
    setIsLoading(false);
  }, []);

  return (
    <IonGrid
      style={{
        padding: 0,
        maxWidth:
          size.screen === BreakPoint.xs || size.screen === BreakPoint.s
            ? "100%"
            : Constants.MAX_GRID_WIDTH,
      }}
    >
      <IonRow>
        <IonCol size="12" style={{ padding: 0 }}>
          <IonList lines="none">
            {list.length === 0 && <Hint text={t("noOtherSimilarNpoFound")} />}
            {list.map((k: any, i: number) => (
              <NpoCard obj={k} key={i} userLikes={likes} />
            ))}
          </IonList>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default TopNpos;
