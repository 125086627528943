import { useState, useEffect } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonLabel,
  IonList,
  IonItem,
  IonNote,
  useIonAlert,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import {
  hammerOutline,
  infiniteOutline,
  informationCircleOutline,
  keyOutline,
  logOutOutline,
  megaphoneOutline,
  walletOutline,
  languageOutline,
} from "ionicons/icons";
import Session from "../../store/session";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import Constants from "../../utils/constants";
import HeaderNavbar from "../../components/HeaderNavbar";
import FeedbackPopup from "../../components/FeedbackPopup";
import ReactGA from "react-ga4";
import LanguagePopup from "../../components/LanguagePopup";
import { useTranslation } from "react-i18next";
import useStore from "../../store/store";

const Settings = () => {
  const history = useHistory();
  const [presentAlert] = useIonAlert();
  const size = useScreenSize();
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] =
    useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const { setLanguage } = useStore((state: any) => state);
  const [isOpenLanguage, setIsOpenLanguage] = useState<boolean>(false);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href });
  }, []);

  const handleLogout = () => {
    presentAlert({
      header: t("sureLogout"),
      buttons: [
        {
          text: t("cancel"),
          role: "cancel",
          handler: () => {},
        },
        {
          text: t("logout"),
          role: "confirm",
          handler: () => {
            Session.logout();
            window.location.href = "/login";
          },
        },
      ],
    });
  };

  return (
    <>
      <LanguagePopup
        isOpen={isOpenLanguage}
        onClose={() => setIsOpenLanguage(false)}
        onChange={(lang: string) => {
          i18n.changeLanguage(lang);
          setLanguage(lang);
          setIsOpenLanguage(false);
        }}
      />

      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{t("settings")}</IonTitle>
            {size.screen !== BreakPoint.xs && size.screen !== BreakPoint.s && (
              <HeaderNavbar />
            )}
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large" className="ios-page-heading">
                {t("settings")}
              </IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonGrid
            style={{
              padding: 0,
              maxWidth:
                size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                  ? "100%"
                  : Constants.MAX_GRID_WIDTH,
            }}
          >
            <IonRow>
              <IonCol size="12">
                <IonList>
                  <IonItem
                    detail={true}
                    button
                    onClick={(e) => history.push("/preferences")}
                  >
                    <IonIcon icon={hammerOutline} slot="start" />
                    <IonLabel>
                      <h3>{t("preferences")}</h3>
                      <IonNote
                        slot="helper"
                        className="ion-text-wrap fwfs-500-14"
                      >
                        {t("accountPreferences")}
                      </IonNote>
                    </IonLabel>
                  </IonItem>
                  <IonItem
                    detail={true}
                    button
                    onClick={(e) => history.push("/transactions")}
                  >
                    <IonIcon icon={walletOutline} slot="start" />
                    <IonLabel>
                      <h3>{t("donationHistory")}</h3>
                      <IonNote
                        slot="helper"
                        className="ion-text-wrap fwfs-500-14"
                      >
                        {t("listOfDonationsMade")}
                      </IonNote>
                    </IonLabel>
                  </IonItem>
                  <IonItem
                    detail={true}
                    button
                    onClick={(e) => history.push("/subscriptions")}
                  >
                    <IonIcon icon={infiniteOutline} slot="start" />
                    <IonLabel>
                      <h3>{t("subscriptions")}</h3>
                      <IonNote
                        slot="helper"
                        className="ion-text-wrap fwfs-500-14"
                      >
                        {t("listOfSubscriptions")}
                      </IonNote>
                    </IonLabel>
                  </IonItem>
                  <IonItem
                    detail={true}
                    button
                    onClick={(e) => setIsOpenLanguage(true)}
                  >
                    <IonIcon icon={languageOutline} slot="start" />
                    <IonLabel>
                      <h3>{t("switchLanguage")}</h3>
                      <IonNote
                        slot="helper"
                        className="ion-text-wrap fwfs-500-14"
                      >
                        {t("switchLanguageHint")}
                      </IonNote>
                    </IonLabel>
                  </IonItem>
                  <IonItem
                    detail={true}
                    button
                    onClick={(e) => history.push("/update-password")}
                  >
                    <IonIcon icon={keyOutline} slot="start" />
                    <IonLabel>
                      <h3>{t("changePassword")}</h3>
                      <IonNote
                        slot="helper"
                        className="ion-text-wrap fwfs-500-14"
                      >
                        {t("changeYourAccountPassword")}
                      </IonNote>
                    </IonLabel>
                  </IonItem>
                  <IonItem
                    detail={true}
                    button
                    onClick={(e) => history.push("/qa")}
                  >
                    <IonIcon icon={informationCircleOutline} slot="start" />
                    <IonLabel>
                      <h3>{t("qa")}</h3>
                      <IonNote
                        slot="helper"
                        className="ion-text-wrap fwfs-500-14"
                      >
                        {process.env.REACT_APP_NAME} {t("faqs")}
                      </IonNote>
                    </IonLabel>
                  </IonItem>
                  <IonItem
                    detail={true}
                    button
                    onClick={() => setIsFeedbackModalOpen(true)}
                  >
                    <IonIcon icon={megaphoneOutline} slot="start" />
                    <IonLabel>
                      <h3>{t("feedback")}</h3>
                      <IonNote
                        slot="helper"
                        className="ion-text-wrap fwfs-500-14"
                      >
                        {t("feedbackHint")}
                      </IonNote>
                    </IonLabel>
                  </IonItem>
                  <IonItem button onClick={handleLogout}>
                    <IonIcon icon={logOutOutline} slot="start" color="danger" />
                    <IonLabel>
                      <h3>{t("logout")}</h3>
                      <IonNote
                        slot="helper"
                        className="ion-text-wrap fwfs-500-14"
                      >
                        {t("logoutHint")}
                      </IonNote>
                    </IonLabel>
                  </IonItem>
                </IonList>
              </IonCol>
            </IonRow>
          </IonGrid>

          <FeedbackPopup
            isOpen={isFeedbackModalOpen}
            onClose={() => setIsFeedbackModalOpen(false)}
          />
        </IonContent>
      </IonPage>
    </>
  );
};

export default Settings;
