import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonBackButton,
  IonButtons,
  IonItem,
  IonAvatar,
  IonImg,
  IonItemDivider,
} from "@ionic/react";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import Constants from "../../utils/constants";
import { useState, useEffect } from "react";
import userService from "../../services/user";
import npoService from "../../services/npo";
import paymentService from "../../services/payment";
import useStore from "../../store/store";
import { Hint } from "../../controls";
import SupportedNpoRow from "../../components/npo/SupportedNpoRow";
import Styles from "../../css/styles";
import imgAchievement1 from "../../images/achievement1.svg";
import imgAchievement2 from "../../images/achievement2.svg";
import HeaderNavbar from "../../components/HeaderNavbar";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const SupportedNpos = (props: any) => {
  const { t } = useTranslation();
  const { token } = useStore((state: any) => state);
  const size = useScreenSize();
  const [donar, setDonar] = useState<any>(null);
  const [donations, setDonations] = useState<any>(null);
  const [list, setList] = useState<any>([]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href });
  }, []);

  useEffect(() => {
    const id = props.match.params.donarId;
    if (!id) return;

    userService.getById(token, id).then((result) => {
      if (result.data) {
        const _donar: any = result.data;
        setDonar(_donar);
      }
    });

    npoService
      .getNposSupportedByUserId({
        userId: id,
        keyword: "",
      })
      .then((_r) => {
        const _npos: any = _r.data;
        setList(_npos);
      });

    paymentService
      .getSumDonationsByDonar({
        donarId: id,
      })
      .then((_r) => {
        const _donations: any = _r.data;
        setDonations(_donations);
      });
  }, [props.match.params.donarId, token]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton className="btn-back"></IonBackButton>
          </IonButtons>
          <IonTitle>{t("nposList")}</IonTitle>
          {size.screen !== BreakPoint.xs && size.screen !== BreakPoint.s && (
            <HeaderNavbar />
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large" className="ios-page-heading">
              {t("nposList")}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        {donar && (
          <IonGrid
            style={{
              padding: 0,
              maxWidth:
                size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                  ? "100%"
                  : Constants.MAX_GRID_WIDTH,
            }}
          >
            <IonRow style={Styles.orangeHeader} className="center">
              <IonCol size="12">
                <IonAvatar
                  style={{
                    width: 100,
                    height: 100,
                    marginTop: 20,
                    marginLeft:
                      size.screen === BreakPoint.xs ||
                      size.screen === BreakPoint.s
                        ? size.width / 2 - 50
                        : Constants.MAX_GRID_WIDTH / 2 - 50,
                  }}
                >
                  <IonImg src={donar?.fileUrl} alt={donar?.name} />
                </IonAvatar>
              </IonCol>
              <IonCol size="12" className="mt-10">
                <IonLabel
                  className="fwfs-500-24"
                  style={{
                    color: Constants.DARK_COLOR,
                  }}
                >
                  {donar?.name}
                </IonLabel>
              </IonCol>
              <IonCol
                size="12"
                className="center"
                style={{
                  width: "100%",
                  display: "flex",
                  flexWrap: "nowrap",
                }}
              >
                {donations?.sumMine >= Constants.BADGES.LOW.mine &&
                  donations?.sumReferal >= Constants.BADGES.LOW.ref && (
                    <IonImg
                      src={imgAchievement1}
                      style={{
                        width: 70,
                        height: 90,
                      }}
                    />
                  )}

                {donations?.sumMine >= Constants.BADGES.MED.mine &&
                  donations?.sumReferal >= Constants.BADGES.MED.ref && (
                    <IonImg
                      src={imgAchievement2}
                      style={{
                        width: 70,
                        height: 90,
                      }}
                    />
                  )}

                {donations?.sumMine >= Constants.BADGES.TOP.mine &&
                  donations?.sumReferal >= Constants.BADGES.TOP.ref && (
                    <IonImg
                      src={imgAchievement1}
                      style={{
                        width: 70,
                        height: 90,
                      }}
                    />
                  )}
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
        <IonGrid
          style={{
            padding: 0,
            maxWidth:
              size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                ? "100%"
                : Constants.MAX_GRID_WIDTH,
          }}
        >
          <IonRow>
            <IonCol size="12" className="mt-10">
              <IonLabel className="fwfs-500-18">{t("details")}</IonLabel>
            </IonCol>
            <IonItemDivider style={{ minHeight: 10 }} />
            <IonCol size="12" className="fwfs-400-14">
              <IonLabel>{donar?.about}</IonLabel>
            </IonCol>
            <IonCol size="12" className="mt-10">
              <IonLabel className="fwfs-500-18">
                {t("listOfAllNpos")} {donar?.name} {t("isSupporting")}
              </IonLabel>
            </IonCol>
            <IonCol
              size="12"
              style={{
                padding:
                  size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                    ? 10
                    : 0,
              }}
              className="mb-20"
            >
              <IonList lines="none">
                {list.length === 0 && (
                  <IonItem lines="none">
                    <Hint text={t("noFavoriteNpo")}></Hint>
                  </IonItem>
                )}
                {list.map((k: any, i: number) => (
                  <SupportedNpoRow data={k} key={i} />
                ))}
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default SupportedNpos;
