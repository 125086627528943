import { IonInput, IonItem, IonLabel, IonIcon } from "@ionic/react";

const Email = ({ label, value, icon, onChange, ...rest }: any) => {
  return (
    <IonItem fill="outline">
      {icon && <IonIcon slot="start" icon={icon}></IonIcon>}
      <IonLabel position="floating">{label}</IonLabel>
      <IonInput
        placeholder={label}
        type="email"
        inputmode="email"
        value={value}
        onIonInput={onChange}
        autocapitalize="off"
        autocorrect="off"
        autocomplete="off"
        autofocus={true}
        enterkeyhint="next"
        {...rest}
      ></IonInput>
    </IonItem>
  );
};

export default Email;
