import { IonLabel } from "@ionic/react";

const Hint = ({ text, ...rest }: any) => {
  return (
    <IonLabel
      color="medium"
      {...rest}
      style={{ fontSize: 14, fontWeight: 400 }}
    >
      {text}
    </IonLabel>
  );
};

export default Hint;
