import { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonList,
  IonItem,
  IonBackButton,
  IonNote,
  IonGrid,
  IonRow,
  IonCol,
  IonButtons,
  IonBadge,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/react";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import Constants from "../../utils/constants";
import paymentService from "../../services/payment";
import useStore from "../../store/store";
import HeaderNavbar from "../../components/HeaderNavbar";
import moment from "moment";
import Utils from "../../utils/utils";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const SummaryBox = ({ title, amount }: any) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        borderRadius: Constants.BORDER_RADIUS,
        padding: 10,
        margin: 1,
        backgroundColor: Constants.LIGHT_COLOR,
        cursor: "pointer",
      }}
    >
      <IonRow>
        <IonCol size="12">
          <IonLabel
            className="fwfs-500-16"
            style={{ color: Constants.DARK_COLOR }}
          >
            {title} <br />
            <IonNote className="fwfs-400-10">{t("toDate")}</IonNote>
          </IonLabel>
        </IonCol>
        <IonCol size="12" className="ion-text-end">
          <IonLabel
            className="fwfs-600-22"
            style={{ color: Constants.DARK_COLOR }}
          >
            {Constants.CURRENCY_SYMBOL}
            {Utils.formatToNumber(amount)}
          </IonLabel>
        </IonCol>
      </IonRow>
    </div>
  );
};

const Transactions = () => {
  const { t } = useTranslation();
  const { token } = useStore((state: any) => state);
  const size = useScreenSize();
  const [list, setList] = useState<any[]>([]);
  const [page, setPage] = useState<number>(0);
  const [mine, setMine] = useState<number>(0);
  const [referal, setReferal] = useState<number>(0);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href });
  }, []);

  useEffect(() => {
    reload();
  }, [page]);

  const reload = async () => {
    let result = await paymentService.getPaginatedByDonarId(token, {
      page: page,
    });
    if (result.data) {
      let _d: any = result.data;
      setList([...list, ..._d?.list]);
      setMine(_d.donations.sumMine || 0);
      setReferal(_d.donations.sumReferal || 0);
    }
  };

  const handleScroll = (e: any) => {
    setTimeout(() => {
      setPage(page + 1);
      e.target.complete();
    }, 500);
  };

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton className="btn-back"></IonBackButton>
            </IonButtons>
            <IonTitle>{t("transactions")}</IonTitle>
            {size.screen !== BreakPoint.xs && size.screen !== BreakPoint.s && (
              <HeaderNavbar />
            )}
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large" className="ios-page-heading">
                {t("transactions")}
              </IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonGrid
            style={{
              padding: 0,
              maxWidth:
                size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                  ? "100%"
                  : Constants.MAX_GRID_WIDTH,
            }}
          >
            <IonRow style={{ paddingLeft: 10, paddingRight: 10 }}>
              <IonCol size="6">
                <SummaryBox title="Referred" amount={referal} />
              </IonCol>
              <IonCol size="6">
                <SummaryBox title="Donated" amount={mine} />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12">
                <IonList>
                  {list.length === 0 && (
                    <IonItem>
                      <IonLabel className="fwfs-400-14">
                        {t("noDonations")}
                      </IonLabel>
                    </IonItem>
                  )}
                  {list.map((item: any, idx: number) => (
                    <IonItem key={item._id}>
                      <IonLabel>
                        <h2>{item?.projectId?.title}</h2>
                        <IonNote
                          slot="helper"
                          className="ion-text-nowrap fwfs-500-14"
                        >
                          {moment(item.date).format("DD MMM YYYY")}
                        </IonNote>
                      </IonLabel>
                      {item?.type === "subscription" && (
                        <IonBadge
                          color="primary"
                          className="ion-text-capitalize"
                        >
                          {t(item.type)}
                        </IonBadge>
                      )}
                      {item?.type === "paypal" && (
                        <IonBadge
                          color="primary"
                          className="ion-text-capitalize"
                        >
                          {t(item.type)}
                        </IonBadge>
                      )}
                      {item?.type === "points" && (
                        <IonBadge
                          color="medium"
                          className="ion-text-capitalize"
                        >
                          {t(item.type)}
                        </IonBadge>
                      )}
                      <IonLabel slot="end" color="primary">
                        {Constants.CURRENCY_SYMBOL}
                        {Utils.formatToNumber(item?.total)}
                      </IonLabel>
                    </IonItem>
                  ))}
                </IonList>
                <IonInfiniteScroll onIonInfinite={handleScroll} threshold="20%">
                  <IonInfiniteScrollContent
                    loadingText={t("loadingWait")}
                    loadingSpinner="bubbles"
                  ></IonInfiniteScrollContent>
                </IonInfiniteScroll>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Transactions;
