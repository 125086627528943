import { IonImg, IonLabel } from "@ionic/react";
import Constants from "../utils/constants";

const CategoriesGridItem = ({ title, img, selected, onChange }: any) => {
  return (
    <div
      title={title}
      style={{
        borderRadius: Constants.BORDER_RADIUS,
        padding: 20,
        margin: 1,
        backgroundColor: selected ? Constants.PRIMARY : Constants.LIGHT_COLOR,
        cursor: "pointer",
      }}
      onClick={(e) => {
        e.preventDefault();
        onChange(title.toLowerCase());
      }}
    >
      <IonImg
        src={img}
        alt={title}
        style={{ width: 55, height: 55, marginBottom: 10 }}
      />
      <IonLabel
        className="fwfs-500-16 truncate-1"
        style={{ color: Constants.DARK_COLOR }}
      >
        {title}
      </IonLabel>
    </div>
  );
};

export default CategoriesGridItem;
