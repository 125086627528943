import { IonIcon, IonImg, IonItem, IonLabel, IonThumbnail } from "@ionic/react";
import Constants from "../../utils/constants";
import imgPencil from "../../images/pencil.svg";
import { useHistory } from "react-router-dom";

const ProjectBox = ({ data }: any) => {
  const history = useHistory();
  return (
    <>
      <IonItem
        lines="none"
        className="mb-10"
        style={{
          marginLeft: 10,
          marginRight: 10,
          borderRadius: Constants.BORDER_RADIUS,
          padding: 5,
          backgroundColor: Constants.ORANGE_COLOR,
        }}
      >
        <IonThumbnail slot="start">
          <IonImg
            src={data.fileUrl}
            style={{
              borderRadius: 8,
              cursor: "pointer",
              objectFit: "cover",
              overflow: "hidden",
            }}
          />
        </IonThumbnail>
        <IonLabel
          className="fwfs-500-12"
          style={{ color: Constants.DARK_COLOR }}
        >
          {data.title}
        </IonLabel>
        <IonIcon
          icon={imgPencil}
          slot="end"
          style={{
            cursor: "pointer",
          }}
          onClick={(e) => history.push(`/projects/${data._id}`)}
        />
      </IonItem>
    </>
  );
};

export default ProjectBox;
