import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  IonBackButton,
  useIonAlert,
  IonButtons,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import useStore from "../../store/store";
import {
  Button,
  Date,
  Textarea,
  File as FileControl,
  Toggle,
} from "../../controls";
import achievementService from "../../services/achievement";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import Constants from "../../utils/constants";
import NpoHeader from "../../components/npo/NpoHeader";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";
import { v4 as uuid } from "uuid";
import DisplayAchievement from "../../components/npo/DisplayAchievement";
import { trash } from "ionicons/icons";
import HeaderNavbar from "../../components/HeaderNavbar";
import moment from "moment";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const NewAchievement: React.FC = (props: any) => {
  const { t } = useTranslation();
  const { token, setIsLoading } = useStore((state: any) => state);
  const size = useScreenSize();
  const history = useHistory();
  const [presentAlert] = useIonAlert();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [achievement, setAchievement] = useState<any>("");
  const [attachment, setAttachment] = useState<any>(null);
  const [details, setDetails] = useState<string>("");
  const [date, setDate] = useState<string>(moment().format("YYYY-MM-DD"));
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href });
  }, []);

  useEffect(() => {
    const id = props.match.params.achievementId;
    if (!id) return;
    achievementService.getById(token, id).then((result) => {
      if (result.data) {
        setAchievement(result.data);
        setAttachment(result.data["fileUrl"] || "");
        setDetails(result.data["text"] || "");
        setDate(result.data["dateAchieved"] || moment().format("YYYY-MM-DD"));
        setIsActive(result.data["active"]);
      }
    });
  }, []);

  const handleAchievementCamera = async () => {
    const image = await Camera.getPhoto({
      quality: 100,
      allowEditing: false,
      resultType: CameraResultType.Uri,
      source: CameraSource.Prompt,
      saveToGallery: true,
      promptLabelHeader: t("choose"),
      promptLabelCancel: t("close"),
      presentationStyle: "popover",
    });

    setAttachment(image.webPath);
    setIsLoading(true);
    try {
      const format = image.format;
      const fileName = `${process.env.REACT_APP_NAME}/${uuid()}.${format}`;
      const base64Data = await base64FromPath(image.webPath!);
      await Filesystem.writeFile({
        path: fileName,
        data: base64Data,
        directory: Directory.Documents,
        encoding: Encoding.UTF8,
      });
      const contents = await Filesystem.readFile({
        path: fileName,
        directory: Directory.Documents,
        encoding: Encoding.UTF8,
      });

      const res = await fetch(contents.data);
      const blob = await res.blob();
      const name = `${uuid()}.${format}`;
      const file = new File([blob], name, {
        type: `image/${format}`,
      });
      const result = await achievementService.updateMedia(
        token,
        achievement._id,
        file
      );
      if (result.error) {
        setErrorMessage(result.error || "");
        setIsLoading(false);
        return;
      }

      setErrorMessage("");
      setSuccessMessage(t("achievementMediaUpdatedSuccess"));
      setIsLoading(false);
    } catch (err: any) {
      setErrorMessage(err.message);
      setIsLoading(false);
      return;
    }
  };

  const base64FromPath = async (path: string): Promise<string> => {
    const response = await fetch(path);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        if (typeof reader.result === "string") {
          resolve(reader.result);
        } else {
          reject("method did not return a string");
        }
      };
      reader.readAsDataURL(blob);
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    if (!achievement) return;
    if (!details || details.length === 0) {
      setErrorMessage(t("detailsRequired"));
      return;
    }

    setIsLoading(true);
    achievementService
      .updateText(token, achievement._id, {
        text: details,
        active: isActive,
        dateAchieved: date,
      })
      .then((result) => {
        if (result.error) {
          setErrorMessage(result.error);
          setIsLoading(false);
          return;
        }

        const data = result?.data || false;
        if (!data) return;

        setIsLoading(false);
        setErrorMessage("");
        setSuccessMessage(t("achievementUpdatedSuccess"));
      });
  };

  const handleDeleteMedia = (e: any) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    if (!achievement) return;

    presentAlert({
      header: t("sureDelete"),
      buttons: [
        {
          text: t("cancel"),
          role: "cancel",
          handler: () => {},
        },
        {
          text: t("delete"),
          role: "confirm",
          handler: () => {
            setIsLoading(true);
            achievementService
              .deleteMedia(token, achievement._id)
              .then((result) => {
                if (result.error) {
                  setErrorMessage(result.error);
                  setIsLoading(false);
                  return;
                }

                const data = result?.data || false;
                if (!data) return;

                setIsLoading(false);
                setErrorMessage("");
                setAttachment(null);
                setSuccessMessage(t("mediaDeleteSuccess"));
              });
          },
        },
      ],
    });
  };

  const handleDelete = (e: any) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    if (!achievement) return;

    presentAlert({
      header: t("sureDelete"),
      buttons: [
        {
          text: t("cancel"),
          role: "cancel",
          handler: () => {},
        },
        {
          text: t("delete"),
          role: "confirm",
          handler: () => {
            achievementService
              .deleteById(token, achievement._id)
              .then((result) => {
                if (result.error) {
                  setErrorMessage(result.error);
                  setIsLoading(false);
                  return;
                }
                history.push("/achievements");
              });
          },
        },
      ],
    });
  };

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton className="btn-back"></IonBackButton>
            </IonButtons>
            <IonTitle>{t("achievementDetails")}</IonTitle>
            {size.screen !== BreakPoint.xs && size.screen !== BreakPoint.s && (
              <HeaderNavbar />
            )}
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large" className="ios-page-heading">
                {t("achievementDetails")}
              </IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonGrid
            style={{
              padding: 0,
              maxWidth:
                size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                  ? "100%"
                  : Constants.MAX_GRID_WIDTH,
            }}
          >
            <NpoHeader />
          </IonGrid>
          <IonGrid
            style={{
              marginTop: 10,
              maxWidth:
                size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                  ? "100%"
                  : Constants.MAX_GRID_WIDTH,
            }}
          >
            <IonRow>
              <IonCol size="12">
                <IonLabel className="fwfs-500-18">
                  {t("achievementDetails")}
                </IonLabel>
              </IonCol>
              <IonItemDivider style={{ minHeight: 10 }} />

              <IonCol size="12" className="mt-10">
                <Textarea
                  label={t("whatWeveAchieved")}
                  value={details}
                  onChange={(e: any) => setDetails(e.target.value)}
                />
              </IonCol>
              <IonCol size="12" className="mt-10">
                <Date
                  id="achievement-date"
                  label={t("date")}
                  value={date}
                  onChange={(e: any) => setDate(e.target.value)}
                />
              </IonCol>
              <IonCol size="12" className="mt-10">
                <Toggle
                  text={t("active")}
                  checked={isActive}
                  onChange={(e: any) => setIsActive(e.target.checked)}
                />
              </IonCol>
              {attachment && (
                <>
                  <IonCol size="12" className="mt-10">
                    <DisplayAchievement url={attachment} />
                  </IonCol>
                  <IonCol size="12" className="mt-10">
                    <Button
                      text={t("deleteMedia")}
                      onClick={handleDeleteMedia}
                      type="submit"
                      color="danger"
                      icon={trash}
                    />
                  </IonCol>
                </>
              )}
              <IonCol size="12">
                <FileControl
                  btnText={t("addMedia")}
                  color="primary"
                  onClick={handleAchievementCamera}
                />
              </IonCol>
              {errorMessage && (
                <IonCol size="12">
                  <IonLabel color="danger">{errorMessage}</IonLabel>
                </IonCol>
              )}
              {successMessage && (
                <IonCol size="12">
                  <IonLabel color="primary">{successMessage}</IonLabel>
                </IonCol>
              )}

              <IonCol size="6" className="mb-20 mt-20">
                <Button
                  text={t("deleteAchievement")}
                  onClick={handleDelete}
                  type="button"
                  color="danger"
                />
              </IonCol>
              <IonCol className="mb-20 mt-20" size="6">
                <Button
                  text={t("saveChanges")}
                  onClick={handleSubmit}
                  type="submit"
                  color="primary"
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </>
  );
};

export default NewAchievement;
