import {
  IonLabel,
  IonDatetime,
  IonDatetimeButton,
  IonModal,
  IonItem,
} from "@ionic/react";

const Date = ({ id, label, value, onChange }: any) => {
  return (
    <IonItem fill="outline" lines="none">
      <IonLabel>{label}</IonLabel>
      <IonDatetimeButton datetime={id}></IonDatetimeButton>
      <IonModal keepContentsMounted={true}>
        <IonDatetime
          id={id}
          presentation="date"
          showDefaultButtons={true}
          value={value}
          onIonChange={onChange}
        >
          <span slot="title">{label}</span>
        </IonDatetime>
      </IonModal>
    </IonItem>
  );
};

export default Date;
