import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonBackButton,
  IonImg,
  IonAvatar,
  IonIcon,
  IonItemDivider,
  IonSlides,
  IonSlide,
  useIonAlert,
  IonButtons,
} from "@ionic/react";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import Constants from "../../utils/constants";
import Utils from "../../utils/utils";
import { useState, useEffect } from "react";
import npoService from "../../services/npo";
import likeService from "../../services/like";
import useStore from "../../store/store";
import Styles from "../../css/styles";
import { Button, Hint, LinkButton } from "../../controls";
import {
  heart,
  heartOutline,
  shareSocialOutline,
  walletOutline,
} from "ionicons/icons";
import ActiveProjectSlide from "../../components/npo/ActiveProjectSlide";
import ActiveAchievementSlide from "../../components/npo/ActiveAchievementSlide";
import TopNpos from "../../components/npo/TopNpos";
import TopDonars from "../../components/npo/TopDonars";
import { useHistory } from "react-router-dom";
import { Clipboard } from "@capacitor/clipboard";
import HeaderNavbar from "../../components/HeaderNavbar";
import NpoAnalytics from "../../components/npo/NpoAnalytics";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const NpoDetails = (props: any) => {
  const { t } = useTranslation();
  const { user, token, isLoggedIn, setIsLoading } = useStore(
    (state: any) => state
  );
  const size = useScreenSize();
  const history = useHistory();
  const [presentAlert] = useIonAlert();
  const [npo, setNpo] = useState<any>(null);
  const [isLiked, setIsLiked] = useState(false);
  const [likes, setLikes] = useState<any>([]);
  const [achievements, setAchievements] = useState<any>([]);
  const [projects, setProjects] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  const [noOfSupporters, setNoOfSupporters] = useState<number>(0);
  const [noOfFavorites, setNoOfFavorites] = useState<number>(0);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href });
  }, []);

  useEffect(() => {
    const id = props.match.params.id || user._id;
    if (!id) return;
    reload(id);
  }, []);

  const reload = async (id: string) => {
    const result = await npoService.getById(token, id);
    if (result.error) {
      setErrorMessage(result.error);
      return;
    }
    const _npo: any = result.data;
    setNpo(_npo);
    setProjects(_npo?.projects);
    setAchievements(_npo?.achievements);
    setLikes(_npo?.likes);

    let sup = Utils.groupArrayOfObjects(_npo?.payments, "donarId") || {};
    setNoOfSupporters(Object.keys(sup).length);
    setNoOfFavorites(_npo?.likes?.length);

    const t: any[] = _npo?.likes || [];
    t.forEach((k: any) => {
      if (k.userId?.toString() === user["_id"]?.toString()) {
        setIsLiked(true);
      }
    });
  };

  const handleLike = (e: any) => {
    e.preventDefault();

    if (!isLoggedIn || !token) {
      history.push("/signup");
      return;
    }

    if (isLiked) {
      const like = likes.find(
        (k: any) => k.userId.toString() === user["_id"].toString()
      );
      if (!like) return;
      setIsLoading(true);
      likeService.remove(token, like._id).then((result) => {
        if (result.error) {
          setIsLoading(false);
          return;
        }

        setIsLiked(false);
        setIsLoading(false);
      });
    } else {
      setIsLoading(true);
      likeService.create(token, npo?._id).then((result) => {
        if (result.error) {
          setIsLoading(false);
          return;
        }

        setIsLiked(true);
        setIsLoading(false);
      });
    }
  };

  const handleGenerateLink = async (e: any) => {
    e.preventDefault();
    await Clipboard.write({
      string: `${process.env.REACT_APP_URL}/npo/${npo?._id}`,
    });
    presentAlert({
      header: t("copied"),
      subHeader: "",
      message: t("copiedHint"),
      buttons: [t("ok")],
    });
  };

  const handleDonate = async (e: any) => {
    e.preventDefault();
    presentAlert({
      header: t("donateToProjects"),
      subHeader: "",
      message: t("donateToProjectsHint"),
      buttons: [t("ok")],
    });
  };

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton className="btn-back"></IonBackButton>
            </IonButtons>
            <IonTitle>{t("npoProfilePage")}</IonTitle>
            {size.screen !== BreakPoint.xs && size.screen !== BreakPoint.s && (
              <HeaderNavbar />
            )}
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large" className="ios-page-heading">
                {t("npoProfilePage")}
              </IonTitle>
            </IonToolbar>
          </IonHeader>
          {npo && (
            <IonGrid
              style={{
                padding: 0,
                maxWidth:
                  size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                    ? "100%"
                    : Constants.MAX_GRID_WIDTH,
              }}
            >
              {errorMessage && (
                <IonCol size="12">
                  <IonLabel color="danger">{errorMessage}</IonLabel>
                </IonCol>
              )}
              {successMessage && (
                <IonCol size="12">
                  <IonLabel color="primary">{successMessage}</IonLabel>
                </IonCol>
              )}

              <IonRow style={Styles.orangeHeader} className="center">
                <IonCol size="9">
                  <IonAvatar
                    style={{
                      width: 100,
                      height: 100,
                      marginTop: 20,
                      marginLeft:
                        size.screen === BreakPoint.xs ||
                        size.screen === BreakPoint.s
                          ? size.width / 2 - 50
                          : Constants.MAX_GRID_WIDTH / 2 - 50,
                    }}
                  >
                    <IonImg src={npo?.fileUrl} alt={npo?.name} />
                  </IonAvatar>
                </IonCol>
                <IonCol size="3" className="ion-text-center">
                  <IonIcon
                    icon={isLiked ? heart : heartOutline}
                    color="primary"
                    style={{
                      height: 25,
                      width: 25,
                      cursor: "pointer",
                    }}
                    onClick={handleLike}
                  />
                </IonCol>
                <IonCol size="12">
                  <IonLabel
                    className="fwfs-500-18"
                    style={{
                      color: Constants.DARK_COLOR,
                    }}
                  >
                    {npo?.name}
                  </IonLabel>
                </IonCol>
                <IonCol size="12" style={{ marginTop: 10 }}>
                  <IonLabel color="primary" className="fwfs-500-18">
                    {noOfSupporters}
                  </IonLabel>{" "}
                  <IonLabel
                    className="fwfs-400-14"
                    style={{
                      color: Constants.DARK_COLOR,
                    }}
                  >
                    {noOfSupporters < 2 ? "Supporter" : "Supporters"}
                  </IonLabel>
                </IonCol>
                <IonCol size="12">
                  <IonLabel color="primary" className="fwfs-500-18">
                    {noOfFavorites}
                  </IonLabel>{" "}
                  <IonLabel
                    className="fwfs-400-14"
                    style={{
                      color: Constants.DARK_COLOR,
                    }}
                  >
                    {noOfFavorites < 2 ? "Favorite" : "Favorites"}
                  </IonLabel>
                </IonCol>
                <IonRow
                  style={{
                    marginBottom: 20,
                    marginTop: 10,
                    width: "90%",
                  }}
                >
                  <IonCol size="6">
                    <Button
                      text={t("donateNow")}
                      type="button"
                      color="primary"
                      onClick={handleDonate}
                      icon={walletOutline}
                      disabled={user?.type === "npo"}
                    />
                  </IonCol>
                  <IonCol size="6">
                    <Button
                      text={t("share")}
                      type="button"
                      color="dark"
                      icon={shareSocialOutline}
                      onClick={handleGenerateLink}
                    />
                  </IonCol>
                </IonRow>
              </IonRow>
            </IonGrid>
          )}
          {npo && (
            <IonGrid
              style={{
                marginTop: 10,
                maxWidth:
                  size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                    ? "100%"
                    : Constants.MAX_GRID_WIDTH,
              }}
            >
              <IonRow>
                <IonCol size="12" className="mt-10">
                  <IonLabel className="fwfs-500-18">{t("details")}</IonLabel>
                </IonCol>
                <IonItemDivider style={{ minHeight: 10 }} />
                <IonCol size="12" className="fwfs-400-14">
                  <IonLabel>{npo?.details}</IonLabel>
                </IonCol>
                <IonCol size="12" className="mt-20">
                  <IonLabel className="fwfs-500-18">{t("whatTheyDo")}</IonLabel>
                </IonCol>
                <IonItemDivider style={{ minHeight: 10 }} />
                <IonCol size="12" className="fwfs-400-14">
                  <IonLabel>{npo?.about}</IonLabel>
                </IonCol>

                <IonCol size="12" className="mt-20">
                  <IonLabel className="fwfs-500-18">
                    {t("activeProjects")}
                  </IonLabel>
                </IonCol>
                <IonItemDivider style={{ minHeight: 10 }} />
                <IonCol size="12" className="mt-10">
                  {projects.length === 0 && <Hint text={t("noProjects")} />}
                  {projects.length > 0 && (
                    <IonSlides
                      pager={true}
                      scrollbar={false}
                      options={{
                        initialSlide: 1,
                        speed: 400,
                      }}
                    >
                      {projects.map((project: any, idx: number) => (
                        <IonSlide
                          key={idx}
                          style={{
                            paddingBottom: 30,
                          }}
                        >
                          <ActiveProjectSlide data={project} npoId={npo?._id} />
                        </IonSlide>
                      ))}
                    </IonSlides>
                  )}
                </IonCol>

                <IonCol size="12" className="mt-20">
                  <IonLabel className="fwfs-500-18">
                    {t("whatTheyveAchieved")}
                  </IonLabel>
                </IonCol>
                <IonItemDivider style={{ minHeight: 10 }} />
                <IonCol size="12" className="mt-10">
                  {achievements.length === 0 && (
                    <Hint text={t("noAchievements")} />
                  )}
                  {achievements.length > 0 && (
                    <IonSlides
                      pager={true}
                      scrollbar={false}
                      options={{
                        initialSlide: 1,
                        speed: 400,
                      }}
                    >
                      {achievements.map((achievement: any, idx: number) => (
                        <IonSlide
                          key={idx}
                          style={{
                            paddingBottom: 30,
                          }}
                        >
                          <ActiveAchievementSlide data={achievement} />
                        </IonSlide>
                      ))}
                    </IonSlides>
                  )}
                </IonCol>

                {npo?.showDonationsOnProfile && (
                  <>
                    <IonCol size="12">
                      <IonLabel className="fwfs-500-18">
                        {t("donations")}
                      </IonLabel>
                    </IonCol>
                    <IonItemDivider style={{ minHeight: 10 }} />
                    <IonCol size="12" className="mt-10">
                      <NpoAnalytics npoId={npo?._id} />
                    </IonCol>
                  </>
                )}

                <IonCol size="6" className="mt-20">
                  <IonLabel className="fwfs-500-18">{t("topDonors")}</IonLabel>
                </IonCol>
                <IonCol size="6" className="ion-text-end">
                  <LinkButton
                    to={`/donars/${npo?._id}`}
                    text="View all"
                    textClassName="fwfs-500-14"
                  />
                </IonCol>
                <IonItemDivider style={{ minHeight: 10 }} />
                <IonCol size="12" className="mt-10">
                  <TopDonars npoId={npo?._id} excludeDonarId="" />
                </IonCol>
                <IonCol size="12" className="mt-20">
                  <IonLabel className="fwfs-500-18">
                    {t("otherRelatedOrgs")}
                  </IonLabel>
                </IonCol>
                <IonItemDivider style={{ minHeight: 10 }} />
                <IonCol size="12" className="mt-10 mb-20">
                  <TopNpos category={npo?.category} excludeNpoId={npo?._id} />
                </IonCol>
              </IonRow>
            </IonGrid>
          )}
        </IonContent>
      </IonPage>
    </>
  );
};

export default NpoDetails;
