import { useState } from "react";
import {
  IonLabel,
  IonItem,
  IonImg,
  IonIcon,
  IonThumbnail,
  useIonAlert,
} from "@ionic/react";
import Constants from "../../utils/constants";
import { heart, heartOutline } from "ionicons/icons";
import likeService from "../../services/like";
import useStore from "../../store/store";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FavouriteNpoRow = ({ obj, liked, showLikeButton }: any) => {
  const { t } = useTranslation();
  const [presentAlert] = useIonAlert();
  const history = useHistory();
  const { token, setIsLoading } = useStore((state: any) => state);
  const [isLiked, setIsLiked] = useState(liked);

  const handleLike = (e: any) => {
    e.preventDefault();
    if (!obj) return;

    presentAlert({
      header: t("sureRemove"),
      buttons: [
        {
          text: t("cancel"),
          role: "cancel",
          handler: () => {},
        },
        {
          text: t("remove"),
          role: "confirm",
          handler: () => {
            setIsLoading(true);
            likeService.remove(token, obj._id).then((result) => {
              if (result.error) {
                setIsLoading(false);
                return;
              }

              setIsLiked(false);
              setIsLoading(false);
            });
          },
        },
      ],
    });
  };

  return (
    <>
      <IonItem
        lines="none"
        className="mb-10"
        style={{
          borderRadius: Constants.BORDER_RADIUS,
          padding: 5,
          backgroundColor: Constants.LIGHT_COLOR,
        }}
      >
        <IonThumbnail slot="start">
          <IonImg
            src={obj.npoId.fileUrl}
            style={{
              borderRadius: 8,
              cursor: "pointer",
              objectFit: "cover",
              overflow: "hidden",
            }}
            onClick={(e) => history.push(`/npo/${obj.npoId._id}`)}
          />
        </IonThumbnail>
        <IonLabel
          className="ion-text-wrap npo-name"
          style={{ cursor: "pointer" }}
          onClick={(e) => history.push(`/npo/${obj.npoId._id}`)}
        >
          {obj.npoId.name}
        </IonLabel>
        {showLikeButton === true && (
          <IonIcon
            icon={isLiked ? heart : heartOutline}
            slot="end"
            color="primary"
            style={{
              cursor: "pointer",
            }}
            onClick={handleLike}
          />
        )}
      </IonItem>
    </>
  );
};

export default FavouriteNpoRow;
