import React, { useState, useEffect } from "react";
import {
  IonCol,
  IonGrid,
  IonImg,
  IonLabel,
  IonProgressBar,
  IonRow,
  useIonAlert,
} from "@ionic/react";
import useStore from "../../store/store";
import Constants from "../../utils/constants";
import imgTrophy from "../../images/trophy.svg";
import { Button } from "../../controls";
import { useHistory } from "react-router-dom";
import { Clipboard } from "@capacitor/clipboard";
import projectService from "../../services/project";
import Utils from "../../utils/utils";
import { shareSocialOutline, walletOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
const SHOW_DONAR_IMAGES = 9;

const ActiveProjectSlide = ({ data, npoId }: any) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [presentAlert] = useIonAlert();
  const { user } = useStore((state: any) => state);

  const style = {
    width: "100%",
    height: 300,
    borderTopRightRadius: Constants.BORDER_RADIUS,
    borderTopLeftRadius: Constants.BORDER_RADIUS,
    objectFit: "cover",
    overflow: "hidden",
  };
  const [donarsCount, setDonarsCount] = useState<number>(0);
  const [donars, setDonars] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);

  const handleShareLink = async (e: any) => {
    e.preventDefault();
    await Clipboard.write({
      string: `${process.env.REACT_APP_URL}/npo/${npoId}`,
    });
    presentAlert({
      header: t("copied"),
      subHeader: "",
      message: t("projectLinkCopied"),
      buttons: [t("ok")],
    });
  };

  useEffect(() => {
    projectService.getDonars({ projectId: data._id }).then((result) => {
      if (result.data) {
        const d: any = result?.data || { donars: [], total: 0 };
        setDonarsCount(d.donars.length || 0);
        setDonars(d.donars.slice(0, SHOW_DONAR_IMAGES));
        setTotal(d.total);
      }
    });
  }, []);

  return (
    <>
      <IonGrid
        style={{
          margin: 2,
          padding: 2,
        }}
      >
        <IonRow>
          <IonCol
            size="12"
            style={{
              margin: 0,
              padding: 0,
            }}
          >
            <IonImg src={data.fileUrl} style={style} />
          </IonCol>
        </IonRow>
        <IonRow
          style={{
            backgroundColor: Constants.ORANGE_COLOR,
            padding: 20,
            borderBottomRightRadius: Constants.BORDER_RADIUS,
            borderBottomLeftRadius: Constants.BORDER_RADIUS,
          }}
        >
          <IonCol size="12" className="ion-text-start">
            <IonLabel
              className="fwfs-600-18"
              style={{ color: Constants.DARK_COLOR }}
            >
              {data.title}
            </IonLabel>
          </IonCol>
          <IonCol size="6" className="ion-text-start">
            <IonLabel
              className="fwfs-500-14"
              style={{ color: Constants.DARK_COLOR }}
            >
              {parseFloat(
                ((total / (data?.goal || 1)) * 100).toString()
              ).toFixed(2)}
              %
            </IonLabel>
          </IonCol>
          <IonCol size="5" className="ion-text-end">
            <IonLabel
              className="fwfs-600-16"
              style={{ color: Constants.DARK_COLOR }}
            >
              {Constants.CURRENCY_SYMBOL}
              {Utils.formatToNumber(data.goal) || 0}
            </IonLabel>
          </IonCol>
          <IonCol size="1" className="ion-text-end">
            <IonImg src={imgTrophy} style={{ width: 32, height: 32 }} />
          </IonCol>
          <IonCol size="12" className="ion-text-center">
            <IonProgressBar
              value={total / (data.goal || 1)}
              type="determinate"
            ></IonProgressBar>
          </IonCol>
          <IonCol size="6" className="ion-text-start mt-10">
            <div
              style={{
                display: "flex",
                width: "100%",
                paddingLeft: 20,
              }}
            >
              {donars.map((supporter: any, idx: number) => (
                <IonImg
                  key={idx}
                  src={supporter.fileUrl}
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: 50,
                    objectFit: "cover",
                    overflow: "hidden",
                    float: "left",
                    marginLeft: -25,
                    border: "0.5px solid #fff",
                  }}
                />
              ))}
            </div>
          </IonCol>
          <IonCol size="6" className="ion-text-end mt-20">
            {donarsCount - SHOW_DONAR_IMAGES > 0 && (
              <IonLabel
                className="fwfs-400-12 fd"
                style={{
                  color: Constants.DARK_COLOR,
                }}
              >
                +
                {Utils.formatToNumber(
                  (donarsCount - SHOW_DONAR_IMAGES).toString()
                )}{" "}
                {t("peopleMore")}
              </IonLabel>
            )}
          </IonCol>
          <IonCol size="6" className="mt-10">
            <Button
              text={t("donateNow")}
              type="button"
              color="primary"
              icon={walletOutline}
              onClick={(e: any) => history.push(`/donate/${data._id}`)}
              disabled={user?.type === "npo"}
            />
          </IonCol>
          <IonCol size="6" className="mt-10">
            <Button
              text={t("share")}
              type="button"
              color="dark"
              icon={shareSocialOutline}
              onClick={handleShareLink}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default ActiveProjectSlide;
