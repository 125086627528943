import Keys from "./keys";

const excludeKeys = [Keys.isFirstTimeLaunch];

class Session {
	static set = (key: string, value: any) => localStorage.setItem(key, value);
	static setStringified = (key: string, value: any) =>
		localStorage.setItem(key, JSON.stringify(value));
	static get = (key: string) => localStorage.getItem(key);
	static getParsed = (key: string) =>
		localStorage.getItem(key)
			? JSON.parse(localStorage.getItem(key) || "")
			: false;
	static remove = (key: string) => localStorage.removeItem(key);
	static logout = () => {
		for (const [key, value] of Object.entries(Keys)) {
			if (excludeKeys.indexOf(value) === -1)
				localStorage.removeItem(value);
		}
	};
}

export default Session;
