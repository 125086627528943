import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import AnalyticTransactionBox from "./AnalyticTransactionBox";
import Constants from "../../utils/constants";
import paymentService from "../../services/payment";
import moment from "moment";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Analytics = ({ donarId }: any) => {
  const { t } = useTranslation();
  const [arrMine, setArrMine] = useState([]);
  const [arrRef, setArrRef] = useState([]);
  const [arrSum, setArrSum] = useState<any[]>([]);
  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    paymentService
      .getYearly({ year: moment().year(), donarId: donarId })
      .then((result) => {
        if (result.data) {
          setArrMine(result.data["mine"]);
          setArrRef(result.data["ref"]);

          let m: any[] = result.data["mine"];
          let r: any[] = result.data["ref"];
          let s: any[] = [];
          for (let i = 0; i < m.length; i++) s.push(m[i] + r[i]);
          setArrSum(s);
        }
      });

    paymentService
      .getTransactionsReferredByUserId({ donarId: donarId })
      .then((result) => {
        if (result.data) {
          const t: any[] = result?.data || [];
          setList(t.slice(0, 2));
        }
      });
  }, []);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: `${t("fundRaisingIn")} ${moment().year()}`,
      },
    },
  };

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: `${t("amount")} (${Constants.CURRENCY_SYMBOL})`,
        data: arrMine,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: `${t("myReference")} (${Constants.CURRENCY_SYMBOL})`,
        data: arrRef,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: `${t("total")} (${Constants.CURRENCY_SYMBOL})`,
        data: arrSum,
        borderColor: Constants.PRIMARY,
        backgroundColor: "rgba(243, 190, 74, 0.5)",
      },
    ],
  };

  return (
    <>
      <Line options={options} data={data} />
      <br />
      {list.map((transaction: any, idx: number) => (
        <AnalyticTransactionBox key={idx} data={transaction} />
      ))}
    </>
  );
};

export default Analytics;
