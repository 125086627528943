import { useState } from "react";
import useStore from "../store/store";
import { IonCol, IonImg, IonLabel, IonRow } from "@ionic/react";
import Constants from "../utils/constants";
import imgDonationSuccess from "../images/donation-success.svg";
import { Button } from "../controls";
import { useHistory } from "react-router-dom";
import { walletOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";

const DonationIsValueable = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useStore((state: any) => state);
  return (
    <>
      <div
        style={{
          backgroundColor: Constants.PRIMARY,
          height: 410,
          width: "100%",
          paddingTop: 20,
          borderRadius: Constants.BORDER_RADIUS,
        }}
      >
        <IonImg
          className="mb-40"
          src={imgDonationSuccess}
          style={{ height: 155 }}
        />

        <IonLabel
          className="fwfs-500-24"
          style={{
            padding: 20,
            color: Constants.DARK_COLOR,
          }}
        >
          {t("everyDonationIsValuable")}
        </IonLabel>

        <IonRow className="mt-30">
          <IonCol size="3"></IonCol>
          <IonCol size="6">
            <Button
              text={t("donateNow")}
              type="button"
              color="dark"
              icon={walletOutline}
              onClick={(e: any) => history.push("/npos")}
              disabled={user?.type === "npo"}
            ></Button>
          </IonCol>
          <IonCol size="3"></IonCol>
        </IonRow>
      </div>
    </>
  );
};

export default DonationIsValueable;
