import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonSlides,
  IonSlide,
  IonLabel,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import useScreenSize, { BreakPoint } from "../hooks/useScreenSize";
import Constants from "../utils/constants";
import useStore from "../store/store";
import { Button } from "../controls";
import { checkmarkCircleOutline } from "ionicons/icons";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import SwitchLanguageAuthScreens from "../components/SwitchLanguageAuthScreens";

const IntroductionSlide = ({ data }: any) => {
  return (
    <IonRow>
      <IonCol size="12">
        <IonLabel className="fwfs-500-18" style={{ color: Constants.PRIMARY }}>
          {data.heading}
        </IonLabel>
      </IonCol>
      <IonCol size="12">
        <IonLabel className="fwfs-400-14">{data.text}</IonLabel>
      </IonCol>
    </IonRow>
  );
};

const Introduction = () => {
  const { t, i18n } = useTranslation();
  const [userLocation, setUserLocation] = useState<string>("");
  const { setIsFirstTimeLaunch, setRefUserId } = useStore(
    (state: any) => state
  );
  const size = useScreenSize();
  const history = useHistory();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href });
  }, []);

  useEffect(() => {
    const id = window.location.href.split("/ref/")[1];
    if (!id || id.length !== 24) return;
    setRefUserId(id);
  }, []);

  const handleMoveOn = (e: any) => {
    e.preventDefault();
    setIsFirstTimeLaunch(false);
    history.push("/");
  };

  useEffect(() => {
    i18n.changeLanguage(userLocation === "jp" ? "jp" : "en");
  }, [userLocation]);

  useEffect(() => {
    getUserCountry();
  }, []);

  function getUserCountry() {
    try {
      fetch("http://api.hostip.info")
        .then((response) => response.text())
        .then((xml) => new window.DOMParser().parseFromString(xml, "text/xml"))
        .then((xmlDoc) => {
          let t =
            xmlDoc.getElementsByTagName("countryAbbrev")[0].innerHTML || "";
          setUserLocation(t?.toLowerCase());
        });
    } catch (ex) {}
  }

  const list = [
    {
      heading: t("introq1"),
      text: t("introa1"),
    },
    {
      heading: t("introq2"),
      text: t("introa2"),
    },
    {
      heading: t("introq3"),
      text: t("introa3"),
    },
  ];

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            {process.env.REACT_APP_NAME} {t("introduction")}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large" className="ios-page-heading">
              {process.env.REACT_APP_NAME} {t("introduction")}
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid
          style={{
            padding: 0,
            maxWidth:
              size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                ? "100%"
                : Constants.MAX_GRID_WIDTH,
          }}
        >
          <IonRow>
            <IonCol size="12">
              <IonSlides
                pager={true}
                scrollbar={false}
                options={{
                  initialSlide: 1,
                  speed: 400,
                }}
              >
                {list.map((slide: any, idx: number) => (
                  <IonSlide
                    key={idx}
                    style={{
                      paddingLeft: 30,
                      paddingRight: 30,
                      paddingBottom: 50,
                      paddingTop: 50,
                    }}
                  >
                    <IntroductionSlide data={slide} />
                  </IonSlide>
                ))}
              </IonSlides>
            </IonCol>
            <IonCol size="12" style={{ padding: 20 }}>
              <Button
                text={t("understood")}
                icon={checkmarkCircleOutline}
                type="submit"
                onClick={handleMoveOn}
              />
            </IonCol>
            <IonCol size="12" className="mt-50">
              <SwitchLanguageAuthScreens />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Introduction;
