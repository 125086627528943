import React, { useEffect, useState } from "react";
import {
  IonApp,
  IonIcon,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import {
  homeOutline,
  informationCircleOutline,
  gridOutline,
  personOutline,
  settingsOutline,
} from "ionicons/icons";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import "./css/Common.css";

/* Theme variables */
import "./theme/variables.css";
import useStore from "./store/store";
import Loading from "./components/Loading";
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/Signup";
import Activate from "./pages/auth/Activate";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import Home from "./pages/user/Home";
import Qa from "./pages/Qa";
import Npos from "./pages/npo/Npos";
import NpoDetails from "./pages/npo/NpoDetails";
import UserProfile from "./pages/user/Profile";
import UserEditBasic from "./pages/user/EditBasic";
import UserEditAbout from "./pages/user/EditAbout";
import UserFavouriteNpos from "./pages/user/FavouriteNpos";
import SupportedNpos from "./pages/user/SupportedNpos";
import UserSupportedNpos from "./pages/user/UserSupportedNpos";
import NpoProfile from "./pages/npo/Profile";
import NpoEditBasic from "./pages/npo/EditBasic";
import NpoEditDetails from "./pages/npo/EditDetails";
import NpoEditAbout from "./pages/npo/EditAbout";
import NpoAchievements from "./pages/npo/Achievements";
import NpoNewAchievement from "./pages/npo/NewAchievement";
import NpoProjects from "./pages/npo/Projects";
import NpoNewProject from "./pages/npo/NewProject";
import NpoSettings from "./pages/npo/Settings";
import UserSettings from "./pages/user/Settings";
import UserPreferences from "./pages/user/Preferences";
import NpoPreferences from "./pages/npo/Preferences";
import NpoTransactions from "./pages/npo/Transactions";
import NpoSubscriptions from "./pages/npo/Subscriptions";
import UpdatePassword from "./pages/auth/UpdatePassword";
import Donate from "./pages/npo/Donate";
import DonateNow from "./pages/npo/DonateNow";
import DonateSubscription from "./pages/npo/DonateSubscription";
import DonatePoints from "./pages/npo/DonatePoints";
import DonationSuccess from "./pages/npo/DonationSuccess";
import Donars from "./pages/user/Donars";
import DonarDetails from "./pages/user/DonarDetails";
import UserProfileRef from "./pages/user/UserProfileRef";
import UserTransactions from "./pages/user/Transactions";
import UserSubscriptions from "./pages/user/Subscriptions";
import Notifications from "./pages/Notifications";
import ReactGA from "react-ga4";
import Introduction from "./pages/Introduction";
import useScreenSize, { BreakPoint } from "./hooks/useScreenSize";
import { Route, Switch } from "react-router-dom";
import Notification from "./components/Notification";
import { onMessageListener } from "./utils/push-notification";
import { Capacitor } from "@capacitor/core";
import { useTranslation } from "react-i18next";
import axios from "axios";

setupIonicReact();

if (process.env.REACT_APP_FB_MEASUREMENT_ID) {
  ReactGA.initialize(process.env.REACT_APP_FB_MEASUREMENT_ID);
}

const App: React.FC = () => {
  const { i18n } = useTranslation();
  const size = useScreenSize();
  const {
    user,
    token,
    isLoggedIn,
    isFirstTimeLaunch,
    setNotification,
    language,
  } = useStore((state: any) => state);

  if (Capacitor.getPlatform() === "web") {
    onMessageListener().then((payload) =>
      setNotification(payload?.notification)
    );
  }

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <IonApp>
      <IonReactRouter>
        <Loading />
        {Capacitor.getPlatform() === "web" && <Notification />}
        {isFirstTimeLaunch === true ? (
          <Introduction />
        ) : (
          <IonTabs>
            <IonRouterOutlet>
              <Switch>
                <Route path="/login" exact component={Login} />
                <Route path="/signup" exact component={Signup} />
                <Route path="/activate" exact component={Activate} />
                <Route path="/fp" exact component={ForgotPassword} />
                <Route path="/rp" exact component={ResetPassword} />
                <Route
                  path="/"
                  exact
                  component={
                    isLoggedIn && token
                      ? user.type === "user"
                        ? Home
                        : NpoDetails
                      : Login
                  }
                />
                <Route
                  path="/home"
                  exact
                  component={
                    isLoggedIn && token
                      ? user.type === "user"
                        ? Home
                        : NpoDetails
                      : Login
                  }
                />
                <Route path="/qa" exact component={Qa} />
                <Route path="/npos" exact component={Npos} />
                <Route
                  path="/npo/:id"
                  exact
                  component={(props: any) => <NpoDetails {...props} />}
                />
                <Route
                  path="/profile"
                  exact
                  component={
                    isLoggedIn && token
                      ? user.type === "user"
                        ? UserProfile
                        : NpoProfile
                      : Login
                  }
                />
                <Route
                  path="/edit-basic"
                  exact
                  component={
                    isLoggedIn && token
                      ? user.type === "user"
                        ? UserEditBasic
                        : NpoEditBasic
                      : Login
                  }
                />
                <Route
                  path="/edit-details"
                  exact
                  component={isLoggedIn && token ? NpoEditDetails : Login}
                />
                <Route
                  path="/edit-about"
                  exact
                  component={
                    isLoggedIn && token
                      ? user.type === "user"
                        ? UserEditAbout
                        : NpoEditAbout
                      : Login
                  }
                />
                <Route
                  path="/achievements"
                  exact
                  component={isLoggedIn && token ? NpoAchievements : Login}
                />
                <Route
                  path="/achievements/:achievementId"
                  exact
                  component={(props: any) =>
                    isLoggedIn && token ? (
                      <NpoNewAchievement {...props} />
                    ) : (
                      <Login />
                    )
                  }
                />
                <Route
                  path="/projects"
                  exact
                  component={isLoggedIn && token ? NpoProjects : Login}
                />
                <Route
                  path="/projects/:projectId"
                  exact
                  component={(props: any) =>
                    isLoggedIn && token ? (
                      <NpoNewProject {...props} />
                    ) : (
                      <Login />
                    )
                  }
                />
                <Route
                  path="/settings"
                  exact
                  component={
                    isLoggedIn && token
                      ? user.type === "user"
                        ? UserSettings
                        : NpoSettings
                      : Login
                  }
                />
                <Route
                  path="/preferences"
                  exact
                  component={
                    isLoggedIn && token
                      ? user.type === "user"
                        ? UserPreferences
                        : NpoPreferences
                      : Login
                  }
                />
                <Route
                  path="/update-password"
                  exact
                  component={isLoggedIn && token ? UpdatePassword : Login}
                />
                <Route
                  path="/donate/:projectId"
                  exact
                  component={
                    isLoggedIn && token
                      ? user.type === "user"
                        ? Donate
                        : Login
                      : Login
                  }
                />
                <Route
                  path="/donate-now/:projectId"
                  exact
                  component={
                    isLoggedIn && token
                      ? user.type === "user"
                        ? DonateNow
                        : Login
                      : Login
                  }
                />
                <Route
                  path="/donate-subscription/:projectId"
                  exact
                  component={
                    isLoggedIn && token
                      ? user.type === "user"
                        ? DonateSubscription
                        : Login
                      : Login
                  }
                />
                <Route
                  path="/donate-points/:projectId"
                  exact
                  component={
                    isLoggedIn && token
                      ? user.type === "user"
                        ? DonatePoints
                        : Login
                      : Login
                  }
                />
                <Route
                  path="/donation-success"
                  exact
                  component={
                    isLoggedIn && token
                      ? user.type === "user"
                        ? DonationSuccess
                        : Login
                      : Login
                  }
                />
                <Route
                  path="/notifications"
                  exact
                  component={isLoggedIn && token ? Notifications : Login}
                />
                <Route
                  path="/favourite-npos"
                  exact
                  component={isLoggedIn && token ? UserFavouriteNpos : Login}
                />
                <Route
                  path="/supported-npos"
                  exact
                  component={isLoggedIn && token ? SupportedNpos : Login}
                />
                <Route path="/donars/:npoId" exact component={Donars} />
                <Route path="/donar/:donarId" exact component={DonarDetails} />
                <Route path="/ref/:userId" exact component={UserProfileRef} />
                <Route
                  path="/donar-supported-npos/:donarId"
                  exact
                  component={UserSupportedNpos}
                />
                <Route
                  path="/transactions"
                  exact
                  component={
                    isLoggedIn && token
                      ? user.type === "user"
                        ? UserTransactions
                        : NpoTransactions
                      : Login
                  }
                />
                <Route
                  path="/subscriptions"
                  exact
                  component={
                    isLoggedIn && token
                      ? user.type === "user"
                        ? UserSubscriptions
                        : NpoSubscriptions
                      : Login
                  }
                />
              </Switch>
            </IonRouterOutlet>
            <IonTabBar
              slot={
                size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                  ? "bottom"
                  : undefined
              }
            >
              {isLoggedIn && (
                <IonTabButton tab="home" href="/home">
                  <IonIcon icon={homeOutline} />
                </IonTabButton>
              )}
              {!isLoggedIn && (
                <IonTabButton tab="profile" href="/login">
                  <IonIcon icon={homeOutline} />
                </IonTabButton>
              )}
              {(!isLoggedIn || user.type === "user") && (
                <IonTabButton tab="npos" href="/npos">
                  <IonIcon icon={gridOutline} />
                </IonTabButton>
              )}
              {!isLoggedIn && (
                <IonTabButton tab="qa" href="/qa">
                  <IonIcon icon={informationCircleOutline} />
                </IonTabButton>
              )}
              {isLoggedIn && (
                <IonTabButton tab="profile" href="/profile">
                  <IonIcon icon={personOutline} />
                </IonTabButton>
              )}
              {isLoggedIn && (
                <IonTabButton tab="settings" href="/settings">
                  <IonIcon icon={settingsOutline} />
                </IonTabButton>
              )}
            </IonTabBar>
          </IonTabs>
        )}
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
