import { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonList,
  IonItem,
  IonBackButton,
  IonNote,
  IonGrid,
  IonRow,
  IonCol,
  IonButtons,
  IonBadge,
} from "@ionic/react";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import Constants from "../../utils/constants";
import subscriptionService from "../../services/subscription";
import useStore from "../../store/store";
import HeaderNavbar from "../../components/HeaderNavbar";
import moment from "moment";
import Utils from "../../utils/utils";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const Subscriptions = () => {
  const { t } = useTranslation();
  const { token, setIsLoading } = useStore((state: any) => state);
  const size = useScreenSize();
  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href });
  }, []);

  useEffect(() => {
    reload();
  }, []);

  const reload = async () => {
    setIsLoading(true);
    let result = await subscriptionService.getByNpoId(token);
    if (result.data) {
      setList(result.data);
      setIsLoading(false);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton className="btn-back"></IonBackButton>
          </IonButtons>
          <IonTitle>{t("subscriptions")}</IonTitle>
          {size.screen !== BreakPoint.xs && size.screen !== BreakPoint.s && (
            <HeaderNavbar />
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large" className="ios-page-heading">
              {t("subscriptions")}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid
          style={{
            padding: 0,
            maxWidth:
              size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                ? "100%"
                : Constants.MAX_GRID_WIDTH,
          }}
        >
          <IonRow>
            <IonCol size="12">
              <IonList>
                {list.length === 0 && (
                  <IonItem>
                    <IonLabel className="fwfs-400-14">
                      {t("noSubscriptions")}
                    </IonLabel>
                  </IonItem>
                )}
                {list.map((item: any, idx: number) => (
                  <IonItem key={item._id}>
                    <IonLabel>
                      <h2>{item?.projectId?.title}</h2>
                      <IonNote
                        slot="helper"
                        className="ion-text-wrap fwfs-500-14"
                      >
                        {t("since")} {moment(item.date).format("DD MMM YYYY")}
                      </IonNote>
                      <br />
                      <IonNote
                        slot="helper"
                        className="ion-text-wrap fwfs-500-14"
                      >
                        {item?.subscriptionId}
                      </IonNote>
                    </IonLabel>
                    {item?.status === "active" && (
                      <IonBadge color="primary" className="ion-text-capitalize">
                        {t(item.status)}
                      </IonBadge>
                    )}
                    {item?.status === "cancelled" && (
                      <IonBadge color="medium" className="ion-text-capitalize">
                        {t(item.status)}
                      </IonBadge>
                    )}
                    <IonLabel slot="end" color="primary">
                      {Constants.CURRENCY_SYMBOL}
                      {Utils.formatToNumber(item?.total)}
                    </IonLabel>
                  </IonItem>
                ))}
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Subscriptions;
