import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  IonBackButton,
  useIonAlert,
  IonButtons,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import useStore from "../../store/store";
import {
  Button,
  Textarea,
  File as FileControl,
  Toggle,
  Number,
} from "../../controls";
import projectService from "../../services/project";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import Constants from "../../utils/constants";
import NpoHeader from "../../components/npo/NpoHeader";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";
import { v4 as uuid } from "uuid";
import DisplayAchievement from "../../components/npo/DisplayAchievement";
import { trash } from "ionicons/icons";
import HeaderNavbar from "../../components/HeaderNavbar";
import CategoriesGridChooser from "../../components/CategoriesGridChooser";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const NewProject: React.FC = (props: any) => {
  const { t } = useTranslation();
  const { user, token, setIsLoading } = useStore((state: any) => state);
  const size = useScreenSize();
  const history = useHistory();
  const [presentAlert] = useIonAlert();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  const [project, setProject] = useState<any>("");
  const [attachment, setAttachment] = useState<any>(null);
  const [title, setTitle] = useState<string>("");
  const [goal, setGoal] = useState<any>("");
  const [isActive, setIsActive] = useState<boolean>(false);
  const [category, setCategory] = useState<string>();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href });
  }, []);

  useEffect(() => {
    const id = props.match.params.projectId;
    if (!id) return;
    setIsLoading(true);
    projectService.getById(token, id).then((result) => {
      if (result.data) {
        setProject(result.data);
        setAttachment(result.data["fileUrl"] || "");
        setTitle(result.data["title"] || "");
        setGoal(result.data["goal"] || "");
        setIsActive(result.data["active"]);
        setCategory(result.data["category"] || user.category || "");
      }
    });
    setIsLoading(false);
  }, []);

  const handleCamera = async () => {
    const image = await Camera.getPhoto({
      quality: 100,
      allowEditing: false,
      resultType: CameraResultType.Uri,
      source: CameraSource.Prompt,
      saveToGallery: true,
      promptLabelHeader: t("choose"),
      promptLabelCancel: t("close"),
      presentationStyle: "popover",
    });

    setAttachment(image.webPath);
    setIsLoading(true);
    try {
      const format = image.format;
      const fileName = `${process.env.REACT_APP_NAME}/${uuid()}.${format}`;
      const base64Data = await base64FromPath(image.webPath!);
      await Filesystem.writeFile({
        path: fileName,
        data: base64Data,
        directory: Directory.Documents,
        encoding: Encoding.UTF8,
      });
      const contents = await Filesystem.readFile({
        path: fileName,
        directory: Directory.Documents,
        encoding: Encoding.UTF8,
      });

      const res = await fetch(contents.data);
      const blob = await res.blob();
      const name = `${uuid()}.${format}`;
      const file = new File([blob], name, {
        type: `image/${format}`,
      });
      const result = await projectService.updateMedia(token, project._id, file);
      if (result.error) {
        setErrorMessage(result.error || "");
        setIsLoading(false);
        return;
      }

      setErrorMessage("");
      setSuccessMessage(t("projectMediaUpdatedSuccess"));
      setIsLoading(false);
    } catch (err: any) {
      setErrorMessage(err.message);
      setIsLoading(false);
      return;
    }
  };

  const base64FromPath = async (path: string): Promise<string> => {
    const response = await fetch(path);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        if (typeof reader.result === "string") {
          resolve(reader.result);
        } else {
          reject("method did not return a string");
        }
      };
      reader.readAsDataURL(blob);
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    if (!project) return;
    if (!title || title.length === 0) {
      setErrorMessage(t("titleRequired"));
      return;
    }
    if (!goal || isNaN(goal)) {
      setErrorMessage(t("goalRequired"));
      return;
    }
    if (!category || category.length === 0) {
      setErrorMessage(t("categoryRequired"));
      return;
    }

    setIsLoading(true);
    projectService
      .updateText(token, project._id, {
        title,
        goal,
        category,
        active: isActive,
      })
      .then((result) => {
        if (result.error) {
          setErrorMessage(result.error);
          setIsLoading(false);
          return;
        }

        const data = result?.data || false;
        if (!data) return;

        setIsLoading(false);
        setErrorMessage("");
        setSuccessMessage(t("projectUpdatedSuccess"));
      });
  };

  const handleDeleteMedia = (e: any) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    if (!project) return;

    presentAlert({
      header: t("sureDelete"),
      buttons: [
        {
          text: t("cancel"),
          role: "cancel",
          handler: () => {},
        },
        {
          text: t("delete"),
          role: "confirm",
          handler: () => {
            setIsLoading(true);
            projectService.deleteMedia(token, project._id).then((result) => {
              if (result.error) {
                setErrorMessage(result.error);
                setIsLoading(false);
                return;
              }

              const data = result?.data || false;
              if (!data) return;

              setIsLoading(false);
              setErrorMessage("");
              setAttachment(null);
              setSuccessMessage(t("mediaDeleteSuccess"));
            });
          },
        },
      ],
    });
  };

  const handleDelete = (e: any) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    if (!project) return;

    presentAlert({
      header: t("sureDelete"),
      buttons: [
        {
          text: t("cancel"),
          role: "cancel",
          handler: () => {},
        },
        {
          text: t("delete"),
          role: "confirm",
          handler: () => {
            projectService.deleteById(token, project._id).then((result) => {
              if (result.error) {
                setErrorMessage(result.error);
                setIsLoading(false);
                return;
              }
              history.push("/projects");
            });
          },
        },
      ],
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton className="btn-back"></IonBackButton>
          </IonButtons>
          <IonTitle>{t("projectDetails")}</IonTitle>
          {size.screen !== BreakPoint.xs && size.screen !== BreakPoint.s && (
            <HeaderNavbar />
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large" className="ios-page-heading">
              {t("projectDetails")}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid
          style={{
            padding: 0,
            maxWidth:
              size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                ? "100%"
                : Constants.MAX_GRID_WIDTH,
          }}
        >
          <NpoHeader />
        </IonGrid>
        <IonGrid
          style={{
            marginTop: 10,
            maxWidth:
              size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                ? "100%"
                : Constants.MAX_GRID_WIDTH,
          }}
        >
          <IonRow>
            <IonCol size="12">
              <IonLabel className="fwfs-500-18">{t("projectDetails")}</IonLabel>
            </IonCol>
            <IonItemDivider style={{ minHeight: 10 }} />

            <IonCol size="12" className="mt-10">
              <CategoriesGridChooser
                selected={category}
                onChange={setCategory}
              />
            </IonCol>

            <IonCol size="12" className="mt-10">
              <Textarea
                label={t("title")}
                value={title}
                onChange={(e: any) => setTitle(e.target.value)}
              />
            </IonCol>
            <IonCol size="12" className="mt-10">
              <Number
                label={`${t("goal")} (${Constants.CURRENCY_SYMBOL})`}
                value={goal}
                onChange={(e: any) => setGoal(e.target.value)}
              />
            </IonCol>
            <IonCol size="12" className="mt-10">
              <Toggle
                text={t("active")}
                checked={isActive}
                onChange={(e: any) => setIsActive(e.target.checked)}
              />
            </IonCol>
            {attachment && (
              <>
                <IonCol size="12" className="mt-10">
                  <DisplayAchievement url={attachment} />
                </IonCol>
                <IonCol size="12" className="mt-10">
                  <Button
                    text={t("deleteMedia")}
                    onClick={handleDeleteMedia}
                    type="submit"
                    color="danger"
                    icon={trash}
                  />
                </IonCol>
              </>
            )}
            <IonCol size="12">
              <FileControl
                btnText={t("featuredImage")}
                color="primary"
                onClick={handleCamera}
              />
            </IonCol>
            {errorMessage && (
              <IonCol size="12">
                <IonLabel color="danger">{errorMessage}</IonLabel>
              </IonCol>
            )}
            {successMessage && (
              <IonCol size="12">
                <IonLabel color="primary">{successMessage}</IonLabel>
              </IonCol>
            )}
            <IonCol size="6" className="mb-20 mt-20">
              <Button
                text={t("deleteProject")}
                onClick={handleDelete}
                type="button"
                color="danger"
              />
            </IonCol>
            <IonCol className="mb-20 mt-20" size="6">
              <Button
                text={t("saveChanges")}
                onClick={handleSubmit}
                type="submit"
                color="primary"
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default NewProject;
