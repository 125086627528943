import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  IonBackButton,
  IonButtons,
} from "@ionic/react";
import useStore from "../../store/store";
import { Button, Hint, Text } from "../../controls";
import npoService from "../../services/npo";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import Constants from "../../utils/constants";
import NpoHeader from "../../components/npo/NpoHeader";
import HeaderNavbar from "../../components/HeaderNavbar";
import CategoriesGridChooser from "../../components/CategoriesGridChooser";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const EditBasic: React.FC = () => {
  const { t } = useTranslation();
  const { user, token, setIsLoading, setUser } = useStore(
    (state: any) => state
  );
  const size = useScreenSize();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [category, setCategory] = useState<string>(user.category || "");
  const [name, setName] = useState<string>(user.name || "");

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href });
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    if (!name || name.length === 0) {
      setErrorMessage(t("nameRequired"));
      return;
    }

    if (!category || category.length === 0) {
      setErrorMessage(t("categoryRequired"));
      return;
    }

    setIsLoading(true);
    npoService
      .updateBasic(token, {
        name,
        category,
      })
      .then((result) => {
        if (result.error) {
          setErrorMessage(result.error);
          setIsLoading(false);
          return;
        }

        const data = result?.data || false;
        if (!data) return;

        setUser(data);
        setIsLoading(false);
        setErrorMessage("");
        setSuccessMessage(t("profileUpdateSuccess"));
      });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton className="btn-back"></IonBackButton>
          </IonButtons>
          <IonTitle>{t("editProfile")}</IonTitle>
          {size.screen !== BreakPoint.xs && size.screen !== BreakPoint.s && (
            <HeaderNavbar />
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large" className="ios-page-heading">
              {t("editProfile")}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid
          style={{
            padding: 0,
            maxWidth:
              size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                ? "100%"
                : Constants.MAX_GRID_WIDTH,
          }}
        >
          <NpoHeader />
        </IonGrid>
        <IonGrid
          style={{
            marginTop: 10,
            maxWidth:
              size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                ? "100%"
                : Constants.MAX_GRID_WIDTH,
          }}
        >
          <IonRow>
            <IonCol size="12">
              <IonLabel className="fwfs-500-18">{t("general")}</IonLabel>
            </IonCol>
            <IonItemDivider style={{ minHeight: 10 }} />

            <IonCol size="12">
              <CategoriesGridChooser
                selected={category}
                onChange={setCategory}
              />
            </IonCol>

            <IonCol size="12" className="mt-10">
              <Text
                label={t("fullName")}
                value={name}
                onChange={(e: any) => setName(e.target.value)}
              />
            </IonCol>
            <IonCol size="12" className="mt-10">
              <Text label={t("email")} value={user.email} readOnly={true} />
              <Hint text={t("emailHint")}></Hint>
            </IonCol>
            {errorMessage && (
              <IonCol size="12">
                <IonLabel color="danger">{errorMessage}</IonLabel>
              </IonCol>
            )}
            {successMessage && (
              <IonCol size="12">
                <IonLabel color="primary">{successMessage}</IonLabel>
              </IonCol>
            )}
            <IonCol className="mb-20" size="12">
              <Button
                text={t("saveChanges")}
                onClick={handleSubmit}
                type="submit"
                color="primary"
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default EditBasic;
