import { IonIcon, IonImg, IonItem, IonLabel, IonThumbnail } from "@ionic/react";
import Constants from "../../utils/constants";
import imgPencil from "../../images/pencil.svg";
import { useHistory } from "react-router-dom";
import moment from "moment";

const AchievementBox = ({ data }: any) => {
  const history = useHistory();
  return (
    <>
      <IonItem
        lines="none"
        className="mb-10"
        style={{
          marginLeft: 10,
          marginRight: 10,
          borderRadius: Constants.BORDER_RADIUS,
          padding: 5,
          backgroundColor: Constants.ORANGE_COLOR,
        }}
      >
        <IonThumbnail slot="start">
          <IonImg
            src={data.fileUrl}
            style={{
              borderRadius: 8,
              cursor: "pointer",
              objectFit: "cover",
              overflow: "hidden",
            }}
          />
        </IonThumbnail>
        <IonLabel
          className="fwfs-500-12"
          style={{ color: Constants.DARK_COLOR }}
        >
          {data.text}
          <br />
          <span className="fwfs-400-10">
            {moment(data.dateAchieved).format("MMM DD, YYYY")}
          </span>
        </IonLabel>
        <IonIcon
          icon={imgPencil}
          slot="end"
          style={{
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.preventDefault();
            history.push(`/achievements/${data._id}`);
          }}
        />
      </IonItem>
    </>
  );
};

export default AchievementBox;
