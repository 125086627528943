import { IonLabel, IonItem, IonImg, IonThumbnail } from "@ionic/react";
import Constants from "../../utils/constants";
import { useHistory } from "react-router-dom";

const MySupportedNpoRow = ({ obj }: any) => {
  const history = useHistory();
  return (
    <>
      <IonItem
        lines="none"
        className="mb-10"
        style={{
          borderRadius: Constants.BORDER_RADIUS,
          padding: 5,
          backgroundColor: Constants.LIGHT_COLOR,
        }}
      >
        <IonThumbnail slot="start">
          <IonImg
            src={obj.fileUrl}
            style={{
              borderRadius: 8,
              cursor: "pointer",
              objectFit: "cover",
              overflow: "hidden",
            }}
            onClick={(e) => history.push(`/npo/${obj._id}`)}
          />
        </IonThumbnail>
        <IonLabel
          className="ion-text-wrap npo-name"
          style={{ cursor: "pointer" }}
          onClick={(e) => history.push(`/npo/${obj._id}`)}
        >
          {obj.name}
        </IonLabel>
      </IonItem>
    </>
  );
};

export default MySupportedNpoRow;
