import i18n from "i18next";
import en from "./en.json";
import jp from "./jp.json";

i18n.init({
  resources: {
    en: {
      translations: en,
    },
    jp: {
      translations: jp,
    },
  },
  fallbackLng: "en",
  debug: false,
  ns: ["translations"],
  defaultNS: "translations",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ",",
  },
});

export default i18n;
