import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonBackButton,
  IonButtons,
  IonItem,
  IonItemDivider,
} from "@ionic/react";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import Constants from "../../utils/constants";
import { useState, useEffect } from "react";
import npoService from "../../services/npo";
import useStore from "../../store/store";
import { Hint } from "../../controls";
import NpoHeader from "../../components/npo/NpoHeader";
import SupportedNpoRow from "../../components/npo/SupportedNpoRow";
import HeaderNavbar from "../../components/HeaderNavbar";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const SupportedNpos: React.FC = () => {
  const { t } = useTranslation();
  const { user, token, setIsLoading } = useStore((state: any) => state);
  const size = useScreenSize();
  const [list, setList] = useState<any>([]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href });
  }, []);

  useEffect(() => {
    setIsLoading(true);

    npoService
      .getNposSupportedByUserId({
        userId: user._id,
        keyword: "",
      })
      .then((_r) => {
        const _npos: any = _r.data;
        setList(_npos);
      });

    setIsLoading(false);
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton className="btn-back"></IonBackButton>
          </IonButtons>
          <IonTitle>{t("editFavouriteNpos")}</IonTitle>
          {size.screen !== BreakPoint.xs && size.screen !== BreakPoint.s && (
            <HeaderNavbar />
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large" className="ios-page-heading">
              {t("editFavouriteNpos")}
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid
          style={{
            padding: 0,
            maxWidth:
              size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                ? "100%"
                : Constants.MAX_GRID_WIDTH,
          }}
        >
          <NpoHeader isForUser={true} />
        </IonGrid>
        <IonGrid
          style={{
            padding: 0,
            maxWidth:
              size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                ? "100%"
                : Constants.MAX_GRID_WIDTH,
          }}
        >
          <IonRow>
            <IonCol size="12" className="mt-10">
              <IonLabel className="fwfs-500-18">{t("details")}</IonLabel>
            </IonCol>
            <IonItemDivider style={{ minHeight: 10 }} />
            <IonCol size="12" className="fwfs-400-14">
              <IonLabel>{user?.about}</IonLabel>
            </IonCol>
            <IonCol size="12" className="mt-10">
              <IonLabel
                className="fwfs-500-18"
                style={{
                  padding:
                    size.screen === BreakPoint.xs ||
                    size.screen === BreakPoint.s
                      ? 10
                      : 0,
                }}
              >
                {t("nposISupport")}
              </IonLabel>
            </IonCol>
            <IonCol
              size="12"
              style={{
                padding:
                  size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                    ? 10
                    : 0,
              }}
              className="mb-20"
            >
              <IonList lines="none">
                {list.length === 0 && (
                  <IonItem lines="none">
                    <Hint text={t("notDonatedToAnyNpo")}></Hint>
                  </IonItem>
                )}
                {list.length > 0 &&
                  list.map((k: any, i: number) => (
                    <SupportedNpoRow data={k} key={i} />
                  ))}
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default SupportedNpos;
