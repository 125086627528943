import { useEffect } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonList,
  IonItem,
  IonBackButton,
  IonNote,
  IonGrid,
  IonRow,
  IonCol,
  IonButtons,
  IonToggle,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import Constants from "../../utils/constants";
import HeaderNavbar from "../../components/HeaderNavbar";
import useStore from "../../store/store";
import userService from "../../services/user";
import { registerNotifications, addListeners } from "../../utils/push";
import { Capacitor } from "@capacitor/core";
import ReactGA from "react-ga4";
import { requestForToken } from "../../utils/push-notification";
import { useTranslation } from "react-i18next";

const Preferences = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const size = useScreenSize();
  const { user, token, setUser } = useStore((state: any) => state);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href });
  }, []);

  const handleNotifications = async (e: any) => {
    const status: Boolean = e.target.checked;
    if (status) {
      if (Capacitor.getPlatform() !== "web") {
        registerNotifications();
        addListeners(history, (t: string) => {
          userService
            .updateNotificationStatus(token, {
              status: status,
              token: t,
            })
            .then((result) => {
              if (result.data) {
                setUser(result.data);
              }
            });
        });
      } else {
        const t = await requestForToken();
        userService
          .updateNotificationStatus(token, {
            status: status,
            token: t,
          })
          .then((result) => {
            if (result.data) {
              setUser(result.data);
            }
          });
      }
    } else {
      userService
        .updateNotificationStatus(token, { status: status, token: "" })
        .then((result) => {
          if (result.data) {
            setUser(result.data);
          }
        });
    }
  };
  const handleDonationsVisibility = async (e: any) => {
    userService
      .updateDonationsVisibility(token, e.target.checked)
      .then((result) => {
        if (result.data) {
          setUser(result.data);
        }
      });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton className="btn-back"></IonBackButton>
          </IonButtons>
          <IonTitle>{t("preferences")}</IonTitle>
          {size.screen !== BreakPoint.xs && size.screen !== BreakPoint.s && (
            <HeaderNavbar />
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large" className="ios-page-heading">
              {t("preferences")}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid
          style={{
            padding: 0,
            maxWidth:
              size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                ? "100%"
                : Constants.MAX_GRID_WIDTH,
          }}
        >
          <IonRow>
            <IonCol size="12">
              <IonList lines="none">
                <IonItem>
                  <IonLabel className="fwfs-500-14">
                    {t("notifications")}
                  </IonLabel>
                  <IonToggle
                    enableOnOffLabels={true}
                    checked={user.notifications}
                    style={{ margin: 0 }}
                    onIonChange={handleNotifications}
                  ></IonToggle>
                </IonItem>
                <IonItem>
                  <IonLabel className="fwfs-500-14">
                    {t("showDonations")}
                  </IonLabel>
                  <IonToggle
                    enableOnOffLabels={true}
                    checked={user.showDonationsOnProfile}
                    style={{ margin: 0 }}
                    onIonChange={handleDonationsVisibility}
                  ></IonToggle>
                  <IonNote slot="helper" className="ion-text-wrap">
                    {t("showDonationsHint")}
                  </IonNote>
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Preferences;
