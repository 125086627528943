import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonBackButton,
  IonImg,
  IonAvatar,
  IonButtons,
  IonNote,
  useIonAlert,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import useStore from "../../store/store";
import { Button, Number } from "../../controls";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import Constants from "../../utils/constants";
import imgDivider from "../../images/divider.svg";
import npoService from "../../services/npo";
import paymentService from "../../services/payment";
import moment from "moment";
import HeaderNavbar from "../../components/HeaderNavbar";
import { colorFillOutline } from "ionicons/icons";
import Utils from "../../utils/utils";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const DonatePoints: React.FC = (props: any) => {
  const { t } = useTranslation();
  const { isLoggedIn, user, token, setIsLoading } = useStore(
    (state: any) => state
  );
  const size = useScreenSize();
  const history = useHistory();
  const [presentAlert] = useIonAlert();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [npo, setNpo] = useState<any>();
  const [points, setPoints] = useState<number>(0);
  const [pointsToAmount, setPointsToAmount] = useState<string>("");
  const [remaining, setRemaining] = useState<number>(0);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href });
  }, []);

  useEffect(() => {
    let t = Utils.formatToNumber(
      (points * Constants.POINTS.EQUIVALENT_TO).toFixed(2)
    );
    setPointsToAmount(t);
    setRemaining(user.points - points);
  }, [points]);

  useEffect(() => {
    const projectId = props.match.params.projectId;
    if (!projectId) return;
    npoService.getNpoDetailsByProjectId(projectId).then((result) => {
      if (result.data) {
        setNpo(result.data);
      }
    });
  }, [props.match.params.projectId]);

  const handleDonatePoints = (e: any) => {
    e.preventDefault();
    if (!isLoggedIn || !token) return;
    if (user.points < Constants.POINTS.MIN_LIMIT) return;
    if (points > user.points) return;

    presentAlert({
      header: t("sure"),
      message: `${points} ${t("pointsWillBeDeducted")}`,
      buttons: [
        {
          text: t("cancel"),
          role: "cancel",
          handler: () => {},
        },
        {
          text: t("confirm"),
          role: "confirm",
          handler: () => {
            setIsLoading(true);
            paymentService
              .createFromPoints(token, {
                npoId: npo.userId._id,
                projectId: npo._id,
                amount: pointsToAmount,
                points: points,
                date: moment().format(),
                currency: Constants.CURRENCY,
                refUserId: "",
              })
              .then((result) => {
                if (result.error) {
                  setErrorMessage(result.error);
                  setIsLoading(false);
                  return;
                }

                if (result.data) {
                  setIsLoading(false);
                  history.push("/donation-success");
                }
              });
          },
        },
      ],
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton className="btn-back"></IonBackButton>
          </IonButtons>
          <IonTitle>{t("makeDonation")}</IonTitle>
          {size.screen !== BreakPoint.xs && size.screen !== BreakPoint.s && (
            <HeaderNavbar />
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large" className="ios-page-heading">
              {t("makeDonation")}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid
          style={{
            padding: 0,
            maxWidth:
              size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                ? "100%"
                : Constants.MAX_GRID_WIDTH,
          }}
        >
          <IonRow className="center">
            <IonCol size="12">
              <IonAvatar
                style={{
                  cursor: "pointer",
                  width: 160,
                  height: 160,
                  marginTop: 20,
                  marginLeft:
                    size.screen === BreakPoint.xs ||
                    size.screen === BreakPoint.s
                      ? size.width / 2 - 80
                      : Constants.MAX_GRID_WIDTH / 2 - 80,
                }}
              >
                <IonImg src={npo?.fileUrl} alt={npo?.name} />
              </IonAvatar>
            </IonCol>

            <IonCol size="12">
              <p className="fwfs-500-20">{npo?.title}</p>
              <IonLabel className="fwfs-500-14" color="primary">
                {npo?.userId?.name}
              </IonLabel>
            </IonCol>

            <IonCol size-xs="3" size-sm="3" size-md="4" size-lg="4">
              <IonImg src={imgDivider} style={{ width: "100%" }} />
            </IonCol>
            <IonCol size-xs="6" size-sm="6" size-md="4" size-lg="4">
              <IonLabel className="fwfs-500-18 ion-text-nowrap">
                {t("thanksPoints")}
              </IonLabel>
            </IonCol>
            <IonCol size-xs="3" size-sm="3" size-md="4" size-lg="4">
              <IonImg src={imgDivider} style={{ width: "100%" }} />
            </IonCol>

            <IonCol size="12" className="ion-text-start mb-20">
              <IonLabel>
                {t("donateViaThanksPoints")} {process.env.REACT_APP_NAME}{" "}
                {t("donateViaThanksPoints1")} {Constants.CURRENCY_SYMBOL}
                {Constants.POINTS.EQUIVALENT_TO} {t("donateViaThanksPoints2")}{" "}
                {t("donateViaThanksPoints3")} {Constants.POINTS.MIN_LIMIT}{" "}
                {t("donateViaThanksPoints4")}
              </IonLabel>
            </IonCol>

            <IonCol size="12">
              <Number
                label={`${t("yourPoints")} ${user.points} ${t("yourPoints2")}`}
                value={points}
                onChange={(e: any) => setPoints(e.target.value)}
                min={Constants.POINTS.MIN_LIMIT}
                max={user.points}
                step={5}
              />
              {points > 0 && (
                <IonNote className="fwfs-500-14">
                  {process.env.REACT_APP_NAME} {t("donateViaThanksPoints1")}{" "}
                  {Constants.CURRENCY_SYMBOL}
                  {pointsToAmount} {t("donateViaThanksPoints5")} {remaining}.
                </IonNote>
              )}
            </IonCol>
            <IonCol size="12" className="mb-20 mt-20">
              <Button
                disabled={
                  user.points < Constants.POINTS.MIN_LIMIT ||
                  user.points < points
                }
                type="submit"
                icon={colorFillOutline}
                text={t("donateWithPoints")}
                onClick={handleDonatePoints}
              />
            </IonCol>

            {errorMessage && (
              <IonCol size="12">
                <IonLabel color="danger">{errorMessage}</IonLabel>
              </IonCol>
            )}
            {successMessage && (
              <IonCol size="12">
                <IonLabel color="primary">{successMessage}</IonLabel>
              </IonCol>
            )}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default DonatePoints;
