import {
  IonCol,
  IonGrid,
  IonImg,
  IonItem,
  IonLabel,
  IonRow,
  IonThumbnail,
} from "@ionic/react";
import Constants from "../../utils/constants";
import moment from "moment";
import { useTranslation } from "react-i18next";

const AnalyticTransactionBox = ({ data }: any) => {
  const { t } = useTranslation();
  return (
    <IonItem
      lines="none"
      className="mb-10"
      style={{
        marginLeft: 15,
        marginRight: 15,
        borderRadius: Constants.BORDER_RADIUS,
        padding: 5,
        backgroundColor: Constants.ORANGE_COLOR,
      }}
    >
      {data.donarId?.fileUrl && (
        <IonThumbnail slot="start">
          <IonImg
            src={data.donarId?.fileUrl}
            style={{
              borderRadius: 8,
              cursor: "pointer",
              objectFit: "cover",
              overflow: "hidden",
            }}
          />
        </IonThumbnail>
      )}
      <IonGrid style={{ padding: 0 }}>
        <IonRow>
          <IonCol size="9">
            <IonLabel
              className="fwfs-500-12"
              style={{ color: Constants.DARK_COLOR }}
            >
              {data.donarId?.name || t("anonymous")}
            </IonLabel>
          </IonCol>
          <IonCol size="3" className="ion-text-end">
            <IonLabel
              className="fwfs-500-12"
              style={{ color: Constants.DARK_COLOR }}
            >
              {Constants.CURRENCY_SYMBOL}
              {data.amount}
            </IonLabel>
          </IonCol>
          <IonCol size="9">
            <IonLabel className="fwfs-400-12" color="primary">
              {data.projectId?.title}
            </IonLabel>
          </IonCol>
          <IonCol size="3" className="ion-text-end">
            <IonLabel
              className="fwfs-400-10"
              style={{ color: Constants.DARK_COLOR }}
            >
              {moment(data.date).format("DD MMM")}
            </IonLabel>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};

export default AnalyticTransactionBox;
