import { IonLabel, IonItem, IonImg } from "@ionic/react";
import img from "../images/qa-icon.svg";
import Constants from "../utils/constants";
import moment from "moment";
import { useTranslation } from "react-i18next";

const QaCard = ({ obj }: any) => {
  const { t } = useTranslation();
  return (
    <IonItem lines="none">
      <div
        style={{
          width: "100%",
          borderRadius: 16,
          backgroundColor: Constants.ORANGE_COLOR,
          padding: 24,
          marginTop: 50,
        }}
      >
        <IonImg src={img} className="qa-icon" />{" "}
        <IonLabel className="ion-text-wrap question">{obj.question}</IonLabel>
        <IonLabel className="ion-text-wrap answer">
          <ul>
            <li>{obj.answer}</li>
            <span className="fwfs-400-10">
              {t("lastUpdated")}: {moment(obj.date).format("MMM DD, YYYY")}
            </span>
          </ul>
        </IonLabel>
      </div>
    </IonItem>
  );
};

export default QaCard;
