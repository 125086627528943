const Constants = {
  TOKEN_NAME: `x-auth`,
  SMALL_FORM_WIDTH: 500,
  SMALL_FORM_PADDING: 20,
  BORDER_RADIUS: 16,
  MAX_GRID_WIDTH: 600,
  DARK_COLOR: "#33342F",
  LIGHT_COLOR: "#F5F5F5",
  ORANGE_COLOR: "#FDF4E2",
  PRIMARY: "#F3BE4A",
  CURRENCY_SYMBOL: "¥",
  CURRENCY: "JPY",
  BADGES: {
    LOW: {
      mine: 50,
      ref: 30,
    },
    MED: {
      mine: 100,
      ref: 50,
    },
    TOP: {
      mine: 200,
      ref: 100,
    },
  },
  POINTS: {
    ACCOUNT_ACTIVATION: 10,
    COMPLETING_PROFILE: 10,
    UPDATE_ABOUT: 10,
    MADE_DONATION: 10,
    REF_MADE_DONATION: 10,
    MIN_LIMIT: 50,
    EQUIVALENT_TO: 0.05,
    FEEDBACK: 5,
  },
};

export default Constants;
