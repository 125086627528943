import { Link } from "react-router-dom";

const LinkButton = ({
  to,
  label,
  text,
  labelStyle,
  textClassName,
  textStyle,
  onClick,
}: any) => {
  return (
    <div style={labelStyle}>
      {label}&nbsp;
      {to?.length > 0 && !onClick ? (
        <Link
          to={to}
          className={textClassName}
          style={{ ...textStyle, textDecoration: "none" }}
        >
          {text}
        </Link>
      ) : (
        <Link
          to={""}
          className={textClassName}
          style={{ ...textStyle, textDecoration: "none" }}
          onClick={onClick}
        >
          {text}
        </Link>
      )}
    </div>
  );
};

export default LinkButton;
