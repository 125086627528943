import { IonInput, IonItem, IonLabel, IonIcon } from "@ionic/react";

const Password = ({ label, value, icon, onChange, ...rest }: any) => {
  return (
    <IonItem fill="outline">
      {icon && <IonIcon slot="start" icon={icon}></IonIcon>}
      <IonLabel position="floating">{label}</IonLabel>
      <IonInput
        placeholder={label}
        type="password"
        inputmode="text"
        value={value}
        onIonInput={onChange}
        {...rest}
      ></IonInput>
    </IonItem>
  );
};

export default Password;
