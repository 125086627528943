import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonToolbar,
} from "@ionic/react";
import { useState } from "react";
import imgEn from "../images/us.svg";
import imgJp from "../images/jp.svg";
import { checkmark, closeCircleOutline } from "ionicons/icons";
import useStore from "../store/store";
import { useTranslation } from "react-i18next";

const LanguagePopup = ({ isOpen, onClose, onChange }: any) => {
  const { t } = useTranslation();
  const { language } = useStore((state: any) => state);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(language);

  const handleChange = (value: string) => {
    setSelectedLanguage(value);
    onChange(value);
  };

  return (
    <IonModal
      isOpen={isOpen}
      initialBreakpoint={0.3}
      breakpoints={[0, 0.3]}
      animated={true}
      showBackdrop={true}
      onDidDismiss={onClose}
      mode="ios"
    >
      <IonHeader mode="ios">
        <IonToolbar className="ion-text-center">
          <IonButtons slot="start">
            <div style={{ marginLeft: 20, width: 20, height: 20 }}></div>
          </IonButtons>
          <IonLabel className="fwfs-500-16">{t("language")}</IonLabel>
          <IonButtons slot="end">
            <IonIcon
              icon={closeCircleOutline}
              onClick={onClose}
              style={{ marginRight: 20, width: 20, height: 20 }}
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY={false} scrollX={false}>
        <IonList lines="full">
          <IonItem button detail={false} onClick={() => handleChange("jp")}>
            <div
              slot="start"
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 20,
                alignItems: "center",
              }}
            >
              <IonIcon icon={imgJp} size="large"></IonIcon>
              <IonLabel className="fwfs-400-16">日本語</IonLabel>
            </div>
            {selectedLanguage === "jp" && (
              <IonIcon slot="end" icon={checkmark}></IonIcon>
            )}
          </IonItem>
          <IonItem button detail={false} onClick={() => handleChange("en")}>
            <div
              slot="start"
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 20,
                alignItems: "center",
              }}
            >
              <IonIcon icon={imgEn} size="large"></IonIcon>
              <IonLabel className="fwfs-400-16">English</IonLabel>
            </div>
            {selectedLanguage === "en" && (
              <IonIcon slot="end" icon={checkmark}></IonIcon>
            )}
          </IonItem>
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default LanguagePopup;
