import { useState } from "react";
import {
  IonAvatar,
  IonCol,
  IonIcon,
  IonImg,
  IonLabel,
  IonRow,
  useIonAlert,
} from "@ionic/react";
import { Button } from "../../controls";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import Styles from "../../css/styles";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";
import useStore from "../../store/store";
import npoService from "../../services/npo";
import { v4 as uuid } from "uuid";
import Constants from "../../utils/constants";
import { Clipboard } from "@capacitor/clipboard";
import { notificationsOutline, shareSocialOutline } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NpoHeader = ({ isForUser = false, showShareButton = true }: any) => {
  const { t } = useTranslation();
  const size = useScreenSize();
  const history = useHistory();
  const [presentAlert] = useIonAlert();
  const { user, token, setIsLoading, setUser } = useStore(
    (state: any) => state
  );
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  const [profilePictureUrl, setProfilePictureUrl] = useState<any>(user.fileUrl);

  const handleProfilePictureCamera = async () => {
    const image = await Camera.getPhoto({
      quality: 100,
      allowEditing: false,
      resultType: CameraResultType.Uri,
      source: CameraSource.Prompt,
      saveToGallery: true,
      promptLabelHeader: t("choose"),
      promptLabelCancel: t("close"),
      presentationStyle: "popover",
    });

    setProfilePictureUrl(image.webPath);
    setIsLoading(true);
    try {
      const format = image.format;
      const fileName = `${process.env.REACT_APP_NAME}/${uuid()}.${format}`;
      const base64Data = await base64FromPath(image.webPath!);
      await Filesystem.writeFile({
        path: fileName,
        data: base64Data,
        directory: Directory.Documents,
        encoding: Encoding.UTF8,
      });
      const contents = await Filesystem.readFile({
        path: fileName,
        directory: Directory.Documents,
        encoding: Encoding.UTF8,
      });

      const res = await fetch(contents.data);
      const blob = await res.blob();
      const name = `${uuid()}.${format}`;
      const file = new File([blob], name, {
        type: `image/${format}`,
      });
      const result = await npoService.updatePicture(token, file);
      if (result.error) {
        setErrorMessage(result.error);
        setIsLoading(false);
        return;
      }

      setErrorMessage("");
      setSuccessMessage(t("profilePictureUpdatedSuccess"));
      setUser(result.data);
      setIsLoading(false);
    } catch (err: any) {
      setErrorMessage(err.message);
      setIsLoading(false);
      return;
    }
  };

  const base64FromPath = async (path: string): Promise<string> => {
    const response = await fetch(path);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        if (typeof reader.result === "string") {
          resolve(reader.result);
        } else {
          reject("method did not return a string");
        }
      };
      reader.readAsDataURL(blob);
    });
  };

  const handleGenerateLink = async (e: any) => {
    e.preventDefault();
    await Clipboard.write({
      string: isForUser
        ? `${process.env.REACT_APP_URL}/ref/${user._id}`
        : `${process.env.REACT_APP_URL}/npo/${user._id}`,
    });
    presentAlert({
      header: "Copied!",
      subHeader: "",
      message: isForUser ? t("profileRefCopied") : t("copiedHint"),
      buttons: [t("ok")],
    });
  };

  return (
    <>
      <IonRow style={Styles.orangeHeader} className="center">
        <IonCol size="9">
          <IonAvatar
            onClick={handleProfilePictureCamera}
            style={{
              cursor: "pointer",
              width: 100,
              height: 100,
              marginTop: 20,
              marginLeft:
                size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                  ? size.width / 2 - 50
                  : Constants.MAX_GRID_WIDTH / 2 - 50,
            }}
          >
            <IonImg src={profilePictureUrl} alt={user.name} />
          </IonAvatar>
        </IonCol>
        <IonCol size="3" className="ion-text-center">
          <IonIcon
            icon={notificationsOutline}
            color="primary"
            style={{
              height: 25,
              width: 25,
              cursor: "pointer",
            }}
            onClick={(e: any) => history.push("/notifications")}
          />
        </IonCol>
        {errorMessage && (
          <IonCol size="12">
            <IonLabel color="danger">{errorMessage}</IonLabel>
          </IonCol>
        )}
        {successMessage && (
          <IonCol size="12">
            <IonLabel color="primary">{successMessage}</IonLabel>
          </IonCol>
        )}

        <IonRow
          style={{
            marginBottom: 20,
            marginTop: 10,
          }}
        >
          <IonCol size="12">
            {showShareButton === true && (
              <Button
                text={isForUser ? t("generateLink") : t("shareLink")}
                type="button"
                color="primary"
                icon={shareSocialOutline}
                onClick={handleGenerateLink}
              />
            )}
          </IonCol>
        </IonRow>
      </IonRow>
    </>
  );
};

export default NpoHeader;
