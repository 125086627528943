import Constants from "../utils/constants";

const Styles = {
	orangeHeader: {
		background: Constants.ORANGE_COLOR,
		minHeight: 100,
		height: "auto",
		borderBottomRightRadius: Constants.BORDER_RADIUS,
		borderBottomLeftRadius: Constants.BORDER_RADIUS
	}
};

export default Styles;
