import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonBackButton,
  IonImg,
  IonAvatar,
  IonButtons,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import useStore from "../../store/store";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import Constants from "../../utils/constants";
import imgDivider from "../../images/divider.svg";
import imgSubscription from "../../images/subscription.svg";
import PayPalSubscriptionWrapper from "../../components/PayPalSubscriptionWrapper";
import npoService from "../../services/npo";
import subscriptionService from "../../services/subscription";
import moment from "moment";
import HeaderNavbar from "../../components/HeaderNavbar";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const DonateSubscription: React.FC = (props: any) => {
  const { t } = useTranslation();
  const { isLoggedIn, token, setIsLoading, setRefUserId, refUserId } = useStore(
    (state: any) => state
  );
  const size = useScreenSize();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [npo, setNpo] = useState<any>();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href });
  }, []);

  useEffect(() => {
    const projectId = props.match.params.projectId;
    if (!projectId) return;
    npoService.getNpoDetailsByProjectId(projectId).then((result) => {
      if (result.data) {
        setNpo(result.data);
      }
    });
  }, [props.match.params.projectId]);

  const handleSaveSubscription = (details: any) => {
    if (isLoggedIn && token) {
      setIsLoading(true);
      subscriptionService
        .create(token, {
          details: details,
          npoId: npo.userId._id,
          projectId: npo._id,
          amount: 1000,
          date: moment().format(),
          currency: Constants.CURRENCY,
          refUserId: refUserId,
          subscriptionId: details.subscriptionID,
        })
        .then((result) => {
          if (result.error) {
            setErrorMessage(result.error);
            setIsLoading(false);
            return;
          }
          if (result.data) {
            setRefUserId("");
            setIsLoading(false);
            history.push("/donation-success");
          }
        });
    } else {
      subscriptionService
        .createAnonymous({
          details: details,
          npoId: npo.userId._id,
          projectId: npo._id,
          amount: 1000,
          date: moment().format(),
          currency: Constants.CURRENCY,
          refUserId: refUserId,
          subscriptionId: details.subscriptionID,
        })
        .then((result) => {
          if (result.error) {
            setErrorMessage(result.error);
            setIsLoading(false);
            return;
          }
          if (result.data) {
            setRefUserId("");
            setIsLoading(false);
            history.push("/donation-success");
          }
        });
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton className="btn-back"></IonBackButton>
          </IonButtons>
          <IonTitle>{t("createSubscription")}</IonTitle>
          {size.screen !== BreakPoint.xs && size.screen !== BreakPoint.s && (
            <HeaderNavbar />
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large" className="ios-page-heading">
              {t("createSubscription")}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid
          style={{
            padding: 0,
            maxWidth:
              size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                ? "100%"
                : Constants.MAX_GRID_WIDTH,
          }}
        >
          <IonRow className="center">
            <IonCol size="12">
              <IonAvatar
                style={{
                  cursor: "pointer",
                  width: 160,
                  height: 160,
                  marginTop: 20,
                  marginLeft:
                    size.screen === BreakPoint.xs ||
                    size.screen === BreakPoint.s
                      ? size.width / 2 - 80
                      : Constants.MAX_GRID_WIDTH / 2 - 80,
                }}
              >
                <IonImg src={npo?.fileUrl} alt={npo?.name} />
              </IonAvatar>
            </IonCol>

            <IonCol size="12">
              <p className="fwfs-500-20">{npo?.title}</p>
              <IonLabel className="fwfs-500-14" color="primary">
                {npo?.userId?.name}
              </IonLabel>
            </IonCol>

            <IonCol size-xs="3" size-sm="3" size-md="4" size-lg="4">
              <IonImg src={imgDivider} style={{ width: "100%" }} />
            </IonCol>
            <IonCol size-xs="6" size-sm="6" size-md="4" size-lg="4">
              <IonLabel className="fwfs-500-18 ion-text-nowrap">
                {t("createSubscription")}
              </IonLabel>
            </IonCol>
            <IonCol size-xs="3" size-sm="3" size-md="4" size-lg="4">
              <IonImg src={imgDivider} style={{ width: "100%" }} />
            </IonCol>

            <IonCol size="12">
              <IonImg src={imgSubscription} />
            </IonCol>
            <IonCol className="mt-10 mb-20" size="12">
              <PayPalSubscriptionWrapper onSuccess={handleSaveSubscription} />
            </IonCol>

            {errorMessage && (
              <IonCol size="12">
                <IonLabel color="danger">{errorMessage}</IonLabel>
              </IonCol>
            )}
            {successMessage && (
              <IonCol size="12">
                <IonLabel color="primary">{successMessage}</IonLabel>
              </IonCol>
            )}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default DonateSubscription;
