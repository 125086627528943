import { imageOutline } from "ionicons/icons";
import Button from "./Button";

const File = ({ btnText, hint, onClick, ...rest }: any) => {
  return (
    <Button text={btnText} icon={imageOutline} onClick={onClick} {...rest} />
  );
};

export default File;
