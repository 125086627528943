import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonBackButton,
  IonButtons,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import QaCard from "../components/QaCard";
import qaService from "../services/qa";
import useScreenSize, { BreakPoint } from "../hooks/useScreenSize";
import Constants from "../utils/constants";
import HeaderNavbar from "../components/HeaderNavbar";
import useStore from "../store/store";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const Qa: React.FC = () => {
  const { t } = useTranslation();
  const { setIsLoading } = useStore((state: any) => state);
  const size = useScreenSize();
  const [list, setList] = useState<any>([]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    qaService
      .getAll({ keyword: "" })
      .then((result) => {
        if (result.data) {
          setList(result.data);
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton className="btn-back"></IonBackButton>
          </IonButtons>
          <IonTitle>
            {process.env.REACT_APP_NAME} {t("qa")}
          </IonTitle>
          {size.screen !== BreakPoint.xs && size.screen !== BreakPoint.s && (
            <HeaderNavbar />
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large" className="ios-page-heading">
              {process.env.REACT_APP_NAME} {t("qa")}
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid
          style={{
            padding: 0,
            maxWidth:
              size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                ? "100%"
                : Constants.MAX_GRID_WIDTH,
          }}
        >
          <IonRow>
            <IonCol size="12" style={{ padding: 0 }}>
              <IonList lines="none">
                {list.map((k: any, i: number) => (
                  <QaCard obj={k} key={i} />
                ))}
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Qa;
