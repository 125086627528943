import { IonImg, IonLabel } from "@ionic/react";
import imgEn from "../images/us.svg";
import imgJp from "../images/jp.svg";
import useStore from "../store/store";
import { useTranslation } from "react-i18next";
import { Button, LinkButton } from "../controls";
import useScreenSize, { BreakPoint } from "../hooks/useScreenSize";

const SwitchLanguageAuthScreens = () => {
  const size = useScreenSize();
  const { t, i18n } = useTranslation();
  const { setLanguage } = useStore((state: any) => state);

  const handleChange = (lang: string) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <IonLabel className="fwfs-500-12 truncate-1">
        {t("switchLanguageHint2")}
      </IonLabel>
      {size.screen === BreakPoint.xs || size.screen === BreakPoint.s ? (
        <></>
      ) : (
        <IonImg src={imgEn} style={{ width: 20, marginLeft: 15 }} />
      )}
      <LinkButton
        to={undefined}
        onClick={() => handleChange("en")}
        text="English"
        textStyle={{
          fontWeight: 600,
          fontSize: 14,
        }}
      />

      {size.screen === BreakPoint.xs || size.screen === BreakPoint.s ? (
        <></>
      ) : (
        <IonImg src={imgJp} style={{ width: 20, marginLeft: 15 }} />
      )}
      <LinkButton
        to={undefined}
        onClick={() => handleChange("jp")}
        text="日本語"
        textStyle={{
          fontWeight: 600,
          fontSize: 14,
        }}
      />
    </div>
  );
};

export default SwitchLanguageAuthScreens;
