import { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonList,
  IonItem,
  IonBackButton,
  IonNote,
  useIonAlert,
  IonGrid,
  IonRow,
  IonCol,
  IonButtons,
  IonBadge,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/react";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import Constants from "../../utils/constants";
import paymentService from "../../services/payment";
import useStore from "../../store/store";
import HeaderNavbar from "../../components/HeaderNavbar";
import moment from "moment";
import Utils from "../../utils/utils";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const SummaryBox = ({ title, amount, onClick }: any) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        borderRadius: Constants.BORDER_RADIUS,
        padding: 10,
        margin: 1,
        backgroundColor: Constants.LIGHT_COLOR,
        cursor: "pointer",
      }}
    >
      <IonRow onClick={onClick}>
        <IonCol size="12">
          <IonLabel
            className="fwfs-500-16"
            style={{ color: Constants.DARK_COLOR }}
          >
            {title} <br />
            <IonNote className="fwfs-400-10">
              {title === "Available" && amount > 0
                ? t("clickToWithdraw")
                : t("toDate")}
            </IonNote>
          </IonLabel>
        </IonCol>
        <IonCol size="12" className="ion-text-end">
          <IonLabel
            className="fwfs-600-22"
            style={{ color: Constants.DARK_COLOR }}
          >
            {Constants.CURRENCY_SYMBOL}
            {Utils.formatToNumber(amount)}
          </IonLabel>
        </IonCol>
      </IonRow>
    </div>
  );
};

const Transactions = () => {
  const { t } = useTranslation();
  const { user, token, setIsLoading } = useStore((state: any) => state);
  const [presentAlert] = useIonAlert();
  const size = useScreenSize();
  const [list, setList] = useState<any[]>([]);
  const [page, setPage] = useState<number>(0);
  const [available, setAvailable] = useState<number>(0);
  const [withdrawn, setWithdrawn] = useState<number>(0);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href });
  }, []);

  useEffect(() => {
    reload();
  }, [page]);

  const reload = async () => {
    let result = await paymentService.getByNpoId(token, { page: page });
    if (result.data) {
      let _d: any = result.data;
      setList([...list, ..._d?.list]);
      setAvailable(_d.available || 0);
      setWithdrawn(_d.withdrawn || 0);
    }
  };

  const handleScroll = (e: any) => {
    setTimeout(() => {
      setPage(page + 1);
      e.target.complete();
    }, 500);
  };

  const handleWithdraw = async (e: any) => {
    e.preventDefault();
    if (available <= 0) return;
    if (!user.paypal || !Utils.isValidEmail(user.paypal)) {
      presentAlert({
        header: t("setupYourReceivingAccount"),
        message: t("receivingAccountNotSetup"),
        buttons: [
          {
            text: t("ok"),
            role: "cancel",
            handler: () => {},
          },
        ],
      });
      return;
    }

    presentAlert({
      message: `${t("fundsWillbeTransferred")} (${user.paypal})`,
      buttons: [
        {
          text: t("cancel"),
          role: "cancel",
          handler: () => {},
        },
        {
          text: t("confirm"),
          role: "confirm",
          handler: () => {
            setIsLoading(true);
            paymentService.withdrawAvailable(token).then((result) => {
              if (result.error) {
                presentAlert({
                  header: t("error"),
                  message: result.error,
                  buttons: [
                    {
                      text: t("ok"),
                      role: "cancel",
                      handler: () => {},
                    },
                  ],
                });
                setIsLoading(false);
                return;
              }

              presentAlert({
                header: t("congratulations"),
                message: `${process.env.REACT_APP_NAME} ${t("sentYou")} ${
                  Constants.CURRENCY_SYMBOL
                }${available} ${t("at")} ${user.paypal}.`,
                buttons: [
                  {
                    text: t("awesome"),
                    role: "cancel",
                    handler: () => {
                      window.location.reload();
                    },
                  },
                ],
              });
              setIsLoading(false);
            });
          },
        },
      ],
    });
  };

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton className="btn-back"></IonBackButton>
            </IonButtons>
            <IonTitle>{t("transactions")}</IonTitle>
            {size.screen !== BreakPoint.xs && size.screen !== BreakPoint.s && (
              <HeaderNavbar />
            )}
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large" className="ios-page-heading">
                {t("transactions")}
              </IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonGrid
            style={{
              padding: 0,
              maxWidth:
                size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                  ? "100%"
                  : Constants.MAX_GRID_WIDTH,
            }}
          >
            <IonRow style={{ paddingLeft: 10, paddingRight: 10 }}>
              <IonCol size="6">
                <SummaryBox
                  title={t("withdrawn")}
                  amount={withdrawn}
                  onClick={null}
                />
              </IonCol>
              <IonCol size="6">
                <SummaryBox
                  title={t("available")}
                  amount={available}
                  onClick={handleWithdraw}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12">
                <IonList>
                  {list.length === 0 && (
                    <IonItem>
                      <IonLabel className="fwfs-400-14">
                        {t("noDonations")}
                      </IonLabel>
                    </IonItem>
                  )}
                  {list.map((item: any, idx: number) => (
                    <IonItem key={item._id}>
                      <IonLabel>
                        <h2>{item?.projectId?.title}</h2>
                        <IonNote
                          slot="helper"
                          className="ion-text-nowrap fwfs-500-14"
                        >
                          {moment(item.date).format("DD MMM YYYY")}
                        </IonNote>
                      </IonLabel>
                      {item.status === "available" && (
                        <IonBadge
                          color="primary"
                          className="ion-text-capitalize"
                        >
                          {t(item.status)}
                        </IonBadge>
                      )}
                      {item.status === "withdrawn" && (
                        <IonBadge
                          color="medium"
                          className="ion-text-capitalize"
                        >
                          {t(item.status)}
                        </IonBadge>
                      )}
                      <IonLabel slot="end" color="primary">
                        {Constants.CURRENCY_SYMBOL}
                        {Utils.formatToNumber(item?.amount)}
                      </IonLabel>
                    </IonItem>
                  ))}
                </IonList>
                <IonInfiniteScroll onIonInfinite={handleScroll} threshold="20%">
                  <IonInfiniteScrollContent
                    loadingText={t("loadingWait")}
                    loadingSpinner="bubbles"
                  ></IonInfiniteScrollContent>
                </IonInfiniteScroll>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Transactions;
