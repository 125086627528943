import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonBackButton,
  IonList,
  IonItem,
  IonThumbnail,
  IonImg,
  IonLabel,
  IonButtons,
} from "@ionic/react";
import useStore from "../store/store";
import useScreenSize, { BreakPoint } from "../hooks/useScreenSize";
import Constants from "../utils/constants";
import { Hint } from "../controls";
import HeaderNavbar from "../components/HeaderNavbar";
import moment from "moment";
import notificationService from "../services/notification";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const NotificationCard = ({ obj }: any) => {
  return (
    <IonItem>
      {obj.data?.fileUrl && (
        <IonThumbnail slot="start">
          <IonImg
            src={obj.data?.fileUrl}
            style={{
              height: 50,
              width: 50,
              borderRadius: 8,
              cursor: "pointer",
              objectFit: obj.data?.fileUrl?.includes("favicon.png")
                ? "contain"
                : "cover",
              overflow: "hidden",
            }}
          />
        </IonThumbnail>
      )}
      <IonLabel className="fwfs-500-14 ion-text-wrap">
        <h6>{obj.body}</h6>
        {obj.date && <Hint text={moment(obj.date).fromNow()} />}
      </IonLabel>
    </IonItem>
  );
};

const Notifications: React.FC = () => {
  const { t } = useTranslation();
  const { token, setIsLoading } = useStore((state: any) => state);
  const size = useScreenSize();
  const [list, setList] = useState<any>([]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href });
  }, []);

  useEffect(() => {
    reload();
  }, []);

  const reload = async () => {
    notificationService
      .getAll(token)
      .then((result) => {
        if (result.data) {
          setList(result.data);
        }
      })
      .catch(() => {});
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton className="btn-back"></IonBackButton>
          </IonButtons>
          <IonTitle>{t("notifications")}</IonTitle>
          {size.screen !== BreakPoint.xs && size.screen !== BreakPoint.s && (
            <HeaderNavbar />
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large" className="ios-page-heading">
              {t("notifications")}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid
          style={{
            padding: 0,
            maxWidth:
              size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                ? "100%"
                : Constants.MAX_GRID_WIDTH,
          }}
        >
          <IonRow>
            <IonCol size="12" style={{ padding: 0 }} className="mb-20">
              <IonList>
                {list.length === 0 && (
                  <IonItem>
                    <IonLabel className="fwfs-500-14 ion-text-wrap">
                      {t("noNotifications")}
                    </IonLabel>
                  </IonItem>
                )}
                {list.map((k: any, i: number) => (
                  <NotificationCard obj={k} key={i} />
                ))}
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Notifications;
