import { PushNotifications } from "@capacitor/push-notifications";

const addListeners = async (history: any, cb: any) => {
	await PushNotifications.addListener("registration", token => {
		// console.info("Registration token: ", token.value);
		cb(token.value);
	});

	await PushNotifications.addListener("registrationError", err => {
		console.error("Registration error: ", err.error);
	});

	await PushNotifications.addListener(
		"pushNotificationReceived",
		notification => {
			// console.log("Push notification received: ", notification);
		}
	);

	await PushNotifications.addListener(
		"pushNotificationActionPerformed",
		data => history.push("/notifications")
	);
};

const registerNotifications = async () => {
	let permStatus = await PushNotifications.checkPermissions();

	if (permStatus.receive === "prompt") {
		permStatus = await PushNotifications.requestPermissions();
	}

	if (permStatus.receive !== "granted") {
		throw new Error("User denied permissions!");
	}

	await PushNotifications.register();
};

const getDeliveredNotifications = async () => {
	const notificationList =
		await PushNotifications.getDeliveredNotifications();
	// console.log("delivered notifications", notificationList);
	return notificationList;
};

export { registerNotifications, addListeners, getDeliveredNotifications };
