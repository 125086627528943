import {
  IonLabel,
  IonItem,
  IonImg,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import Constants from "../../utils/constants";
import { heart } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FavouriteNpoCard = ({ data, setMaxWidth }: any) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <>
      <div
        style={{
          maxWidth: setMaxWidth ? 170 : "100%",
          minWidth: setMaxWidth ? "" : "100%",
          height: 150,
          borderRadius: Constants.BORDER_RADIUS,
          padding: 10,
          backgroundColor: Constants.LIGHT_COLOR,
          float: "left",
          margin: setMaxWidth ? 3 : 0,
          cursor: "pointer",
        }}
      >
        <IonGrid style={{ padding: 0, margin: 0 }}>
          <IonRow>
            <IonCol size="8">
              <IonImg
                src={data.npoId.fileUrl}
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 8,
                  objectFit: "cover",
                  overflow: "hidden",
                }}
                onClick={(e) => history.push(`/npo/${data.npoId._id}`)}
              />
            </IonCol>
            <IonCol size="4" className="ion-text-end">
              <IonIcon
                icon={heart}
                color="primary"
                style={{ height: 15, width: 15 }}
              />
            </IonCol>
            <IonCol size="12">
              <IonItem
                lines="none"
                className="zero-padding-item"
                style={{
                  minHeight: 30,
                  height: 35,
                }}
                onClick={(e) => history.push(`/npo/${data.npoId._id}`)}
              >
                <IonLabel
                  className="fwfs-500-16"
                  style={{ color: Constants.DARK_COLOR }}
                >
                  {data.npoId.name}
                </IonLabel>
              </IonItem>
            </IonCol>
            <IonCol size="12" className="ion-text-start">
              <IonLabel color="primary" className="fwfs-500-14">
                {data?.supporters || 0}
              </IonLabel>{" "}
              <IonLabel
                style={{ color: Constants.DARK_COLOR }}
                className="fwfs-400-12"
              >
                {t("supporters")}
              </IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </>
  );
};

export default FavouriteNpoCard;
