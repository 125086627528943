import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { Capacitor } from "@capacitor/core";

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FB_API_KEY,
	authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FB_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FB_APP_ID,
	measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID
};

let messaging;
if (Capacitor.getPlatform() === "web") {
	initializeApp(firebaseConfig);
	messaging = getMessaging();
}

export const requestForToken = () => {
	if (Capacitor.getPlatform() === "web") {
		return getToken(messaging, {
			vapidKey: process.env.REACT_APP_VAPID_KEY
		})
			.then(currentToken => {
				if (currentToken) {
					return currentToken;
				} else {
					console.log(
						"No registration token available. Request permission to generate one."
					);
					return "";
				}
			})
			.catch(err => {
				console.log("An error occurred while retrieving token. ", err);
			});
	}
};

export const onMessageListener = () => {
	return new Promise((resolve, reject) => {
		if (Capacitor.getPlatform() === "web") {
			onMessage(messaging, payload => {
				resolve(payload);
			});
		} else {
			reject();
		}
	});
};
