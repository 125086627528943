import { IonLabel } from "@ionic/react";

const PageHeading = ({ text }: any) => {
  return (
    <>
      <IonLabel color="dark" className="page-heading">
        {text}
      </IonLabel>
    </>
  );
};

export default PageHeading;
