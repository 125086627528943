const APP_NAME = `@${process.env.REACT_APP_NAME?.replaceAll(
	" ",
	"_"
).toUpperCase()}_`;

const Keys = {
	isLoggedIn: `${APP_NAME}isLoggedIn`,
	isLoading: `${APP_NAME}isLoading`,
	isFirstTimeLaunch: `${APP_NAME}isFirstTimeLaunch`,
	token: `${APP_NAME}token`,
	user: `${APP_NAME}user`,
	refUserId: `${APP_NAME}refUserId`,
	selectedCategory: `${APP_NAME}selectedCategory`,
	notification: `${APP_NAME}notification`,
	language: `${APP_NAME}language`
};

export default Keys;
