import validator from "validator";
import axios from "axios";

class Utils {
	static capitalizeFirstLetter = (str: string): string =>
		str.charAt(0).toUpperCase() + str.slice(1);

	static isValidEmail = (email: string): boolean => validator.isEmail(email);
	static getIp = async () => {
		const ipResponse = await axios.get(
			`https://api.ipify.org/?format=json`
		);
		const ip = (ipResponse && ipResponse.data && ipResponse.data.ip) || "";
		return ip;
	};

	static groupArrayOfObjects = (list: any[], key: string) => {
		return list.reduce(function (rv, x) {
			(rv[x[key]] = rv[x[key]] || []).push(x);
			return rv;
		}, {});
	};

	static formatToNumber = (amount: string) => {
		return parseFloat(amount)
			.toFixed(2)
			.replace(/\d(?=(\d{3})+\.)/g, "$&,")
			.replace(".00", "");
	};

	static getRandomColor = () => {
		const r = Math.round(Math.random() * 255);
		const g = Math.round(Math.random() * 255);
		const b = Math.round(Math.random() * 255);
		return {
			background: `rgba(${r},${g},${b},.7)`,
			border: `rgba(${r},${g},${b},1)`
		};
	};
}

export default Utils;
