import { useEffect, useState } from "react";
import { IonButtons, IonButton, IonIcon, IonImg } from "@ionic/react";
import { useHistory } from "react-router-dom";
import {
  gridOutline,
  homeOutline,
  personOutline,
  settingsOutline,
} from "ionicons/icons";
import useStore from "../store/store";
import { Button } from "../controls";
import "../css/HeaderNavbar.css";
import useScreenSize from "../hooks/useScreenSize";
import imgLogo from "../images/Logo.svg";
import { useTranslation } from "react-i18next";

const HeaderNavbar = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user, token, isLoggedIn } = useStore((state: any) => state);
  const size = useScreenSize();

  const [isHomeActive, setIsHomeActive] = useState<boolean>(false);
  const [isQaActive, setIsQaActive] = useState<boolean>(false);
  const [isNposActive, setIsNposActive] = useState<boolean>(false);
  const [isProfileActive, setIsProfileActive] = useState<boolean>(false);
  const [isSettingsActive, setIsSettingsActive] = useState<boolean>(false);

  const setAllFalse = () => {
    setIsHomeActive(false);
    setIsQaActive(false);
    setIsNposActive(false);
    setIsProfileActive(false);
    setIsSettingsActive(false);
  };

  useEffect(() => {
    const url = window.location.href
      .split(process.env.REACT_APP_URL || "")
      .pop();

    setAllFalse();
    if (
      url?.includes("npo") ||
      url?.includes("donate") ||
      url?.includes("donar") ||
      url?.includes("donation")
    ) {
      setIsNposActive(true);
    }

    if (url?.includes("qa")) {
      setIsQaActive(true);
    }

    if (url === "/" || url?.includes("home")) {
      setIsHomeActive(true);
    }

    if (
      url?.includes("profile") ||
      url?.includes("edit") ||
      url?.includes("notifications") ||
      url?.includes("achievement")
    ) {
      setIsProfileActive(true);
    }

    if (url?.includes("settings") || url?.includes("update-password")) {
      setIsSettingsActive(true);
    }
  }, []);

  return (
    <>
      <IonImg
        src={imgLogo}
        slot="end"
        style={{
          position: "fixed",
          width: 100,
          marginLeft: size.width / 2 - 50,
        }}
      />
      {isLoggedIn && token ? (
        <IonButtons slot="end" style={{ marginRight: 50 }}>
          <IonButton
            fill="clear"
            color={isHomeActive ? "primary" : ""}
            onClick={(e: any) => history.push("/")}
          >
            <IonIcon icon={homeOutline} slot="icon-only" />
          </IonButton>
          {user.type === "user" && (
            <IonButton
              fill="clear"
              color={isNposActive ? "primary" : ""}
              onClick={(e: any) => history.push("/npos")}
            >
              <IonIcon icon={gridOutline} slot="icon-only" />
            </IonButton>
          )}
          <IonButton
            fill="clear"
            color={isProfileActive ? "primary" : ""}
            onClick={(e: any) => history.push("/profile")}
          >
            <IonIcon icon={personOutline} slot="icon-only" />
          </IonButton>
          <IonButton
            fill="clear"
            color={isSettingsActive ? "primary" : ""}
            onClick={(e: any) => history.push("/settings")}
          >
            <IonIcon icon={settingsOutline} slot="icon-only" />
          </IonButton>
        </IonButtons>
      ) : (
        <IonButtons slot="end" style={{ marginRight: 50 }}>
          <Button
            text={t("exploreNpos")}
            type="button"
            color="primary"
            className="btn-rounded"
            onClick={(e: any) => history.push("/npos")}
          ></Button>
          <Button
            text={t("login")}
            type="button"
            color="primary"
            className="btn-rounded"
            onClick={(e: any) => history.push("/login")}
          ></Button>
          <Button
            text={t("register")}
            type="button"
            color="dark"
            className="btn-rounded"
            onClick={(e: any) => history.push("/signup")}
          ></Button>
        </IonButtons>
      )}
    </>
  );
};

export default HeaderNavbar;
