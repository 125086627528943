import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonPage,
  IonLabel,
  IonCol,
  IonGrid,
  IonRow,
} from "@ionic/react";
import Constants from "../../utils/constants";
import useStore from "../../store/store";
import { Button, Password, LinkButton, Hint } from "../../controls";
import PageHeading from "../../components/PageHeading";
import AuthScreensBackground from "../../components/AuthScreensBackground";
import userService from "../../services/user";
import { isBrowser } from "react-device-detect";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import SwitchLanguageAuthScreens from "../../components/SwitchLanguageAuthScreens";

const ResetPassword: React.FC = () => {
  const { t } = useTranslation();
  const size = useScreenSize();
  const [password1, setPassword1] = useState<string>("");
  const [password2, setPassword2] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [uuid, setUuid] = useState<string>("");
  const { setIsLoading } = useStore((state: any) => state);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href });
  }, []);

  useEffect(() => {
    const token = window.location.href.split("token=").pop();
    if (!token || token.length !== 36) {
      setErrorMessage(t("invalidPasswordResetToken"));
      return;
    }

    setUuid(token);
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    if (!password1 || password1.length === 0) {
      setErrorMessage(t("passwordRequired"));
      return;
    }
    if (password1 !== password2) {
      setErrorMessage(t("passwordMismatch"));
      return;
    }

    // atleast 8 length, atleast 1 special character
    if (/^(?=.*?[#?!@$%^&*-]).{8,}$/.test(password1) === false) {
      setErrorMessage(t("passwordValidationError"));
      return;
    }

    setIsLoading(true);
    userService
      .resetPassword({ newPassword: password1, token: uuid })
      .then((result) => {
        if (result.error) {
          setErrorMessage(result.error);
          setIsLoading(false);
          return;
        }

        setIsLoading(false);
        setErrorMessage("");
        setSuccessMessage(t("passwordResetSuccess2"));
      });
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonGrid className="mp-0">
          <IonRow>
            <IonCol
              size-xs="12"
              size-sm="12"
              size-md="12"
              size-lg="6"
              style={{
                padding: 0,
                margin: 0,
                background: Constants.ORANGE_COLOR,
                borderBottomRightRadius: isBrowser
                  ? Constants.BORDER_RADIUS
                  : 0,
                borderTopRightRadius: isBrowser ? Constants.BORDER_RADIUS : 0,
                paddingTop: isBrowser ? 100 : 50,
              }}
            >
              <AuthScreensBackground />
            </IonCol>
            <IonCol
              size-xs="12"
              size-sm="12"
              size-md="12"
              size-lg="6"
              className="mp-0"
            >
              <IonGrid
                style={{
                  maxWidth: Constants.SMALL_FORM_WIDTH,
                  paddingLeft: Constants.SMALL_FORM_PADDING,
                  paddingRight: Constants.SMALL_FORM_PADDING,
                  borderTopLeftRadius: Constants.BORDER_RADIUS,
                  borderTopRightRadius: Constants.BORDER_RADIUS,
                  transform: "translateY(-28px)",
                }}
                className={isBrowser ? "" : "bg-dark"}
              >
                <IonRow>
                  <IonCol
                    size="12"
                    className="mb-20"
                    style={{
                      marginTop:
                        size.screen === BreakPoint.xs ||
                        size.screen === BreakPoint.s
                          ? 20
                          : 160,
                    }}
                  >
                    <PageHeading text={t("restYourPassword")} />
                  </IonCol>
                  <IonCol size="12" className="mb-10">
                    <Hint text={t("resetPasswordHint")}></Hint>
                  </IonCol>
                  <IonCol size="12">
                    <Password
                      label={t("newPassword")}
                      value={password1}
                      onChange={(e: any) => setPassword1(e.target.value)}
                    />
                  </IonCol>
                  <IonCol size="12">
                    <Password
                      label={t("confirmPassword")}
                      value={password2}
                      onChange={(e: any) => setPassword2(e.target.value)}
                    />
                  </IonCol>
                  <IonCol className="mt-20" size="12">
                    <Button
                      text={t("confirm")}
                      onClick={handleSubmit}
                      type="submit"
                      color="primary"
                    />
                  </IonCol>
                  {errorMessage && (
                    <IonCol size="12">
                      <IonLabel color="danger">{errorMessage}</IonLabel>
                    </IonCol>
                  )}
                  {successMessage && (
                    <IonCol size="12">
                      <IonLabel color="primary">{successMessage}</IonLabel>
                    </IonCol>
                  )}
                  <IonCol className="ion-text-center mt-50" size="12">
                    <LinkButton
                      to="/signup"
                      label={t("dontHaveAccount")}
                      text={t("signup")}
                      labelStyle={{ fontSize: 14 }}
                      textStyle={{
                        fontWeight: 600,
                        fontSize: 14,
                      }}
                    />
                  </IonCol>
                  <IonCol className="ion-text-center" size="12">
                    <LinkButton
                      to="/login"
                      label={t("alreadyHaveAccount")}
                      text={t("login")}
                      labelStyle={{ fontSize: 14 }}
                      textStyle={{
                        fontWeight: 600,
                        fontSize: 14,
                      }}
                    />
                  </IonCol>
                  <IonCol size="12">
                    <SwitchLanguageAuthScreens />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ResetPassword;
