import {
  IonGrid,
  IonRow,
  IonCol,
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
} from "@ionic/react";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import Constants from "../../utils/constants";
import { useState, useEffect } from "react";
import userService from "../../services/user";
import useStore from "../../store/store";
import DonarCard from "../../components/npo/DonarCard";
import HeaderNavbar from "../../components/HeaderNavbar";
import { Hint } from "../../controls";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const Donars = (props: any) => {
  const { t } = useTranslation();
  const { setIsLoading } = useStore((state: any) => state);
  const size = useScreenSize();
  const [list, setList] = useState<any>([]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href });
  }, []);

  useEffect(() => {
    const id = props.match.params.npoId;
    if (!id) return;
    reload(id);
  }, []);

  const reload = async (id: string) => {
    setIsLoading(true);
    userService.getDonars({ npoId: id === "all" ? "" : id }).then((result) => {
      if (result.data) {
        let t: any[] = result.data;
        t = t.filter((k) => parseInt(k.donations) > 0);
        setList(t);
      }
    });
    setIsLoading(false);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton className="btn-back"></IonBackButton>
          </IonButtons>
          <IonTitle>{t("donors")}</IonTitle>
          {size.screen !== BreakPoint.xs && size.screen !== BreakPoint.s && (
            <HeaderNavbar />
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large" className="ios-page-heading">
              {t("donors")}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid
          style={{
            padding: 0,
            maxWidth:
              size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                ? "100%"
                : Constants.MAX_GRID_WIDTH,
          }}
        >
          <IonRow>
            {list.length === 0 && (
              <IonCol size="12">
                <Hint text={t("noDonors")} />
              </IonCol>
            )}
            {list.map((k: any, idx: number) => (
              <IonCol size="6" key={idx}>
                <DonarCard data={k} key={idx} />
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Donars;
