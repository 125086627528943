import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonBackButton,
  IonImg,
  IonAvatar,
  IonIcon,
  IonButtons,
  IonItem,
  IonNote,
  IonList,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import Constants from "../../utils/constants";
import imgDivider from "../../images/divider.svg";
import npoService from "../../services/npo";
import HeaderNavbar from "../../components/HeaderNavbar";
import { cardOutline, colorFillOutline, infiniteOutline } from "ionicons/icons";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const Donate: React.FC = (props: any) => {
  const { t } = useTranslation();
  const size = useScreenSize();
  const history = useHistory();
  const [npo, setNpo] = useState<any>();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href });
  }, []);

  useEffect(() => {
    const projectId = props.match.params.projectId;
    if (!projectId) return;
    npoService.getNpoDetailsByProjectId(projectId).then((result) => {
      if (result.data) {
        setNpo(result.data);
      }
    });
  }, [props.match.params.projectId]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton className="btn-back"></IonBackButton>
          </IonButtons>
          <IonTitle>{t("makeDonation")}</IonTitle>
          {size.screen !== BreakPoint.xs && size.screen !== BreakPoint.s && (
            <HeaderNavbar />
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large" className="ios-page-heading">
              {t("makeDonation")}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid
          style={{
            padding: 0,
            maxWidth:
              size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                ? "100%"
                : Constants.MAX_GRID_WIDTH,
          }}
        >
          <IonRow className="center">
            <IonCol size="12">
              <IonAvatar
                style={{
                  cursor: "pointer",
                  width: 160,
                  height: 160,
                  marginTop: 20,
                  marginLeft:
                    size.screen === BreakPoint.xs ||
                    size.screen === BreakPoint.s
                      ? size.width / 2 - 80
                      : Constants.MAX_GRID_WIDTH / 2 - 80,
                }}
              >
                <IonImg src={npo?.fileUrl} alt={npo?.name} />
              </IonAvatar>
            </IonCol>

            <IonCol size="12" className="mb-20">
              <p className="fwfs-500-20">{npo?.title}</p>
              <IonLabel className="fwfs-500-14" color="primary">
                {npo?.userId?.name}
              </IonLabel>
            </IonCol>

            <IonCol size-xs="3" size-sm="3" size-md="4" size-lg="4">
              <IonImg src={imgDivider} style={{ width: "100%" }} />
            </IonCol>
            <IonCol size-xs="6" size-sm="6" size-md="4" size-lg="4">
              <IonLabel className="fwfs-500-18 ion-text-nowrap">
                {t("chooseYourMethod")}
              </IonLabel>
            </IonCol>
            <IonCol size-xs="3" size-sm="3" size-md="4" size-lg="4">
              <IonImg src={imgDivider} style={{ width: "100%" }} />
            </IonCol>

            <IonList className="mt-20 mb-20">
              <IonItem
                detail={true}
                button
                onClick={() =>
                  history.push(`/donate-now/${props.match.params.projectId}`)
                }
              >
                <IonIcon icon={cardOutline} slot="start" />
                <IonLabel>
                  <h3>{t("donateNow")}</h3>
                  <IonNote slot="helper" className="ion-text-wrap fwfs-500-14">
                    {t("donateViaPaypal")}
                  </IonNote>
                </IonLabel>
              </IonItem>
              <IonItem
                detail={true}
                button
                onClick={() =>
                  history.push(
                    `/donate-subscription/${props.match.params.projectId}`
                  )
                }
              >
                <IonIcon icon={infiniteOutline} slot="start" />
                <IonLabel>
                  <h3>{t("createSubscription")}</h3>
                  <IonNote slot="helper" className="ion-text-wrap fwfs-500-14">
                    {t("createSubscriptionFor")} {Constants.CURRENCY_SYMBOL}
                    {t("createSubscriptionFor2")}
                  </IonNote>
                </IonLabel>
              </IonItem>
              <IonItem
                detail={true}
                button
                onClick={() =>
                  history.push(`/donate-points/${props.match.params.projectId}`)
                }
              >
                <IonIcon icon={colorFillOutline} slot="start" />
                <IonLabel>
                  <h3>{t("thanksPoints")}</h3>
                  <IonNote slot="helper" className="ion-text-wrap fwfs-500-14">
                    {t("donateViaThanksPoints")} {process.env.REACT_APP_NAME}{" "}
                    {t("donateViaThanksPoints1")} {Constants.CURRENCY_SYMBOL}
                    {Constants.POINTS.EQUIVALENT_TO}{" "}
                    {t("donateViaThanksPoints2")}
                    {t("donateViaThanksPoints3")} {Constants.POINTS.MIN_LIMIT}{" "}
                    {t("donateViaThanksPoints4")}
                  </IonNote>
                </IonLabel>
              </IonItem>
            </IonList>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Donate;
