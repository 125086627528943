import useStore from "../store/store";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import imgShelters from "../images/shelters.svg";
import imgHumanRights from "../images/human-rights.svg";
import { useHistory } from "react-router-dom";
import CategoriesGridItem from "./CategoriesGridItem";
import { useTranslation } from "react-i18next";

const CategoriesGridFilter = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { selectedCategory, setSelectedCategory } = useStore(
    (state: any) => state
  );

  const onChange = (v: string) => {
    setSelectedCategory(selectedCategory === v ? "" : v);
    history.push("/npos");
  };

  return (
    <IonGrid>
      <IonRow>
        <IonCol size="6">
          <CategoriesGridItem
            title={t("shelters")}
            img={imgShelters}
            selected={selectedCategory === "shelters"}
            onChange={onChange}
          />
        </IonCol>
        {/* <IonCol size="6">
			<CategoriesGridItem
				title="Environment"
				img={imgEnvironment}
				selected={selectedCategory === "environment"}
				onChange={onChange}
			/>
		</IonCol>
		<IonCol size="6">
			<CategoriesGridItem
				title="Education"
				img={imgEducation}
				selected={selectedCategory === "education"}
				onChange={onChange}
			/>
		</IonCol> */}
        <IonCol size="6">
          <CategoriesGridItem
            title={t("disasterRecovery")}
            img={imgHumanRights}
            selected={selectedCategory === "disaster recovery"}
            onChange={onChange}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default CategoriesGridFilter;
