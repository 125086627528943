import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonPage,
  IonLabel,
  IonCol,
  IonGrid,
  IonRow,
  IonBackButton,
  IonButtons,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonItemDivider,
} from "@ionic/react";
import Constants from "../../utils/constants";
import useStore from "../../store/store";
import { Button, Password } from "../../controls";
import userService from "../../services/user";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import HeaderNavbar from "../../components/HeaderNavbar";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const UpdatePassword: React.FC = () => {
  const size = useScreenSize();
  const { t } = useTranslation();
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [password1, setPassword1] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const { token, setIsLoading } = useStore((state: any) => state);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href });
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    if (!currentPassword || currentPassword.length === 0) {
      setErrorMessage(t("currentPasswordRequired"));
      return;
    }
    if (!password || password.length === 0) {
      setErrorMessage(t("newPasswordRequired"));
      return;
    }
    if (password !== password1) {
      setErrorMessage(t("newPasswordMissmatch"));
      return;
    }

    // atleast 8 length, atleast 1 special character
    if (/^(?=.*?[#?!@$%^&*-]).{8,}$/.test(password) === false) {
      setErrorMessage(t("passwordValidationError"));
      return;
    }

    setIsLoading(true);
    userService
      .updatePassword(token, {
        currentPassword: currentPassword,
        newPassword: password,
      })
      .then((result) => {
        if (result.error) {
          setErrorMessage(result.error);
          setIsLoading(false);
          return;
        }

        const data = result?.data || false;
        if (!data) return;

        setIsLoading(false);
        setErrorMessage("");
        setSuccessMessage(t("passwordResetSuccess3"));
      });
  };

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton className="btn-back"></IonBackButton>
            </IonButtons>
            <IonTitle>{t("updatedPassword")}</IonTitle>
            {size.screen !== BreakPoint.xs && size.screen !== BreakPoint.s && (
              <HeaderNavbar />
            )}
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large" className="ios-page-heading">
                {t("updatedPassword")}
              </IonTitle>
            </IonToolbar>
          </IonHeader>

          <IonGrid
            style={{
              padding: 0,
              maxWidth:
                size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                  ? "100%"
                  : Constants.MAX_GRID_WIDTH,
            }}
          >
            <IonRow className="mt-20">
              <IonCol size="12">
                <Password
                  label={t("currentPassword")}
                  value={currentPassword}
                  onChange={(e: any) => setCurrentPassword(e.target.value)}
                />
              </IonCol>
              <IonItemDivider style={{ minHeight: 10 }} />
              <IonCol size="12" className="mt-10">
                <Password
                  label={t("newPassword")}
                  value={password}
                  onChange={(e: any) => setPassword(e.target.value)}
                />
              </IonCol>
              <IonCol size="12" className="mt-10">
                <Password
                  label={t("repeatNewPassword")}
                  value={password1}
                  onChange={(e: any) => setPassword1(e.target.value)}
                />
              </IonCol>
              {errorMessage && (
                <IonCol size="12">
                  <IonLabel color="danger">{errorMessage}</IonLabel>
                </IonCol>
              )}
              {successMessage && (
                <IonCol size="12">
                  <IonLabel color="primary">{successMessage}</IonLabel>
                </IonCol>
              )}
              <IonCol className="mt-20" size="12">
                <Button
                  text={t("saveChanges")}
                  onClick={handleSubmit}
                  type="submit"
                  color="primary"
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </>
  );
};

export default UpdatePassword;
