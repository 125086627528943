import { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import useStore from "../store/store";
import useScreenSize, { BreakPoint } from "../hooks/useScreenSize";

const Notification = () => {
  const size = useScreenSize();
  const { notification } = useStore((state: any) => state);

  const notify = () =>
    toast.success(
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ fontWeight: 600, fontSize: 16 }}>
          {notification?.title}
        </div>
        <div style={{ fontWeight: 400, fontSize: 14 }}>
          {notification?.body}
        </div>
      </div>,
      {
        duration: 4000,
        position:
          size.screen === BreakPoint.xs || size.screen === BreakPoint.s
            ? "bottom-center"
            : "bottom-right",
        icon: <img src="/assets/icon/favicon.png" alt="" />,
      }
    );

  useEffect(() => {
    if (notification?.title) notify();
  }, [notification]);

  return <Toaster />;
};

export default Notification;
