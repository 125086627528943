import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonPage,
  IonLabel,
  IonCol,
  IonGrid,
  IonRow,
  IonBackButton,
} from "@ionic/react";
import Utils from "../../utils/utils";
import Constants from "../../utils/constants";
import useStore from "../../store/store";
import { Email, Button, LinkButton, Hint } from "../../controls";
import PageHeading from "../../components/PageHeading";
import AuthScreensBackground from "../../components/AuthScreensBackground";
import userService from "../../services/user";
import { isBrowser } from "react-device-detect";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import SwitchLanguageAuthScreens from "../../components/SwitchLanguageAuthScreens";

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();
  const size = useScreenSize();
  const [email, setEmail] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const { setIsLoading } = useStore((state: any) => state);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href });
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    if (!email || email.length === 0) {
      setErrorMessage(t("emailRequired"));
      return;
    } else {
      if (!Utils.isValidEmail(email)) {
        setErrorMessage(t("emailShouldValid"));
        return;
      }
    }

    setIsLoading(true);
    userService.forgotPassword(email).then((result) => {
      if (result.error) {
        setErrorMessage(result.error);
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
      setErrorMessage("");
      setSuccessMessage(t("passwordResetSuccess"));
    });
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonGrid className="mp-0">
          <IonRow>
            <IonCol
              size-xs="12"
              size-sm="12"
              size-md="6"
              size-lg="6"
              style={{
                padding: 0,
                margin: 0,
                background: Constants.ORANGE_COLOR,
                borderBottomRightRadius: isBrowser
                  ? Constants.BORDER_RADIUS
                  : 0,
                borderTopRightRadius: isBrowser ? Constants.BORDER_RADIUS : 0,
                paddingTop: isBrowser ? 100 : 50,
              }}
            >
              <AuthScreensBackground />
            </IonCol>
            <IonCol
              size-xs="12"
              size-sm="12"
              size-md="6"
              size-lg="6"
              className="mp-0"
            >
              <IonGrid
                style={{
                  maxWidth: Constants.SMALL_FORM_WIDTH,
                  paddingLeft: Constants.SMALL_FORM_PADDING,
                  paddingRight: Constants.SMALL_FORM_PADDING,
                  borderTopLeftRadius: Constants.BORDER_RADIUS,
                  borderTopRightRadius: Constants.BORDER_RADIUS,
                  transform: "translateY(-28px)",
                }}
                className={isBrowser ? "" : "bg-dark"}
              >
                <IonRow>
                  <IonCol
                    size="12"
                    className="mb-20"
                    style={{
                      marginTop:
                        size.screen === BreakPoint.xs ||
                        size.screen === BreakPoint.s
                          ? 20
                          : 160,
                    }}
                  >
                    <IonRow>
                      <IonBackButton className="btn-back"></IonBackButton>
                      <PageHeading text={t("forgotPassword")} />
                    </IonRow>
                  </IonCol>
                  <IonCol size="12" className="mb-10">
                    <Hint text={t("forgotPasswordHint")}></Hint>
                  </IonCol>
                  <IonCol size="12">
                    <Email
                      label="Email"
                      value={email}
                      onChange={(e: any) => setEmail(e.target.value)}
                    />
                  </IonCol>
                  <IonCol className="mt-20" size="12">
                    <Button
                      text={t("confirm")}
                      onClick={handleSubmit}
                      type="submit"
                      color="primary"
                    />
                  </IonCol>
                  {errorMessage && (
                    <IonCol size="12">
                      <IonLabel color="danger">{errorMessage}</IonLabel>
                    </IonCol>
                  )}
                  {successMessage && (
                    <IonCol size="12">
                      <IonLabel color="primary">{successMessage}</IonLabel>
                    </IonCol>
                  )}
                  <IonCol className="ion-text-center mt-50" size="12">
                    <LinkButton
                      to="/signup"
                      label={t("dontHaveAccount")}
                      text={t("signup")}
                      labelStyle={{ fontSize: 14 }}
                      textStyle={{
                        fontWeight: 600,
                        fontSize: 14,
                      }}
                    />
                  </IonCol>
                  <IonCol className="ion-text-center" size="12">
                    <LinkButton
                      to="/login"
                      label={t("alreadyHaveAccount")}
                      text={t("login")}
                      labelStyle={{ fontSize: 14 }}
                      textStyle={{
                        fontWeight: 600,
                        fontSize: 14,
                      }}
                    />
                  </IonCol>
                  <IonCol size="12">
                    <SwitchLanguageAuthScreens />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ForgotPassword;
