import { IonImg } from "@ionic/react";
import { Capacitor } from "@capacitor/core";
import { Share } from "@capacitor/share";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import fbIcon from "../images/fb-icon.png";
import twitterIcon from "../images/twitter-icon.png";
import instagramIcon from "../images/instagram-icon.png";
import { useTranslation } from "react-i18next";

const SocialShare = ({ title, text, url }: any) => {
  const { t } = useTranslation();
  const handleShareNative = async () => {
    await Share.share({
      title: title,
      text: text,
      url: url,
      dialogTitle: t("shareOnSocialMedia"),
    });
  };
  const iconStyle = {
    height: 48,
    width: 48,
    marginLeft: 5,
    marginRight: 5,
    cursor: "pointer",
  };

  return Capacitor.getPlatform() !== "web" ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <IonImg src={fbIcon} style={iconStyle} onClick={handleShareNative} />
      <IonImg src={twitterIcon} style={iconStyle} onClick={handleShareNative} />
      <IonImg
        src={instagramIcon}
        style={iconStyle}
        onClick={handleShareNative}
      />
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <FacebookShareButton url={url} quote={text}>
        <IonImg src={fbIcon} style={iconStyle} />
      </FacebookShareButton>
      <TwitterShareButton
        url={url}
        title={text}
        via={process.env.REACT_APP_NAME}
        related={[process.env.REACT_APP_NAME || ""]}
        hashtags={[process.env.REACT_APP_NAME || ""]}
      >
        <IonImg src={twitterIcon} style={iconStyle} />
      </TwitterShareButton>
    </div>
  );
};

export default SocialShare;
