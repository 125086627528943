import { IonLoading } from "@ionic/react";
import useStore from "../store/store";
import { useTranslation } from "react-i18next";

const Loading = () => {
  const { isLoading } = useStore((state: any) => state);
  const { t } = useTranslation();
  return (
    <IonLoading
      // cssClass="my-custom-class"
      isOpen={isLoading}
      // onDidDismiss={() => setIsLoading(false)}
      message={t("pleaseWaitLoading")}
      // duration={5000}
      spinner="crescent"
    />
  );
};

export default Loading;
