import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonPage,
  IonLabel,
  IonCol,
  IonGrid,
  IonRow,
} from "@ionic/react";
import Constants from "../../utils/constants";
import { LinkButton } from "../../controls";
import PageHeading from "../../components/PageHeading";
import AuthScreensBackground from "../../components/AuthScreensBackground";
import userService from "../../services/user";
import { isBrowser } from "react-device-detect";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import SwitchLanguageAuthScreens from "../../components/SwitchLanguageAuthScreens";

const Activate: React.FC = () => {
  const { t } = useTranslation();
  const size = useScreenSize();
  const [isActive, setIsActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href });
  }, []);

  useEffect(() => {
    const token = window.location.href.split("token=").pop();
    if (!token || token.length !== 24) {
      setErrorMessage(t("invalidActionToken"));
      return;
    }

    userService.activateAccount(token).then((result) => {
      if (result.error) {
        setErrorMessage(result.error);
        return;
      }

      setErrorMessage("");
      setIsActive(true);
    });
  }, []);

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonGrid className="mp-0">
          <IonRow>
            <IonCol
              size-xs="12"
              size-sm="12"
              size-md="12"
              size-lg="6"
              style={{
                padding: 0,
                margin: 0,
                background: Constants.ORANGE_COLOR,
                borderBottomRightRadius: isBrowser
                  ? Constants.BORDER_RADIUS
                  : 0,
                borderTopRightRadius: isBrowser ? Constants.BORDER_RADIUS : 0,
                paddingTop: isBrowser ? 100 : 50,
              }}
            >
              <AuthScreensBackground />
            </IonCol>
            <IonCol
              size-xs="12"
              size-sm="12"
              size-md="12"
              size-lg="6"
              className="mp-0"
            >
              <IonGrid
                style={{
                  maxWidth: Constants.SMALL_FORM_WIDTH,
                  paddingLeft: Constants.SMALL_FORM_PADDING,
                  paddingRight: Constants.SMALL_FORM_PADDING,
                  borderTopLeftRadius: Constants.BORDER_RADIUS,
                  borderTopRightRadius: Constants.BORDER_RADIUS,
                  transform: "translateY(-28px)",
                }}
                className={isBrowser ? "" : "bg-dark"}
              >
                <IonRow>
                  <IonCol
                    size="12"
                    className="mb-20"
                    style={{
                      marginTop:
                        size.screen === BreakPoint.xs ||
                        size.screen === BreakPoint.s
                          ? 20
                          : 160,
                    }}
                  >
                    <PageHeading text={t("accountActivation")} />
                  </IonCol>
                  <IonCol size="12" className="ion-text-center">
                    {isActive && (
                      <IonLabel color="primary">
                        {t("accountActivationSuccess")}
                        <br />
                        {t("loginAndGetStarted")}
                      </IonLabel>
                    )}
                  </IonCol>
                  {errorMessage && (
                    <IonCol size="12">
                      <IonLabel color="danger">{errorMessage}</IonLabel>
                    </IonCol>
                  )}
                  {successMessage && (
                    <IonCol size="12">
                      <IonLabel color="primary">{successMessage}</IonLabel>
                    </IonCol>
                  )}
                  {isActive && (
                    <IonCol className="ion-text-center mt-50">
                      <LinkButton
                        to="/login"
                        label={t("loginAndGetStarted")}
                        text={t("login")}
                        labelStyle={{ fontSize: 14 }}
                        textStyle={{
                          fontWeight: 600,
                          fontSize: 14,
                        }}
                      />
                    </IonCol>
                  )}

                  <IonCol size="12" className="mt-50">
                    <SwitchLanguageAuthScreens />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Activate;
