import imgLogo from "../images/Logo.svg";
import { IonImg } from "@ionic/react";
import Constants from "../utils/constants";

const Logo = ({ height, width }: any) => {
  return (
    <div
      className="center"
      style={{
        background: Constants.ORANGE_COLOR,
        display: "flex",
      }}
    >
      <IonImg src={imgLogo} style={{ width: width, height: height }} />
    </div>
  );
};

export default Logo;
