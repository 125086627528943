import { useState, useEffect } from "react";
import { IonImg } from "@ionic/react";
import Logo from "./Logo";
import bgImage from "../images/Subscribe_1.svg";
import { isBrowser } from "react-device-detect";
import useScreenSize, { BreakPoint } from "../hooks/useScreenSize";
import Constants from "../utils/constants";
import { useTranslation } from "react-i18next";

const AuthScreensBackground = () => {
  const { t } = useTranslation();
  const size = useScreenSize();
  const [padding, setPadding] = useState(180);

  useEffect(() => {
    if (size.screen === BreakPoint.xs) {
      setPadding(50);
    } else if (size.screen === BreakPoint.s) {
      setPadding(50);
    } else if (size.screen === BreakPoint.m) {
      setPadding(80);
    } else if (size.screen === BreakPoint.l) {
      setPadding(100);
    } else if (size.screen === BreakPoint.xl) {
      setPadding(180);
    }
  }, [size]);

  return (
    <div>
      <Logo width={isBrowser ? 160 : 90} height={isBrowser ? 32 : 36} />
      <div className="ion-hide-md-down center">
        <p
          className="page-heading mb-30"
          style={{ color: "#121212", marginTop: 50 }}
        >
          {t("authq1")}
        </p>
        <p
          className=""
          style={{
            color: "#121212",
            paddingLeft: padding,
            paddingRight: padding,
          }}
        >
          <span
            style={{
              color: Constants.PRIMARY,
              fontWeight: 700,
              fontSize: 14,
            }}
          >
            {process.env.REACT_APP_NAME}
          </span>{" "}
          <span
            style={{
              fontWeight: 500,
              fontSize: 14,
            }}
          >
            {t("autha1")}
          </span>
        </p>
      </div>
      <IonImg
        src={bgImage}
        style={{
          objectFit: "cover",
          marginTop:
            size.screen === BreakPoint.xs || size.screen === BreakPoint.s
              ? 30
              : 150,
        }}
      />
    </div>
  );
};

export default AuthScreensBackground;
