import { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonList,
  IonItem,
  IonBackButton,
  IonNote,
  useIonAlert,
  IonGrid,
  IonRow,
  IonCol,
  IonButtons,
  IonBadge,
  IonAccordionGroup,
  IonAccordion,
} from "@ionic/react";
import { sadOutline } from "ionicons/icons";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import Constants from "../../utils/constants";
import subscriptionService from "../../services/subscription";
import useStore from "../../store/store";
import HeaderNavbar from "../../components/HeaderNavbar";
import moment from "moment";
import Utils from "../../utils/utils";
import { Button } from "../../controls";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const Subscriptions = () => {
  const { t } = useTranslation();
  const { token, setIsLoading } = useStore((state: any) => state);
  const [presentAlert] = useIonAlert();
  const size = useScreenSize();
  const [list, setList] = useState<any[]>([]);

  const handleCancel = (data: any) => {
    presentAlert({
      header: t("areYouSure"),
      message: t("cancelSubscriptionHint"),
      buttons: [
        {
          text: t("cancel"),
          role: "cancel",
          handler: () => {},
        },
        {
          text: t("confirm"),
          role: "confirm",
          handler: () => {
            setIsLoading(true);
            subscriptionService.cancel(token, data._id).then(() => {
              reload();
              setIsLoading(false);
            });
          },
        },
      ],
    });
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href });
  }, []);

  useEffect(() => {
    reload();
  }, []);

  const reload = async () => {
    setIsLoading(true);
    let result = await subscriptionService.getByDonarId(token);
    if (result.data) {
      setList(result.data);
      setIsLoading(false);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton className="btn-back"></IonBackButton>
          </IonButtons>
          <IonTitle>{t("subscriptions")}</IonTitle>
          {size.screen !== BreakPoint.xs && size.screen !== BreakPoint.s && (
            <HeaderNavbar />
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large" className="ios-page-heading">
              {t("subscriptions")}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid
          style={{
            padding: 0,
            maxWidth:
              size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                ? "100%"
                : Constants.MAX_GRID_WIDTH,
          }}
        >
          <IonRow>
            <IonCol size="12">
              <IonList>
                {list.length === 0 && (
                  <IonItem>
                    <IonLabel className="fwfs-400-14">
                      {t("noSubscriptions")}
                    </IonLabel>
                  </IonItem>
                )}
                {list.map((item: any, idx: number) => (
                  <IonAccordionGroup
                    animated={true}
                    key={item._id}
                    multiple={false}
                  >
                    <IonAccordion id={item._id}>
                      <IonItem button slot="header">
                        <IonLabel>
                          <h2>{item?.projectId?.title}</h2>
                          <IonNote
                            slot="helper"
                            className="ion-text-wrap fwfs-500-14"
                          >
                            {t("since")}{" "}
                            {moment(item.date).format("DD MMM YYYY")}
                          </IonNote>
                          <br />
                          <IonNote
                            slot="helper"
                            className="ion-text-wrap fwfs-500-14"
                          >
                            {item?.subscriptionId}
                          </IonNote>
                        </IonLabel>
                        {item?.status === "active" && (
                          <IonBadge
                            color="primary"
                            className="ion-text-capitalize"
                          >
                            {t(item.status)}
                          </IonBadge>
                        )}
                        {item?.status === "cancelled" && (
                          <IonBadge
                            color="medium"
                            className="ion-text-capitalize"
                          >
                            {t(item.status)}
                          </IonBadge>
                        )}
                        <IonLabel slot="end" color="primary">
                          {Constants.CURRENCY_SYMBOL}
                          {Utils.formatToNumber(item?.total)}
                        </IonLabel>
                      </IonItem>
                      <div className="ion-padding" slot="content">
                        <IonRow>
                          <IonCol size="12">
                            <IonLabel>{t("donationsDoingGood")}</IonLabel>
                          </IonCol>
                          <IonCol size="6"></IonCol>
                          <IonCol size="6">
                            <Button
                              text={t("cancelAnyway")}
                              type="button"
                              color="dark"
                              icon={sadOutline}
                              onClick={() => handleCancel(item)}
                            />
                          </IonCol>
                        </IonRow>
                      </div>
                    </IonAccordion>
                  </IonAccordionGroup>
                ))}
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Subscriptions;
