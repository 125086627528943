import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  IonBackButton,
  IonButton,
  useIonAlert,
  IonButtons,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import useStore from "../../store/store";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import Constants from "../../utils/constants";
import NpoHeader from "../../components/npo/NpoHeader";
import AchievementBox from "../../components/npo/AchievementBox";
import achievementService from "../../services/achievement";
import moment from "moment";
import HeaderNavbar from "../../components/HeaderNavbar";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const Achievements: React.FC = () => {
  const { t } = useTranslation();
  const { token, setIsLoading } = useStore((state: any) => state);
  const size = useScreenSize();
  const history = useHistory();
  const [presentAlert] = useIonAlert();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [list, setList] = useState<any>([]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href });
  }, []);

  useEffect(() => {
    reload();
  }, []);

  const reload = () => {
    achievementService.getAll(token).then((result) => {
      if (result.error) {
        setErrorMessage(result.error);
        setIsLoading(false);
        return;
      }

      const data = result?.data || false;
      if (!data) return;

      setList(data);
    });
  };

  const handleCreateAchievement = (e: any) => {
    e.preventDefault();
    achievementService
      .create(token, {
        text: "",
        active: true,
        date: moment().format(),
      })
      .then((result) => {
        if (result.error) {
          setErrorMessage(result.error);
          setIsLoading(false);
          return;
        }

        const data = result?.data || false;
        if (!data) return;

        history.push(`/achievements/${data["_id"]}`);
      });
  };

  const handleDelete = (id: string) => {
    presentAlert({
      header: "Are you sure you want to delete?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          handler: () => {},
        },
        {
          text: "Delete",
          role: "confirm",
          handler: () => {
            achievementService.deleteById(token, id).then((result) => {
              if (result.error) {
                setErrorMessage(result.error);
                setIsLoading(false);
                return;
              }
              reload();
            });
          },
        },
      ],
    });
  };

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton className="btn-back"></IonBackButton>
            </IonButtons>
            <IonTitle>{t("editAchievements")}</IonTitle>
            {size.screen !== BreakPoint.xs && size.screen !== BreakPoint.s && (
              <HeaderNavbar />
            )}
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large" className="ios-page-heading">
                {t("editAchievements")}
              </IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonGrid
            style={{
              padding: 0,
              maxWidth:
                size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                  ? "100%"
                  : Constants.MAX_GRID_WIDTH,
            }}
          >
            <NpoHeader />
          </IonGrid>
          <IonGrid
            style={{
              marginTop: 10,
              maxWidth:
                size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                  ? "100%"
                  : Constants.MAX_GRID_WIDTH,
            }}
          >
            <IonRow>
              <IonCol size="8">
                <IonLabel className="fwfs-500-18">
                  {t("activeAchievements")}
                </IonLabel>
              </IonCol>
              <IonCol size="4" className="ion-text-end">
                <IonButton
                  fill="clear"
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    marginTop: 0,
                    padding: 0,
                    height: 30,
                  }}
                  onClick={handleCreateAchievement}
                >
                  {t("addNew")}
                </IonButton>
              </IonCol>
              <IonItemDivider style={{ minHeight: 10 }} />

              <IonCol size="12" className="mt-10" style={{ padding: 0 }}>
                {list.map((achievement: any, idx: number) => (
                  <AchievementBox key={idx} data={achievement} />
                ))}
              </IonCol>

              {errorMessage && (
                <IonCol size="12">
                  <IonLabel color="danger">{errorMessage}</IonLabel>
                </IonCol>
              )}
              {successMessage && (
                <IonCol size="12">
                  <IonLabel color="primary">{successMessage}</IonLabel>
                </IonCol>
              )}
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Achievements;
