import { useState } from "react";
import { IonLabel, IonImg, IonGrid, IonRow, IonCol } from "@ionic/react";
import Constants from "../../utils/constants";
import useStore from "../../store/store";
import { useHistory } from "react-router-dom";
import useScreenSize from "../../hooks/useScreenSize";
import { useTranslation } from "react-i18next";

const DonarCard = ({ data }: any) => {
  const { t } = useTranslation();
  const size = useScreenSize();
  const history = useHistory();
  const { token, setIsLoading } = useStore((state: any) => state);

  return (
    <>
      <div
        style={{
          width: 170,
          minWidth: 170,
          height: 200,
          borderRadius: Constants.BORDER_RADIUS,
          padding: 20,
          backgroundColor: Constants.ORANGE_COLOR,
          marginRight: 5,
          marginLeft: 5,
          marginBottom: 10,
          cursor: "pointer",
        }}
        onClick={(e) => history.push(`/donar/${data._id}`)}
      >
        <IonGrid>
          <IonRow>
            <IonCol size="12">
              <IonImg
                src={data.fileUrl}
                style={{
                  width: 65,
                  height: 65,
                  borderRadius: "50%",
                  objectFit: "cover",
                  overflow: "hidden",
                  marginLeft: 170 / 2 - 62,
                }}
              />
            </IonCol>
            <IonCol size="12" className="ion-text-center mt-10">
              <IonLabel
                className="fwfs-500-16"
                style={{ color: Constants.DARK_COLOR }}
              >
                {data.name}
              </IonLabel>
            </IonCol>
            <IonCol size="12" className="ion-text-center">
              <IonLabel color="primary">{data.donations || 0}</IonLabel>{" "}
              <IonLabel
                style={{ color: Constants.DARK_COLOR }}
                className="fwfs-400-12"
              >
                {t("donations")}
              </IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </>
  );
};

export default DonarCard;
