import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonBackButton,
  IonImg,
  IonAvatar,
  IonItemDivider,
  useIonAlert,
  IonButtons,
} from "@ionic/react";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import Constants from "../../utils/constants";
import { useState, useEffect } from "react";
import userService from "../../services/user";
import npoService from "../../services/npo";
import paymentService from "../../services/payment";
import Styles from "../../css/styles";
import { Hint, LinkButton } from "../../controls";
import TopDonars from "../../components/npo/TopDonars";
import imgAchievement1 from "../../images/achievement1.svg";
import imgAchievement2 from "../../images/achievement2.svg";
import SupportedNpoRow from "../../components/npo/SupportedNpoRow";
import DonationIsValueable from "../../components/DonationIsValueable";
import HeaderNavbar from "../../components/HeaderNavbar";
import useStore from "../../store/store";
import Analytics from "../../components/npo/Analytics";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const DonarDetails = (props: any) => {
  const { t } = useTranslation();
  const { token } = useStore((state: any) => state);
  const size = useScreenSize();
  const [presentAlert] = useIonAlert();
  const [donar, setDonar] = useState<any>(null);
  const [npos, setNpos] = useState<any>([]);
  const [donations, setDonations] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href });
  }, []);

  useEffect(() => {
    const id = props.match.params.donarId;
    if (!id) return;
    reload(id);
  }, [props.match.params.donarId]);

  const reload = async (id: string) => {
    const result = await userService.getById(token, id);
    if (result.error) {
      setErrorMessage(result.error);
      return;
    }
    const _donar: any = result.data;
    setDonar(_donar);

    const _r = await npoService.getNposSupportedByUserId({
      userId: id,
      keyword: "",
    });
    if (_r.error) {
      setErrorMessage(_r.error);
      return;
    }
    const _npos: any = _r.data;
    setNpos(_npos.slice(0, 3));

    const donations = await paymentService.getSumDonationsByDonar({
      donarId: id,
    });
    if (donations.error) {
      setErrorMessage(donations.error);
      return;
    }
    const _donations: any = donations.data;
    setDonations(_donations);
  };

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton className="btn-back"></IonBackButton>
            </IonButtons>
            <IonTitle>{t("donorProfile")}</IonTitle>
            {size.screen !== BreakPoint.xs && size.screen !== BreakPoint.s && (
              <HeaderNavbar />
            )}
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large" className="ios-page-heading">
                {t("donorProfile")}
              </IonTitle>
            </IonToolbar>
          </IonHeader>
          {donar && (
            <IonGrid
              style={{
                padding: 0,
                maxWidth:
                  size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                    ? "100%"
                    : Constants.MAX_GRID_WIDTH,
              }}
            >
              <IonRow style={Styles.orangeHeader} className="center">
                <IonCol size="12">
                  <IonAvatar
                    style={{
                      width: 100,
                      height: 100,
                      marginTop: 20,
                      marginLeft:
                        size.screen === BreakPoint.xs ||
                        size.screen === BreakPoint.s
                          ? size.width / 2 - 50
                          : Constants.MAX_GRID_WIDTH / 2 - 50,
                    }}
                  >
                    <IonImg src={donar?.fileUrl} alt={donar?.name} />
                  </IonAvatar>
                </IonCol>
                <IonCol size="12" className="mt-10">
                  <IonLabel
                    className="fwfs-500-24"
                    style={{
                      color: Constants.DARK_COLOR,
                    }}
                  >
                    {donar?.name}
                  </IonLabel>
                </IonCol>
                <IonCol size="12">
                  <IonLabel color="primary" className="fwfs-500-18">
                    {donar?.points}
                  </IonLabel>{" "}
                  <IonLabel
                    className="fwfs-400-14"
                    style={{
                      color: Constants.DARK_COLOR,
                    }}
                  >
                    {t("points")}
                  </IonLabel>
                </IonCol>
                <IonCol
                  size="12"
                  className="center"
                  style={{
                    width: "100%",
                    display: "flex",
                    flexWrap: "nowrap",
                  }}
                >
                  {donations?.sumMine >= Constants.BADGES.LOW.mine &&
                    donations?.sumReferal >= Constants.BADGES.LOW.ref && (
                      <IonImg
                        src={imgAchievement1}
                        style={{
                          width: 70,
                          height: 90,
                        }}
                      />
                    )}

                  {donations?.sumMine >= Constants.BADGES.MED.mine &&
                    donations?.sumReferal >= Constants.BADGES.MED.ref && (
                      <IonImg
                        src={imgAchievement2}
                        style={{
                          width: 70,
                          height: 90,
                        }}
                      />
                    )}

                  {donations?.sumMine >= Constants.BADGES.TOP.mine &&
                    donations?.sumReferal >= Constants.BADGES.TOP.ref && (
                      <IonImg
                        src={imgAchievement1}
                        style={{
                          width: 70,
                          height: 90,
                        }}
                      />
                    )}
                </IonCol>
              </IonRow>
            </IonGrid>
          )}
          {donar && (
            <IonGrid
              style={{
                marginTop: 10,
                maxWidth:
                  size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                    ? "100%"
                    : Constants.MAX_GRID_WIDTH,
              }}
            >
              <IonRow>
                <IonCol size="12" className="mt-10">
                  <IonLabel className="fwfs-500-18">{t("details")}</IonLabel>
                </IonCol>
                <IonItemDivider style={{ minHeight: 10 }} />
                <IonCol size="12" className="fwfs-400-14">
                  <IonLabel>{donar?.about}</IonLabel>
                </IonCol>
                <IonCol size="8" className="mt-10">
                  <IonLabel className="fwfs-500-18">
                    {t("nposImSupoorting")}
                  </IonLabel>
                </IonCol>
                <IonCol size="4" className="ion-text-end mt-10">
                  <LinkButton
                    to={`/donar-supported-npos/${donar?._id}`}
                    text={t("viewAll")}
                    textClassName="fwfs-500-14"
                  />
                </IonCol>
                <IonItemDivider style={{ minHeight: 10 }} />
                <IonCol size="12" className="fwfs-400-14">
                  {npos.length === 0 && (
                    <Hint text={t("notSupportingAnyNpo")} />
                  )}
                  {npos.length > 0 &&
                    npos.map((n: any, idx: number) => (
                      <SupportedNpoRow data={n} key={idx} />
                    ))}
                </IonCol>
                {donar?.showDonationsOnProfile && (
                  <>
                    <IonCol size="12">
                      <IonLabel className="fwfs-500-18">
                        {t("donationsIveMade")}
                      </IonLabel>
                    </IonCol>
                    <IonItemDivider style={{ minHeight: 10 }} />
                    <IonCol size="12" className="mt-10">
                      <Analytics donarId={donar?._id} />
                    </IonCol>
                  </>
                )}
                <IonCol size="6">
                  <IonLabel className="fwfs-500-18">
                    {t("otherDonors")}
                  </IonLabel>
                </IonCol>
                <IonCol size="6" className="ion-text-end">
                  <LinkButton
                    to="/donars/all"
                    text={t("viewAll")}
                    textClassName="fwfs-500-14"
                  />
                </IonCol>
                <IonItemDivider style={{ minHeight: 10 }} />
                <IonCol size="12" className="mt-10">
                  <TopDonars npoId="" excludeDonarId={donar?._id} />
                </IonCol>

                <IonCol size="12" className="mt-10 ion-text-center">
                  <DonationIsValueable />
                </IonCol>
              </IonRow>
            </IonGrid>
          )}
        </IonContent>
      </IonPage>
    </>
  );
};

export default DonarDetails;
