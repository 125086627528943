import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonSearchbar,
  IonSlides,
  IonSlide,
  IonItemDivider,
} from "@ionic/react";
import useStore from "../../store/store";
import projectService from "../../services/project";
import likeService from "../../services/like";
import { useEffect, useState } from "react";
import { Hint, LinkButton } from "../../controls";
import ActiveProjectSlide from "../../components/npo/ActiveProjectSlide";
import DonationIsValueable from "../../components/DonationIsValueable";
import FavouriteNpoCard from "../../components/npo/FavouriteNpoCard";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import Constants from "../../utils/constants";
import HeaderNavbar from "../../components/HeaderNavbar";
import CategoriesGridFilter from "../../components/CategoriesGridFilter";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const Home: React.FC = () => {
  const { t } = useTranslation();
  const { user, isLoggedIn, token } = useStore((state: any) => state);
  const [projects, setProjects] = useState<any>([]);
  const [keyword, setKeyword] = useState<string>("");
  const [favoriteNpos, setFavoriteNpos] = useState<any>([]);
  const size = useScreenSize();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href });
  }, []);

  useEffect(() => {
    reload();
  }, [keyword]);

  const reload = async () => {
    setProjects([]);
    const result = await projectService.getFiltered(keyword);
    const t: any[] = result.data || [];
    if (t.length > 0) {
      setProjects(t);
    }

    if (isLoggedIn && token) {
      const f = await likeService.getUserFavoriteNpos(token);
      const data: any[] = f?.data || [];
      if (!data || data.length === 0) return;

      setFavoriteNpos(data.slice(0, 5));
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t("home")}</IonTitle>
          {size.screen !== BreakPoint.xs && size.screen !== BreakPoint.s && (
            <HeaderNavbar />
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large" className="ios-page-heading">
              {t("home")}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid
          style={{
            padding: 0,
            maxWidth:
              size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                ? "100%"
                : Constants.MAX_GRID_WIDTH,
          }}
        >
          <IonRow>
            <IonCol size="12" className="mt-10">
              <IonLabel className="fwfs-500-22" style={{ paddingLeft: 20 }}>
                {t("hi")} {token && isLoggedIn && <>{user.name}!</>}
              </IonLabel>
            </IonCol>
            <IonCol size="12">
              <IonLabel className="fwfs-400-14" style={{ paddingLeft: 20 }}>
                {t("whereToDonate")}
              </IonLabel>
            </IonCol>
            <IonCol size="12">
              <IonSearchbar
                debounce={600}
                showClearButton="focus"
                onIonClear={(e: any) => setKeyword("")}
                inputmode="search"
                type="search"
                animated={true}
                placeholder={t("searchProjects")}
                onIonChange={(e: any) => setKeyword(e.target.value)}
              />
            </IonCol>
            <IonCol size="12" className="mt-10">
              {projects.length === 0 && <Hint text={t("noProjectsFound")} />}
              {projects.length > 0 && (
                <IonSlides
                  pager={true}
                  scrollbar={false}
                  options={{
                    initialSlide: 1,
                    speed: 400,
                  }}
                >
                  {projects.map((project: any, idx: number) => (
                    <IonSlide
                      key={project?._id}
                      style={{
                        paddingBottom: 30,
                      }}
                    >
                      <ActiveProjectSlide
                        data={project}
                        npoId={project?.userId}
                      />
                    </IonSlide>
                  ))}
                </IonSlides>
              )}
            </IonCol>
            <IonCol size="12" className="mt-10">
              <IonLabel className="fwfs-500-18">{t("categories")}</IonLabel>
            </IonCol>
            <IonItemDivider style={{ minHeight: 10 }} />
            <IonCol size="12" className="fwfs-400-14">
              <CategoriesGridFilter />
            </IonCol>
            <IonCol size="12" className="mt-10 ion-text-center">
              <DonationIsValueable />
            </IonCol>

            <IonCol size="8" className="mt-20">
              <IonLabel className="fwfs-500-18">{t("orgs")}</IonLabel>
            </IonCol>
            <IonCol size="4" className="ion-text-end mt-20">
              <LinkButton
                to="/npos"
                text={t("viewAll")}
                textClassName="fwfs-500-14"
              />
            </IonCol>
            <IonItemDivider style={{ minHeight: 10 }} />

            {favoriteNpos.map((npo: any, idx: number) => (
              <IonCol size="6" key={idx}>
                <FavouriteNpoCard data={npo} setMaxWidth={false} />
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Home;
