import { IonButton, IonIcon } from "@ionic/react";
import "./Button.css";

const Button = ({ text, icon, onClick, type, ...rest }: any) => {
  return (
    <IonButton
      expand="block"
      fill="solid"
      size="default"
      onClick={onClick}
      type={type}
      {...rest}
    >
      {icon && <IonIcon slot="start" icon={icon}></IonIcon>}
      {text}
    </IonButton>
  );
};

export default Button;
