import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
} from "@ionic/react";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import Constants from "../../utils/constants";
import NpoCard from "../../components/npo/NpoCard";
import { useState, useEffect } from "react";
import userService from "../../services/user";
import likeService from "../../services/like";
import useStore from "../../store/store";
import CategoriesGridFilter from "../../components/CategoriesGridFilter";
import HeaderNavbar from "../../components/HeaderNavbar";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const Npos: React.FC = () => {
  const { t } = useTranslation();
  const { isLoggedIn, token, setIsLoading, selectedCategory } = useStore(
    (state: any) => state
  );
  const size = useScreenSize();
  const [list, setList] = useState<any>([]);
  const [likes, setLikes] = useState<any>([]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    userService.getAllNpos({ category: selectedCategory }).then((result) => {
      if (result.data) {
        setList(result.data);
      }
    });

    if (isLoggedIn && token) {
      likeService.getUserFavoriteNpos(token).then((result) => {
        if (result.data) {
          setLikes(result.data);
        }
      });
    }

    setIsLoading(false);
  }, [selectedCategory]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t("npos")}</IonTitle>
          {size.screen !== BreakPoint.xs && size.screen !== BreakPoint.s && (
            <HeaderNavbar />
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large" className="ios-page-heading">
              {t("npos")}
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid
          style={{
            padding: 0,
            maxWidth:
              size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                ? "100%"
                : Constants.MAX_GRID_WIDTH,
          }}
        >
          <IonRow>
            <IonCol size="12">
              <CategoriesGridFilter />
            </IonCol>
            <IonCol size="12">
              <IonLabel className="fwfs-500-18" style={{ paddingLeft: 10 }}>
                {t("findNpo")}
              </IonLabel>
            </IonCol>
            <IonCol size="12" style={{ padding: 0 }} className="mb-20">
              <IonList lines="none">
                {list.map((k: any, i: number) => (
                  <NpoCard obj={k} key={i} userLikes={likes} />
                ))}
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Npos;
