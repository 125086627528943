import { IonImg, IonLabel } from "@ionic/react";
import { useTranslation } from "react-i18next";

const DisplayAchievement = ({ url }: any) => {
  const { t } = useTranslation();
  const style = { width: "100%", height: "auto" };
  return (
    <>
      <IonLabel className="fwfs-500-18">{t("featuredMedia")}</IonLabel>
      <IonImg src={url} style={style} className="mt-10" />
    </>
  );
};

export default DisplayAchievement;
