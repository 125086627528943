import { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import AnalyticTransactionBox from "./AnalyticTransactionBox";
import Constants from "../../utils/constants";
import paymentService from "../../services/payment";
import moment from "moment";
import Utils from "../../utils/utils";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const NpoAnalytics = ({ npoId }: any) => {
  const { t } = useTranslation();
  const [list, setList] = useState<any[]>([]);
  const [datasets, setDatasets] = useState<any[]>([
    {
      label: `${t("amount")} (${Constants.CURRENCY_SYMBOL})`,
      data: [],
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
  ]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: `${t("fundRaisingIn2")} ${moment().year()}`,
      },
    },
  };

  const labels = [
    t("january"),
    t("february"),
    t("march"),
    t("april"),
    t("may"),
    t("june"),
    t("july"),
    t("august"),
    t("september"),
    t("october"),
    t("november"),
    t("december"),
  ];

  useEffect(() => {
    paymentService
      .getByNpoIdYearly({ year: moment().year(), npoId: npoId })
      .then((result) => {
        if (result.data) {
          let tt: any[] = result?.data || [];
          let f = tt.map((k) => {
            let c = Utils.getRandomColor();
            return {
              label: k.title,
              data: k.yearly,
              borderColor: c.border,
              backgroundColor: c.background,
            };
          });

          let s: any[] = [];
          for (let i = 0; i < 12; i++) {
            let monthTotal = 0;
            for (let j = 0; j < f.length; j++) monthTotal += f[j].data[i];

            s.push(monthTotal);
          }

          // sum
          f.push({
            label: `${t("allProjects")} (${Constants.CURRENCY_SYMBOL})`,
            data: s,
            borderColor: Constants.PRIMARY,
            backgroundColor: "rgba(243, 190, 74, 0.5)",
          });
          setDatasets(f);
        }
      });
  }, []);

  const data = {
    labels,
    datasets: datasets,
  };

  return (
    <>
      <Line options={options} data={data} />
      <br />
      {list.map((transaction: any, idx: number) => (
        <AnalyticTransactionBox key={idx} data={transaction} />
      ))}
    </>
  );
};

export default NpoAnalytics;
