import { IonCol, IonGrid, IonImg, IonLabel, IonRow } from "@ionic/react";
import moment from "moment";
import Constants from "../../utils/constants";

const ActiveAchievementSlide = ({ data }: any) => {
  const style = {
    width: "100%",
    height: 300,
    borderRadius: Constants.BORDER_RADIUS,
    objectFit: "cover",
    overflow: "hidden",
  };

  return (
    <IonGrid
      style={{
        margin: 0,
        padding: 0,
      }}
    >
      <IonRow>
        <IonCol
          size="12"
          style={{
            margin: 2,
            padding: 2,
          }}
        >
          <IonImg src={data.fileUrl} style={style} />
        </IonCol>
        <IonCol size="12" className="ion-text-start">
          <IonLabel className="fwfs-400-14">{data.text}</IonLabel> <br />
          <IonLabel
            className="fwfs-400-12"
            style={{ color: Constants.PRIMARY }}
          >
            {moment(data.dateAchieved).format("MMM DD, YYYY")}
          </IonLabel>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default ActiveAchievementSlide;
