import create from "zustand";
import Session from "./session";
import Keys from "./keys";

const useStore = create(set => ({
	isLoggedIn: Session.get(Keys.isLoggedIn) || false,
	setIsLoggedIn: (t: boolean) => {
		Session.set(Keys.isLoggedIn, t);
		set(() => ({ isLoggedIn: t }));
	},
	isLoading: Session.get(Keys.isLoading) || false,
	setIsLoading: (t: boolean) => {
		Session.set(Keys.isLoading, t);
		set(() => ({ isLoading: t }));
	},
	isFirstTimeLaunch: Session.get(Keys.isFirstTimeLaunch) || true,
	setIsFirstTimeLaunch: (t: boolean) => {
		Session.set(Keys.isFirstTimeLaunch, t);
		set(() => ({ isFirstTimeLaunch: t }));
	},
	token: Session.get(Keys.token) || false,
	setToken: (t: string) => {
		Session.set(Keys.token, t);
		set(() => ({ token: t }));
	},
	user: Session.getParsed(Keys.user) || false,
	setUser: (t: any) => {
		Session.setStringified(Keys.user, t);
		set(() => ({ user: t }));
	},
	refUserId: Session.get(Keys.refUserId) || "",
	setRefUserId: (t: string) => {
		Session.set(Keys.refUserId, t);
		set(() => ({ refUserId: t }));
	},
	selectedCategory: Session.get(Keys.selectedCategory) || "",
	setSelectedCategory: (t: string) => {
		Session.set(Keys.selectedCategory, t);
		set(() => ({ selectedCategory: t }));
	},
	notification: Session.get(Keys.notification) || false,
	setNotification: (t: any) => {
		Session.setStringified(Keys.notification, t);
		set(() => ({ notification: t }));
	},
	language: Session.get(Keys.language) || "",
	setLanguage: (t: string) => {
		Session.set(Keys.language, t);
		set(() => ({ language: t }));
	},
}));

export default useStore;
