import { useState, useEffect } from "react";
import { Hint } from "../../controls";
import userService from "../../services/user";
import DonarCard from "./DonarCard";
import { useTranslation } from "react-i18next";

const TopDonars = ({ npoId, excludeDonarId }: any) => {
  const { t } = useTranslation();
  const [list, setList] = useState<any>([]);

  useEffect(() => {
    userService.getTopDonars({ npoId }).then((result) => {
      if (result.data) {
        let t: any[] = result.data;
        t = t.filter(
          (k) =>
            parseInt(k.donations) > 0 &&
            k._id.toString() !== excludeDonarId.toString()
        );
        setList(t);
      }
    });
  }, [npoId]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        overflowX: "auto",
        overflowY: "hidden",
        paddingBottom: 20,
      }}
    >
      {list.length === 0 && <Hint text={t("noDonors")} />}
      {list.map((k: any, idx: number) => (
        <DonarCard data={k} key={idx} />
      ))}
    </div>
  );
};

export default TopDonars;
