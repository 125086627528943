import { useEffect, useState } from "react";
import { IonLabel, IonItem, IonImg, IonIcon, IonThumbnail } from "@ionic/react";
import Constants from "../../utils/constants";
import { heart, heartOutline } from "ionicons/icons";
import likeService from "../../services/like";
import useStore from "../../store/store";
import { useHistory } from "react-router-dom";

const NpoCard = ({ obj, userLikes }: any) => {
  const history = useHistory();
  const { isLoggedIn, token, setIsLoading } = useStore((state: any) => state);
  const [isLiked, setIsLiked] = useState(false);

  const handleLike = (e: any) => {
    e.preventDefault();

    if (isLoggedIn && token) {
      if (isLiked) {
        const like = userLikes.find(
          (k: any) => k.npoId.toString() === obj._id.toString()
        );
        if (!like) return;
        likeService.remove(token, like._id).then((result) => {
          if (result.error) {
            return;
          }

          setIsLiked(false);
        });
      } else {
        likeService.create(token, obj._id).then((result) => {
          if (result.error) {
            setIsLoading(false);
            return;
          }

          setIsLiked(true);
        });
      }
    } else {
      history.push("/signup");
    }
  };

  useEffect(() => {
    userLikes.forEach((k: any) => {
      if (k.npoId._id.toString() === obj._id.toString()) {
        setIsLiked(true);
      }
    });
  }, []);

  return (
    <>
      <IonItem
        lines="none"
        className="mb-10"
        style={{
          marginLeft: 15,
          marginRight: 15,
          borderRadius: Constants.BORDER_RADIUS,
          padding: 5,
          backgroundColor: Constants.LIGHT_COLOR,
        }}
      >
        <IonThumbnail slot="start">
          <IonImg
            src={obj.fileUrl}
            style={{
              borderRadius: 8,
              cursor: "pointer",
              objectFit: "cover",
              overflow: "hidden",
            }}
            onClick={(e) => history.push(`/npo/${obj._id}`)}
          />
        </IonThumbnail>
        <IonLabel
          className="ion-text-wrap npo-name"
          style={{ cursor: "pointer" }}
          onClick={(e) => history.push(`/npo/${obj._id}`)}
        >
          {obj.name}
        </IonLabel>
        <IonIcon
          icon={isLiked ? heart : heartOutline}
          slot="end"
          color="primary"
          style={{
            cursor: "pointer",
          }}
          onClick={handleLike}
        />
      </IonItem>
    </>
  );
};

export default NpoCard;
