import { IonInput, IonItem, IonLabel, IonIcon } from "@ionic/react";
import Constants from "../utils/constants";

const Number = ({ label, value, icon, onChange, ...rest }: any) => {
  return (
    <IonItem fill="outline">
      {icon && <IonIcon slot="start" icon={icon}></IonIcon>}
      <IonLabel position="floating">{label}</IonLabel>
      <IonInput
        placeholder={Constants.CURRENCY_SYMBOL}
        type="number"
        inputmode="numeric"
        value={value}
        onIonInput={onChange}
        {...rest}
      ></IonInput>
    </IonItem>
  );
};

export default Number;
