import { IonCol, IonGrid, IonRow } from "@ionic/react";
import imgShelters from "../images/shelters.svg";
import imgHumanRights from "../images/human-rights.svg";
import CategoriesGridItem from "./CategoriesGridItem";
import { useTranslation } from "react-i18next";

const CategoriesGridChooser = ({ selected, onChange }: any) => {
  const { t } = useTranslation();
  return (
    <IonGrid>
      <IonRow>
        <IonCol size="6">
          <CategoriesGridItem
            title={t("shelters")}
            img={imgShelters}
            selected={selected === "shelters"}
            onChange={onChange}
          />
        </IonCol>
        {/* <IonCol size="6">
					<CategoriesGridItem
						title="Environment"
						img={imgEnvironment}
						selected={selected === "environment"}
						onChange={onChange}
					/>
				</IonCol>
				<IonCol size="6">
					<CategoriesGridItem
						title="Education"
						img={imgEducation}
						selected={selected === "education"}
						onChange={onChange}
					/>
				</IonCol> */}
        <IonCol size="6">
          <CategoriesGridItem
            title={t("disasterRecovery")}
            img={imgHumanRights}
            selected={selected === "disaster recovery"}
            onChange={onChange}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default CategoriesGridChooser;
