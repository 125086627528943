import { IonLabel, IonImg, IonRow, IonCol, IonGrid } from "@ionic/react";
import Constants from "../../utils/constants";
import { useHistory } from "react-router-dom";
import { Button } from "../../controls";
import Utils from "../../utils/utils";
import { useTranslation } from "react-i18next";
const SHOW_DONAR_IMAGES = 9;

const SupportedNpoRow = ({ data }: any) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <>
      <IonGrid
        style={{
          borderRadius: Constants.BORDER_RADIUS,
          backgroundColor: Constants.LIGHT_COLOR,
          marginBottom: 10,
          padding: 10,
        }}
      >
        <IonRow>
          <IonCol size="4">
            <IonImg
              src={data?.fileUrl}
              style={{
                borderRadius: 8,
                cursor: "pointer",
                objectFit: "cover",
                overflow: "hidden",
                width: 100,
                height: 100,
              }}
            />
          </IonCol>
          <IonCol size="8">
            <IonRow>
              <IonCol size="12">
                <IonLabel
                  className="fwfs-500-14 ion-text-nowrap truncate-1"
                  style={{ color: Constants.DARK_COLOR }}
                >
                  {data?.name}
                </IonLabel>
              </IonCol>
              <IonCol size="12">
                <IonLabel
                  className="fwfs-400-12 ion-text-wrap"
                  style={{ color: Constants.DARK_COLOR }}
                >
                  {data?.about}
                </IonLabel>
              </IonCol>
              <IonCol size="12">
                <Button
                  text={t("helpThisProject")}
                  type="button"
                  style={{
                    width: 150,
                    height: 35,
                  }}
                  onClick={(e: any) => history.push(`/npo/${data?._id}`)}
                ></Button>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol
            size="9"
            style={{
              paddingLeft: 30,
            }}
          >
            {data.supporters
              .slice(0, SHOW_DONAR_IMAGES)
              .map((supporter: any, idx: number) => (
                <IonImg
                  key={idx}
                  src={supporter.fileUrl}
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: 50,
                    objectFit: "cover",
                    overflow: "hidden",
                    float: "left",
                    marginLeft: -25,
                    border: "0.5px solid #fff",
                  }}
                />
              ))}
          </IonCol>
          <IonCol size="3" style={{ paddingTop: 20 }}>
            {data.supporters.length - SHOW_DONAR_IMAGES > 0 && (
              <IonLabel
                className="fwfs-400-12 ion-text-nowrap"
                style={{
                  color: Constants.DARK_COLOR,
                }}
              >
                +
                {Utils.formatToNumber(
                  (data.supporters.length - SHOW_DONAR_IMAGES).toString()
                )}{" "}
                {t("more")}
              </IonLabel>
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default SupportedNpoRow;
