import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonPage,
  IonLabel,
  IonCol,
  IonGrid,
  IonRow,
  IonBackButton,
  IonRadioGroup,
  IonItem,
  IonRadio,
} from "@ionic/react";
import Utils from "../../utils/utils";
import Constants from "../../utils/constants";
import useStore from "../../store/store";
import {
  Email,
  Button,
  Password,
  LinkButton,
  Text,
  TnCCheckbox,
} from "../../controls";
import PageHeading from "../../components/PageHeading";
import AuthScreensBackground from "../../components/AuthScreensBackground";
import userService from "../../services/user";
import { isBrowser } from "react-device-detect";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import moment from "moment";
import "./CustomRadioButtons.css";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import SwitchLanguageAuthScreens from "../../components/SwitchLanguageAuthScreens";

const Signup: React.FC = () => {
  const { t } = useTranslation();
  const size = useScreenSize();
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [type, setType] = useState<string>("user");
  const [tnc, setTnC] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const { setIsLoading } = useStore((state: any) => state);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href });
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    if (!email || email.length === 0) {
      setErrorMessage(t("emailRequired"));
      return;
    } else {
      if (!Utils.isValidEmail(email)) {
        setErrorMessage(t("emailShouldValid"));
        return;
      }
    }
    if (!name || name.length === 0) {
      setErrorMessage(t("nameRequired"));
      return;
    }
    if (!password || password.length === 0) {
      setErrorMessage(t("passwordRequired"));
      return;
    }
    // atleast 8 length, atleast 1 special character
    if (/^(?=.*?[#?!@$%^&*-]).{8,}$/.test(password) === false) {
      setErrorMessage(t("passwordValidationError"));
      return;
    }

    setIsLoading(true);
    userService
      .signup({
        name,
        email,
        password,
        type,
        joined: moment().format(),
      })
      .then((result) => {
        if (result.error) {
          setErrorMessage(result.error);
          setIsLoading(false);
          return;
        }

        setIsLoading(false);
        const data = result?.data || false;
        if (!data) return;

        setErrorMessage("");
        setSuccessMessage(t("signupSuccess"));
      });
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonGrid className="mp-0">
          <IonRow>
            <IonCol
              size-xs="12"
              size-sm="12"
              size-md="12"
              size-lg="6"
              style={{
                padding: 0,
                margin: 0,
                background: Constants.ORANGE_COLOR,
                borderBottomRightRadius: isBrowser
                  ? Constants.BORDER_RADIUS
                  : 0,
                borderTopRightRadius: isBrowser ? Constants.BORDER_RADIUS : 0,
                paddingTop: isBrowser ? 100 : 50,
              }}
            >
              <AuthScreensBackground />
            </IonCol>
            <IonCol
              size-xs="12"
              size-sm="12"
              size-md="12"
              size-lg="6"
              className="mp-0"
            >
              <IonGrid
                style={{
                  maxWidth: Constants.SMALL_FORM_WIDTH,
                  paddingLeft: Constants.SMALL_FORM_PADDING,
                  paddingRight: Constants.SMALL_FORM_PADDING,
                  borderTopLeftRadius: Constants.BORDER_RADIUS,
                  borderTopRightRadius: Constants.BORDER_RADIUS,
                  transform: "translateY(-28px)",
                }}
                className={isBrowser ? "" : "bg-dark"}
              >
                <IonRow>
                  <IonCol
                    size="12"
                    className="mb-20"
                    style={{
                      marginTop:
                        size.screen === BreakPoint.xs ||
                        size.screen === BreakPoint.s
                          ? 20
                          : 160,
                    }}
                  >
                    <IonRow>
                      <IonBackButton className="btn-back"></IonBackButton>
                      <PageHeading text={t("createAccount")} />
                    </IonRow>
                  </IonCol>
                  <IonCol size="12">
                    <Email
                      label={t("email")}
                      value={email}
                      onChange={(e: any) => setEmail(e.target.value)}
                    />
                  </IonCol>
                  <IonCol size="12">
                    <Text
                      label={t("fullName")}
                      value={name}
                      onChange={(e: any) => setName(e.target.value)}
                    />
                  </IonCol>
                  <IonCol size="12">
                    <Password
                      label={t("password")}
                      value={password}
                      onChange={(e: any) => setPassword(e.target.value)}
                    />
                  </IonCol>
                  <IonCol size="12" className="mt-10">
                    <IonLabel>{t("yourSigningupAs")}</IonLabel>
                    <IonRadioGroup value={type}>
                      <IonItem lines="none" className="mt-10">
                        <IonLabel>{t("individualUser")}</IonLabel>
                        <IonRadio
                          slot="end"
                          value="user"
                          onClick={(e: any) => {
                            e.preventDefault();
                            setType("user");
                          }}
                        ></IonRadio>
                      </IonItem>
                      <IonItem lines="none">
                        <IonLabel>{t("npoFull")}</IonLabel>
                        <IonRadio
                          slot="end"
                          value="npo"
                          onClick={(e: any) => {
                            e.preventDefault();
                            setType("npo");
                          }}
                        ></IonRadio>
                      </IonItem>
                    </IonRadioGroup>
                  </IonCol>
                  <IonCol size="12" className="mt-20">
                    <TnCCheckbox
                      checked={tnc}
                      onChange={(e: any) => setTnC(e.target.checked)}
                    />
                  </IonCol>
                  <IonCol size="12" className="mt-10">
                    <Button
                      text={t("register")}
                      onClick={handleSubmit}
                      type="submit"
                      color="primary"
                      disabled={!tnc}
                    />
                  </IonCol>
                  {errorMessage && (
                    <IonCol size="12">
                      <IonLabel color="danger">{errorMessage}</IonLabel>
                    </IonCol>
                  )}
                  {successMessage && (
                    <IonCol size="12">
                      <IonLabel color="primary">{successMessage}</IonLabel>
                    </IonCol>
                  )}
                  <IonCol className="ion-text-center mt-50">
                    <LinkButton
                      to="/login"
                      label={t("alreadyHaveAccount")}
                      text="Login!"
                      labelStyle={{ fontSize: 14 }}
                      textStyle={{
                        fontWeight: 600,
                        fontSize: 14,
                      }}
                    />
                  </IonCol>
                  <IonCol size="12">
                    <SwitchLanguageAuthScreens />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Signup;
