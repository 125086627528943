import { useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonModal,
  IonTextarea,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import Constants from "../utils/constants";
import moment from "moment";
import { Button } from "../controls";
import { heart, thumbsUpOutline } from "ionicons/icons";
import useStore from "../store/store";
import feedbackService from "../services/feedback";
import { useTranslation } from "react-i18next";

const FeedbackPopup = ({ isOpen, onClose }: any) => {
  const { t } = useTranslation();
  const { token, setIsLoading } = useStore((state: any) => state);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [feedback, setFeedback] = useState<string>("");
  const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);

  const confirm = () => {
    if (!feedback) {
      setErrorMessage(t("feedbackRequired"));
      return;
    }

    setIsLoading(true);
    feedbackService
      .create(token, { text: feedback, date: moment().format() })
      .then((result) => {
        if (result.error) {
          setSuccessMessage("");
          setErrorMessage(result.error);
          setIsLoading(false);
          return;
        }

        setFeedback("");
        setErrorMessage("");
        setIsLoading(false);
        setShowSuccessToast(true);
        onClose();
      });
  };

  return (
    <>
      <IonToast
        isOpen={showSuccessToast}
        onDidDismiss={() => setShowSuccessToast(false)}
        message={t("thankYouFeedback")}
        icon={heart}
        color={Constants.ORANGE_COLOR}
        animated={true}
        position="bottom"
        translucent={true}
        duration={2500}
      />

      <IonModal
        isOpen={isOpen}
        onWillDismiss={() => {
          setErrorMessage("");
          setSuccessMessage("");
          setFeedback("");
        }}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onClose}>{t("cancel")}</IonButton>
            </IonButtons>
            <IonTitle>{t("shareYourThoughts")}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonLabel>
            {t("feedback1")} {Constants.POINTS.FEEDBACK} {t("feedback2")}
          </IonLabel>

          <IonItem className="mt-30 mb-30" fill="outline" lines="none">
            <IonLabel position="floating">{t("feedback")}</IonLabel>
            <IonTextarea
              required={true}
              autofocus={true}
              placeholder={t("shareYourThoughts1")}
              value={feedback}
              onIonChange={(e: any) => {
                e.preventDefault();
                setErrorMessage("");
                setFeedback(e.target.value);
              }}
            />
          </IonItem>
          <Button
            strong={true}
            color="primary"
            onClick={confirm}
            text={t("submit")}
            icon={thumbsUpOutline}
          />

          {errorMessage && <IonLabel color="danger">{errorMessage}</IonLabel>}
          {successMessage && (
            <IonLabel color="primary">{successMessage}</IonLabel>
          )}
        </IonContent>
      </IonModal>
    </>
  );
};

export default FeedbackPopup;
