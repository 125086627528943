import { IonToggle, IonLabel, IonCol, IonItem } from "@ionic/react";

const Toggle = ({ text, checked, onChange, ...rest }: any) => {
  return (
    <IonItem fill="outline" lines="none">
      <IonCol size="8">
        <IonLabel>{text}</IonLabel>
      </IonCol>
      <IonCol size="4" className="ion-text-end">
        <IonToggle
          enableOnOffLabels={true}
          checked={checked}
          onIonChange={onChange}
          {...rest}
        ></IonToggle>
      </IonCol>
    </IonItem>
  );
};

export default Toggle;
