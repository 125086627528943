import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonBackButton,
  IonImg,
  IonAvatar,
  IonButtons,
  IonToast,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import useStore from "../../store/store";
import { Button } from "../../controls";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import Constants from "../../utils/constants";
import Styles from "../../css/styles";
import imgDonationSuccess from "../../images/donation-success.svg";
import HeaderNavbar from "../../components/HeaderNavbar";
import { megaphoneOutline } from "ionicons/icons";
import SocialShare from "../../components/SocialShare";
import FeedbackPopup from "../../components/FeedbackPopup";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const DonationSuccess: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useStore((state: any) => state);
  const size = useScreenSize();
  const history = useHistory();
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href });
  }, []);

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton className="btn-back"></IonBackButton>
            </IonButtons>
            <IonTitle>{t("thankYou")}</IonTitle>
            {size.screen !== BreakPoint.xs && size.screen !== BreakPoint.s && (
              <HeaderNavbar />
            )}
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large" className="ios-page-heading">
                {t("thankYou")}
              </IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonGrid
            style={{
              padding: 0,
              maxWidth:
                size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                  ? "100%"
                  : Constants.MAX_GRID_WIDTH,
            }}
          >
            <IonRow style={Styles.orangeHeader} className="center">
              <IonCol size="12">
                <IonAvatar
                  style={{
                    cursor: "pointer",
                    width: 280,
                    height: 250,
                    marginTop: 30,
                    marginBottom: -80,
                    marginLeft:
                      size.screen === BreakPoint.xs ||
                      size.screen === BreakPoint.s
                        ? size.width / 2 - 140
                        : Constants.MAX_GRID_WIDTH / 2 - 140,
                  }}
                >
                  <IonImg src={imgDonationSuccess} alt={user.name} />
                </IonAvatar>
              </IonCol>
            </IonRow>
            <IonRow style={{ marginTop: 80 }}>
              <IonCol
                size="12"
                className="ion-text-center mt-20"
                style={{ paddingLeft: 50, paddingRight: 50 }}
              >
                <IonLabel className="fwfs-500-24 ion-text-wrap">
                  {t("donationSuccess")}
                </IonLabel>
              </IonCol>
              <IonCol
                size="12"
                className="ion-text-center mt-20"
                style={{ paddingLeft: 50, paddingRight: 50 }}
              >
                <IonLabel className="fwfs-400-14">{t("thankYouHint")}</IonLabel>
              </IonCol>

              <IonCol size="12" className="ion-text-center mt-20">
                <SocialShare
                  title={t("shareYourDeed")}
                  text={`${t("shareYourDeed1")} ${
                    process.env.REACT_APP_NAME
                  }. ${t("shareYourDeed2")}\n`}
                  url={`${process.env.REACT_APP_URL}/donar/${user._id}`}
                />
              </IonCol>

              <IonCol className="mt-20" size="12">
                <Button
                  text={t("makeMoreDonations")}
                  onClick={(e: any) => history.push("/npos")}
                  type="button"
                  color="primary"
                />
              </IonCol>
              <IonCol className="mb-20" size="12">
                <Button
                  text={t("backToHome")}
                  onClick={(e: any) => history.push("/home")}
                  type="button"
                  color="dark"
                />
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonToast
            isOpen={true}
            message={`${t("rateUs1")} ${process.env.REACT_APP_NAME}${t(
              "rateUs2"
            )}`}
            icon={megaphoneOutline}
            animated={true}
            position="bottom"
            translucent={true}
            duration={4000}
            buttons={[
              {
                text: t("sure2"),
                role: "cancel",
                handler: () => {
                  // open playstore
                },
              },
              {
                text: t("no"),
                role: "info",
                handler: () => {
                  setIsFeedbackModalOpen(true);
                },
              },
            ]}
          />

          <FeedbackPopup
            isOpen={isFeedbackModalOpen}
            onClose={() => setIsFeedbackModalOpen(false)}
          />
        </IonContent>
      </IonPage>
    </>
  );
};

export default DonationSuccess;
